import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import CSearchTextField from "../../../components/CSearchTextField";
// import CDropdown, {IDropdown} from "../../components/CDropdown";
// import CSearchTextField from "../../components/CSearchTextField";

export interface IFeeRecordPageRealizedCommissionHistoryFilter{
    selectedListDisplay : IDropdown,
    setSelectedListDisplay : Function,
    selectedSortby : IDropdown,
    setSelectedSortby : Function,
    onSearch : Function
}

export default function FeeRecordPageRealizedCommissionHistoryFilter(props:IFeeRecordPageRealizedCommissionHistoryFilter) {
    const listDisplayData = [
        {
            name : "25",
            value : "25",
        },
        {
            name : "50",
            value : "50",
        },
        {
            name : "100",
            value : "100",
        }
    ]
    const sortByData = [
        {
            name : "Date (Recent)",
            value : "CREATE_DESC"
        },
        {
            name : "Date (Old)",
            value : "CREATE_ASC"
        },
        {
            name : "Gas Coverage (High)",
            value : "GAS_COVERAGE_DESC"
        },
        {
            name : "Gas Coverage (Low)",
            value : "GAS_COVERAGE_ASC"
        },
        {
            name : "Conversion (High)",
            value : "CONVERSION_DESC"
        },
        {
            name : "Conversion (Low)",
            value : "CONVERSION_ASC"
        },
    ]


    return <Row className="mb-3 align-items-end">
        <Col xs={2}>
            <CDropdown id={"commission-record-history-dropdown-list-display"} hint={"List Display"} data={listDisplayData} selected={props.selectedListDisplay}  onSelected={props.setSelectedListDisplay}></CDropdown>
        </Col>
        <Col xs={3}>
            <CDropdown id={"commission-record-history--sort-by"} hint={"Sort by"} data={sortByData} selected={props.selectedSortby} onSelected={props.setSelectedSortby}></CDropdown>
        </Col>
        <Col></Col>
        <Col xs={4} className={"d-flex justify-content-end"}>
            <CSearchTextField id={"transaction-approve-search"} placeholder={"Pair ID"} onSearch={props.onSearch} />

        </Col>
    </Row>;
}