import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../../api/wallet_connect/wallet_connect_dto";
import CLocaleTimestamp from "../../components/CLocaleTimestamp";
export interface ITransactionDetailPageDepositInformation {
	detail?: Dto_GetTransactionDetail
}


export default function TransactionDetailPageDepositInformation(props:ITransactionDetailPageDepositInformation) {
	return <Container>
		<Row style={{ width: "620px", height: "400px" }} className={"p-4 mb-4 bg-white rounded d-flex flex-column align-items-start"}>
			<Row className={"h4 mb-3 pb-3 border-2 border-bottom"}>Deposit Information</Row>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Date</div>
				<div>{props.detail?.depositDate?<CLocaleTimestamp timestamp={props.detail.depositDate}/>:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center"}>
				<div>Event</div>
				<div>Deposit {props.detail?.depositTokenName}</div>
			</div>
		</Row>
	</Container>
}