import {Button, Col, Container, Row} from "react-bootstrap";
import {useEffect} from "react";
import CTextField, {ITextFieldType} from "../../../components/CTextField";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult} from "../../../../model/custom_dropdown";
import {getCurrencyUnit, getGasUnit, gweiToEth} from "../../../../utils/util";

export interface IWalletManagementSetGasFeePageSetGasFee {
	selectedMainnet?: IDropdown_Dto_GetMainnetResult
	proposedDynamicNetworkFee: string
	maxGasPrice: string, setMaxGasPrice: Function
	gasPrice: string, setGasPrice: Function
	gasLimit: string, setGasLimit: Function
	maxTransactionFee: string, setMaxTransactionFee: Function
}

export default function WalletManagementSetGasFeePageSetGasFee(
	props: IWalletManagementSetGasFeePageSetGasFee
) {

	useEffect(() => {
		init();
	}, [props.selectedMainnet])

	useEffect(() => {
		try {
			if(props.maxGasPrice && props.gasLimit) {
				props.setMaxTransactionFee(gweiToEth(parseFloat(props.maxGasPrice) * parseFloat(props.gasLimit)).toString());
			} else {
				props.setMaxTransactionFee('');
			}
		} catch(e) {
			props.setMaxTransactionFee('');
		}
	}, [props.maxGasPrice, props.gasLimit])
	async function init() {
		getGasFee();
	}

	async function getGasFee() {
		if(!props.selectedMainnet || !props.selectedMainnet.data) return;
		NetworkApi.getNetworkGasFee(props.selectedMainnet.data?.id).then((res) => {
			props.setGasLimit(res.data.detail.gasLimit);
			props.setMaxGasPrice(res.data.detail.maxGasPrice);
		})
	}

	function getMaxTransactionFee() {
		if(!props.maxGasPrice || !props.gasLimit) return;
		try {
			const maxGasPrice = parseFloat(props.maxGasPrice);
			const gasLimit = parseFloat(props.gasLimit);
			const TransactionFee = maxGasPrice * gasLimit;
			return TransactionFee;
		} catch(e) {

		}
	}

	return <Container>
		<Row className={"d-flex justify-content-center mb-5"}>
			<Row style={{ width: "768px"}} className={"bg-white rounded p-5"}>
				<Row className={"h5"}>Set Gas Fee</Row>
				<Row className={"fs-6 mb-5"}>You can set a gas fee to use when withdrawing.</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Max Gas Price</Col>
					<Col>
						<CTextField id={'wallet-management-set-gas-fee-max-gas-price'} text={props.maxGasPrice} setText={(text: string) => {
							props.setMaxGasPrice(text);
						}} unit={"Gwei"} type={ITextFieldType.number}  onChange={(str: string) => {
							getMaxTransactionFee();
						}}/>
					</Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Gas Price</Col>
					<Col>
						<CTextField id={'wallet-management-set-gas-fee-gas-price'} text={props.gasPrice} setText={(text: string) => {
							props.setGasPrice(text);
						}} unit={"Gwei"} disabled={true}/>
					</Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Gas Limit</Col>
					<Col>
						<CTextField id={'wallet-management-set-gas-fee-gas-limit'} text={props.gasLimit} setText={(text: string) => {
							props.setGasLimit(text)
						}} type={ITextFieldType.number} onChange={(str: string) => {
							getMaxTransactionFee();
						}}/>
					</Col>
				</Row>
				<Row className={"mb-5 align-items-center"}>
					<Col>Maximum Transaction Fee</Col>
					<Col>
						<CTextField id={'wallet-management-set-gas-fee-maximum-transaction-fee'}
									 text={props.maxTransactionFee} setText={props.setMaxTransactionFee}
									 disabled={true}
						            unit={props.selectedMainnet && props.selectedMainnet.data ? getCurrencyUnit(props.selectedMainnet?.data?.name) : ""}
						/>
					</Col>
				</Row>
			</Row>
		</Row>
	</Container>
}