import {BaseHttp} from "../BaseHttp";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";
import {
	Dto_CreateNewWallet,
	Dto_GetWalletsDistribute,
	Dto_GetWalletsManagement,
	Dto_GetWalletsManagementResult,
	Dto_GetWalletsDistributeResult,
	Dto_GetTokensBalanceByWalletResult,
	Dto_VerifyAddress,
	Dto_GetTokensByWalletResult,
	Dto_GetTokensByWallet,
	Dto_GetWalletBasicResult,
	Dto_SetWalletBasic,
	Dto_GetApproveSentToResult,
	Dto_SetApproveSentTo,
	Dto_GetWalletAdminResult,
	Dto_GetWalletUsageResult, Dto_SetWalletStatus, Dto_AddTokenToWallet, Dto_GetBalanceByWalletAndTokenResult, Dto_GetBalanceByWalletResult
} from "./wallet_dto";
import {ApiUrl} from "../../constants/ApiUrl";

export class WalletApi {
	private static instance: WalletApi
	private constructor() {}

	// OK
	public static async getWallets_distribute(params: Dto_GetWalletsDistribute ):
		Promise<{code: string, msg: string, data: { list: Dto_GetWalletsDistributeResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS, params);
	}

	// OK
	public static async getWallets_management(params: Dto_GetWalletsManagement ):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetWalletsManagementResult[] }, success: boolean}> {
		const baseUrl = process.env.REACT_APP_API_BASE_URL_TESTNET;
		return BaseHttp.get(`${baseUrl}/api/private/wallets`, params);
	}

	public static async createNewWallet(data: Dto_CreateNewWallet ): Promise<DataResponse> {
		const baseUrl = process.env.REACT_APP_API_BASE_URL_TESTNET;
		return BaseHttp.post(`${baseUrl}/api/private/wallets`, data);
	}

	public static async getTokensBalanceByWallet(walletId: number ):
		Promise<{code: string, msg: string, data: { list: Dto_GetTokensBalanceByWalletResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_TOKENS_BALANCE(walletId.toString()));
	}

	public static async getTokensByWallet(walletId: number, params?: Dto_GetTokensByWallet):
		Promise<{code: string, msg: string, data: { list: Dto_GetTokensByWalletResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_TOKENS(walletId.toString()), params);
	}

	public static async verifyAddress(data: Dto_VerifyAddress): Promise<DataResponse> {
		return BaseHttp.post(ApiUrl.API_PRIVATE_WALLETS_VALIDATION, data);
	}

	public static async getWalletBasic(walletId: number):
		Promise<{code: string, msg: string, data: { detail: Dto_GetWalletBasicResult }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_BASIC(walletId.toString()));
	}

	public static async setWalletBasic(walletId: number, data: Dto_SetWalletBasic ):
		Promise<{code: string, msg: string, data: any, success: boolean}> {
			return BaseHttp.put(ApiUrl.API_PRIVATE_WALLETS_ID_BASIC(walletId.toString()), data);
	}

	public static async getApproveSentTo(walletId: number):
		Promise<{code: string, msg: string, data: { detail: Dto_GetApproveSentToResult }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_ALERTS(walletId.toString()));
	}

	public static async setApproveSentTo(walletId: number, data: Dto_SetApproveSentTo):
		Promise<{code: string, msg: string, data: { detail: Dto_SetApproveSentTo }, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_WALLETS_ID_ALERTS(walletId.toString()), data);
	}

	public static async getWalletAdminResult():
		Promise<{code: string, msg: string, data: { list: Dto_GetWalletAdminResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ADMINS);
	}

	public static async getWalletStatus(walletId: number):
		Promise<{code: string, msg: string, data: Dto_GetWalletUsageResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_STATUS(walletId.toString()));
	}

	public static async setWalletStatus(walletId: number, data: Dto_SetWalletStatus): Promise<DataResponse> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_WALLETS_ID_STATUS(walletId.toString()), data);
	}

	public static async addTokenToWallet(walletId: number, data: Dto_AddTokenToWallet): Promise<DataResponse> {
		return BaseHttp.post(ApiUrl.API_PRIVATE_WALLETS_ID_TOKENS(walletId.toString()), data);
	}

	public static async getBalanceByWalletAndToken(walletId: number, tokenId: number):
		Promise<{code: string, msg: string, data: Dto_GetBalanceByWalletAndTokenResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_TOKENS_ID_BALANCE(walletId.toString(), tokenId.toString()));
	}

	public static async getBalanceByWallet(walletId: number):
		Promise<{code: string, msg: string, data: Dto_GetBalanceByWalletResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_WALLETS_ID_BALANCE(walletId.toString()));
	}
}