export const dBConfig = {
    name: 'admin',
    version: 1,
    objectStoresMeta: [
      {
        store: 'user',
        storeConfig: { keyPath: 'email', autoIncrement: false} ,
        storeSchema: [
            { name: 'email', keypath: 'email', options: { unique: false } },
            { name: 'uid', keypath: 'uid', options: { unique: false } },
            { name: 'name', keypath: 'name', options: { unique: false } },
            { name: 'grade', keypath: 'grade', options: { unique: false } }
        ]
      }
    ]
};