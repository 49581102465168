import tether from "assets/symbols/tether.png"
import gpex from "assets/symbols/gpex.png"
import ethereum from "assets/symbols/ethereum.png"
import {Button, Col, Container, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSelectTextField from "../../components/CSelectTextField";
import {useEffect, useState} from "react";
import CCalendarModal from "../../components/modal/CCalendarModal";
import {TokenApi} from "../../../api/token/TokenApi";
import {Dto_GetRealizedFeeResult, Dto_GetTokensResult} from "../../../api/token/token_dto";
import { IDropdown_Dto_GetTokenResult} from "../../../model/custom_dropdown";
import CLoadingDimmer from "pages/components/CLoadingDimmer";


export default function CommissionRecordPageFeeSummary() {
	const [showCalendarToModal, setShowCalendarToModal] = useState(false);
	const [showCalendarFromModal, setShowCalendarFromModal] = useState(false);
	const [selectDateFrom, setSelectDateFrom] = useState(new Date());
	const [selectDateTo, setSelectDateTo] = useState(new Date());
	const [loading,setLoading] = useState(false)
	const [totalUnrealizedFee,setTotalUnrealizedFee] = useState<Dto_GetRealizedFeeResult>()
	const [selectedTokenDropdown, setSelectedTokenDropdown] = useState<IDropdown_Dto_GetTokenResult>();
	const [tokenDropDownList,setTokenDropDownList] = useState<IDropdown_Dto_GetTokenResult[]>([])
	const [curruentToken,setCurruentToken] = useState("");
	
	

	//api에서 토큰 목록 받아오기
	useEffect(() => {
		fetchGetTokenList();
	}, [])

	useEffect(()=>{
		summaryRealrize();
	},[selectedTokenDropdown,selectDateFrom,selectDateTo])

	async function fetchGetTokenList() {
		if(loading) return;
		setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setTokenDropDownList(newDropdownList);
		}).catch((error) => {

		}).finally(() => {
			setLoading(false);
		});
	}


	async function summaryRealrize() {
		if(loading) return;
		setLoading(true);
		try {
			// const numAmount = parseFloat(amount);
			// const numWalletId = parseInt(selectedGpdWalletDropdown?.value!)
			setLoading(true);
			TokenApi.getFeesSummary(
				{
					fromDate : selectDateFrom.toISOString().replace('T', ' ').slice(0, -5),
					toDate : selectDateTo.toISOString().replace('T', ' ').slice(0, -5),
					tokenId : selectedTokenDropdown?.value? parseInt(selectedTokenDropdown.value):0
				}
			).then((res) => {
				let data = res.data;	
				setTotalUnrealizedFee(()=>data)
				// selectedTokenDropdown?.data.tokenName
				if(selectedTokenDropdown?.data.tokenName !== undefined){
				setCurruentToken(selectedTokenDropdown?.data.tokenName)
				}
				
			}).catch((error) => {
				alert(error)
			}).finally(() => {
				setLoading(false);
			})
		} catch(e) {
			return;
		}

	}

	function tokenLogo(curruentToken : string){
		if(curruentToken === "USDT"){
			return <img
			src={tether}
			width="50"
			height="50"
			alt="settings"
			style={{ marginRight: "16px"}}
			/>
		}
		else if(curruentToken === "GPD"){
			return <img
			src={gpex}
			width="50"
			height="50"
			alt="settings"
			style={{ marginRight: "16px"}}
			/>
		}
		else if(curruentToken === "ETH"){
			return <img
			src={ethereum}
			width="50"
			height="50"
			alt="settings"
			style={{ marginRight: "16px"}}
			/>
			
		}else if(curruentToken === "GPX"){
			return <img
			src={gpex}
			width="50"
			height="50"
			alt="settings"
			style={{ marginRight: "16px"}}
			/>
		}
		else{
			return;
		}

		
	}
	



	return <Container className="p-4 bg-white rounded">
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>

		<CCalendarModal show={showCalendarFromModal} setShow={setShowCalendarFromModal}
						onOk={(date: Date) => {
							setSelectDateFrom(date);
						}}/>

		<CCalendarModal show={showCalendarToModal} setShow={setShowCalendarToModal}
			onOk={(date: Date) => {
			setSelectDateTo(date);
		}}/>

		<Row>
			{/* Left Side */}
			<Col>
				{/* Selected Date */}
				<Row className={"mb-4"}>
					<Col>Selected Date</Col>
				</Row>
				<div className={"mb-2 d-flex justify-content-start align-items-center"}>
					<div style={{ width: "120px" }}>From</div>
					<div>
						<CSelectTextField
							id={"fee-record-select-date-to"}
							placeholder={`${selectDateFrom.getFullYear()}/${(selectDateFrom.getMonth()+1).toString().padStart(2,'0')}/${selectDateFrom.getDate().toString().padStart(2,'0') } - ${selectDateFrom.getHours().toString().padStart(2,'0')}:${selectDateFrom.getMinutes().toString().padStart(2,'0')}:${selectDateFrom.getSeconds().toString().padStart(2,'0')}`}
							onSelect={()=>{
								setShowCalendarFromModal(true);
							}}/>
					</div>
				</div>
				<div className={"mb-2 d-flex justify-content-start align-items-center"}>
					<div style={{ width: "120px" }}>To</div>
					<div>
						<CSelectTextField
							id={"fee-record-select-date-from"}
							placeholder={`${selectDateTo.getFullYear()}/${(selectDateTo.getMonth()+1).toString().padStart(2,'0')}/${selectDateTo.getDate().toString().padStart(2,'0') } - ${selectDateTo.getHours().toString().padStart(2,'0')}:${selectDateTo.getMinutes().toString().padStart(2,'0')}:${selectDateTo.getSeconds().toString().padStart(2,'0')}`}
							onSelect={() => {
								setShowCalendarToModal(true);
							}}/>
					</div>
				</div>

				{/* Selected Token */}
				<Row className={"mt-5 mb-2"}>
					<Col>Selected Token</Col>
				</Row>
				<Row>
					<Col>
						<CDropdown id={"fee-record-select-token"}  data={tokenDropDownList}  selected={selectedTokenDropdown} onSelected={(select:IDropdown_Dto_GetTokenResult)=>{
							setSelectedTokenDropdown(select);

							}}/>
					</Col>
				</Row>

			</Col>

			{/* Right Side */}
			<Col>
				{/* Accumulated Unrealized Fee */}
				<Row className="mb-3 border border-2 rounded">
					<Col>
						<Row className={"h5 mt-2 ps-4 pt-3 pb-3 bg-white d-flex justify-content-start"}>Accumulated Unrealized Fee</Row>
						<Row className={"d-flex p-3 justify-content-between align-items-center"}>
							<Col>
								<div>
								{tokenLogo(curruentToken)}
								</div>

							</Col>
							{/* <Col className={"d-flex justify-content-end"}>{totalUnrealizedFee?.unrealizedFee !==null ? totalUnrealizedFee?.unrealizedFee?.totalFee:"-" } {selectedTokenDropdown?.data.tokenName}</Col> */}
							<Col className={"d-flex justify-content-end"}>{totalUnrealizedFee?.unrealizedFee !==null ? totalUnrealizedFee?.unrealizedFee?.totalFee:"-" } {curruentToken} </Col>
						</Row>
					</Col>
				</Row>
				<Row className="border border-2 rounded">
					<Col>
						<Row className={"h5 mt-2 ps-4 pt-3 pb-3 mb-3 bg-white justify-content-start"}>Accumulated Realized Fee</Row>
						<Row className={"d-flex p-3 justify-content-between align-items-center"}>
							<Col>
								<div>
								{tokenLogo(curruentToken)}
								</div>
							</Col>
							<Col className={"d-flex justify-content-end"}>{totalUnrealizedFee?.realizedFee !== null?totalUnrealizedFee?.realizedFee?.totalFee :"-"}  {curruentToken}</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	</Container>
}