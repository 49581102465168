import {Container} from "react-bootstrap";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import CListPage from "../../components/CListPage";
import AdminSettingsPageFilter from "./AdminSettingsPageFilter";
import AdminSettingsPageListHeader from "./AdminSettingsPageListHeader";
import {useEffect, useState} from "react";
import {AdminApi} from "../../../api/admin/AdminApi";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import {Dto_GetAdminListResult} from "../../../api/admin/admin_dto";
import AdminSettingsPageListItem from "./AdminSettingsPageListItem";
import {useNavigate} from "react-router-dom";
import AdminSettingsRemoveOTPModal from "./modals/AdminSettingsRemoveOTPModal";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../constants/value";
import AdminSettingsLoginLockModal from "./modals/AdminSettingsLoginLockModal";
import AdminSettingsLoginUnlockModal from "./modals/AdminSettingsLoginUnlockModal";
import AdminSettingsDeleteAdminUnlockModal from "./modals/AdminSettingsDeleteAdminModal";
import AdminSettingRoleModal from "./modals/AdminSettingsRoleModal";
import {UserApi} from "../../../api/user/UserApi";

export default function AdminSettingsPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<Dto_GetAdminListResult[]>([]);
	const [role, setRole] = useState({
		name: 'All',
		value: undefined,
	},);

	const [selectedUser, setSelectedUser] = useState<Dto_GetAdminListResult|undefined>(undefined);
	const [removeOtp, setRemoveOtp] = useState(false);
	const [loginLock, setLoginLock] = useState(false);
	const [loginUnlock, setLoginUnlock] = useState(false);
	const [setting, setSetting] = useState(false);
	const [deleteAdmin, setDeleteAdmin] = useState(false);

	const [accessDeleteUserOTP, setAccessDeleteUserOTP] = useState(false);
	const [accessUpdateUserStatus, setAccessUpdateUserStatus] = useState(false);
	const [accessUpdateUserRole, setAccessUpdateUserRole] = useState(false);
	const [accessDeleteUser, setAccessDeleteUser] = useState(false);

	const generateItem = (value: Dto_GetAdminListResult) => {
		return <AdminSettingsPageListItem
			user={value}
			onRemoveOtpClicked={() => {
				setSelectedUser(value)
				setRemoveOtp(true)
			}}
			onLoginLockClicked={() => {
				setSelectedUser(value)
				setLoginLock(true)
			}}
			onLoginUnlockClicked={() => {
				setSelectedUser(value)
				setLoginUnlock(true)
			}}
			onSettingClicked={() => {
				setSelectedUser(value)
				setSetting(true)
			}}
			onDeleteClicked={() => {
				setSelectedUser(value)
				setDeleteAdmin(true)
			}}
			key={JSON.stringify(value)}
			accessDeleteUserOTP={accessDeleteUserOTP}
			accessUpdateUserStatus={accessUpdateUserStatus}
			accessUpdateUserRole={accessUpdateUserRole}
			accessDeleteUser={accessDeleteUser}
		/>
	}

	useEffect(() => {
		fetchAdminUserList(1);
		getAccess();
	}, [])

	async function fetchAdminUserList(page: number, search?: string) {
		setLoading(true);
		const result = await AdminApi.getAdminList({
			pageNumber: 1,
			pageSize: 100,
			roleId: role.value ? parseInt(role.value) : undefined,
			searchFilter: search
		});
		if(result && result.data.list) {
			try {
				setList(result.data.list);
				setLoading(false);
			} catch(e) {
				setList([]);
				setLoading(false);
			}
		} else {
			setList([]);
			setLoading(false);
		}
	}

	async function getAccess() {
		const promises = [
			UserApi.getUserAccess("DELETE_USER_OTP"),
			UserApi.getUserAccess("UPDATE_USER_STATUS"),
			UserApi.getUserAccess("UPDATE_USER_ROLE"),
			UserApi.getUserAccess("DELETE_USER"),
		];
		const access = await Promise.all(promises);
		setAccessDeleteUserOTP(access[0]);
		setAccessUpdateUserStatus(access[1]);
		setAccessUpdateUserRole(access[2]);
		setAccessDeleteUser(access[3]);
	}

	return <Container>
		<AdminSettingsRemoveOTPModal show={removeOtp} setShow={setRemoveOtp} user={selectedUser} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AdminSettingsLoginLockModal show={loginLock} setShow={setLoginLock} user={selectedUser} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AdminSettingsLoginUnlockModal show={loginUnlock} setShow={setLoginUnlock} user={selectedUser} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AdminSettingsDeleteAdminUnlockModal show={deleteAdmin} setShow={setDeleteAdmin} user={selectedUser} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AdminSettingRoleModal show={setting} setShow={setSetting} user={selectedUser} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>

		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Admin Settings"} firstUrl={"/settings/admin"}/>
		<CPageTitle title={"Admin Settings"} subtitle={""}/>
		<AdminSettingsPageFilter role={role} setRole={setRole} onSearch={(search: string) => {
			fetchAdminUserList(1, search);
		}}/>
		<AdminSettingsPageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}