import {ReactElement} from "react";
import {Row} from "react-bootstrap";

interface IListPageComponent {
	list: Array<any>;
	itemGenerator: (value: any) => ReactElement;
}

export default function CGridListPage(props: IListPageComponent) {
	return <Row xs={1} md={3} className={"pb-5"}>
		{props.list.map((value) => {
			try {
				return props.itemGenerator(value)
			} catch(e) {}
		})}
	</Row>
}