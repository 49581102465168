import {IDropdown} from "../../../components/CDropdown";
import {useEffect, useState} from "react";
import {TokenApi} from "../../../../api/token/TokenApi";
import FeeRecordPageRealizedCommissionHistoryFilter from "./FeeRecordPageRealizedCommissionHistoryFilter";
import CLoadingDimmer from "../../../components/CLoadingDimmer";
import {Dto_GetFeeTransactionListResult} from "../../../../api/token/token_dto";
import {Container} from "react-bootstrap";
import CListPage from "../../../components/CListPage";
import FeeRecordPageRealizedCommissionHistoryHeader from "./FeeRecordPageRealizedCommissionHistoryHeader";
import FeeRecordPageRealizedCommissionHistoryListItem from "./FeeRecordPageRealizedCommissionHistoryListItem";
import CSimplePagination from "../../../components/CSimplePagination";

export default function FeeRecordPageRealizedCommissionHistory() {
    const [index, setIndex] = useState(1);
    const [list, setList] = useState<Dto_GetFeeTransactionListResult[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageSize,setPageSize] = useState(25);

    const [listDisplay,setListDisplay] = useState<IDropdown>({
        name: "25",
        value: "25",
    })

    const [sortby ,setSortby] = useState<IDropdown>(
        {
            name : "Date (Recent)",
            value : "CREATE_DESC"
        },
    )

    const generateItem = (value:Dto_GetFeeTransactionListResult) => {
        return <FeeRecordPageRealizedCommissionHistoryListItem key={value.feeTransactionId} {...value}/>
    }


    useEffect(() => {
        fetchFeeTransactionList(1);
    }, [])

    async function fetchFeeTransactionList(page : number,search?:string,  feeTransactionId?: number) {
        if(loading) return;
        setLoading(true);
        TokenApi.getFeeTransactionList({
            search : search,
            pageNumber : page,
            pageSize : listDisplay.value ? parseInt(listDisplay.value) : 25,
            sort : sortby.value,
            feeTransactionId : search?parseInt(search):undefined,
        }).then((result) => {
            if (result && result.data.list){
                if (listDisplay.value) setPageSize(parseInt(listDisplay.value))
                setList(result.data.list);
                setTotalCount(result.data.pagingInfo.totalCount);
            } else {
                if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
                setList([]);
                setTotalCount(0);
            }
        }).catch((error) => {
            if (listDisplay.value) setPageSize(parseInt(listDisplay.value))
            setList([]);
            setTotalCount(0);
        }).finally(() => {
            setLoading(false);
        })
    }

    return <Container>
        <CLoadingDimmer loading={loading} setLoading={setLoading}/>
        <CSimplePagination
            index={index}
            setIndex={setIndex}
            loading={loading}
            onSubmit={(page: number) => fetchFeeTransactionList(page)}
            totalCount={totalCount}
            pageSize={pageSize}
        />
        <FeeRecordPageRealizedCommissionHistoryFilter
            selectedListDisplay={listDisplay}
            setSelectedListDisplay={setListDisplay}
            selectedSortby={sortby}
            setSelectedSortby={setSortby}
            onSearch={(search: string) => {
                fetchFeeTransactionList(1, search);
            }}
        />

        <FeeRecordPageRealizedCommissionHistoryHeader/>
        {list.length === 0 ? "No Data" : ""}
        <CListPage list={list} itemGenerator={generateItem}/>
    </Container>
}





