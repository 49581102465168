import {Button, Col, Row} from "react-bootstrap";
import {getGasUnit, shrinkAddress} from "../../../../utils/util";
import TransactionPendingOutDetailModal from "../modals/TransactionPendingDetailModal";
import {useState} from "react";
import TransactionPendingDetailModal from "../modals/TransactionPendingDetailModal";
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";
import {IDropdown} from "../../../components/CDropdown";
import CLinkExplorer from "../../../components/CLinkExplorer";

export interface ITransactionPendingPageWalletConnectInListItem {
	createDate : string,
	eventName : string
	txId : string,
	fromAddress : string,
	fromWalletName : string,
	toAddress : string,
    toWalletName : string,
    maxFee : number
	amount : number,
	tokenName : string,
	gasFee : number,
	networkName : string,
	networkId : number,
	// blockStatus : string,
	pairId : number,
}

export default function TransactionPendingPageWalletConnectInListItem(props: ITransactionPendingPageWalletConnectInListItem) {
	const [showDetailModal, setShowDetailModal] = useState(false);
	return(
	<>
	<TransactionPendingDetailModal 
			show={showDetailModal} 
			setShow={setShowDetailModal}
		    pairId={props.pairId}
			date = {props.createDate}
			amount = {props.amount}
			fromAddress={props.fromAddress}
			toAddress={props.toAddress}
            mainnetName={props.networkName}
			mainnetId={props.networkId}
			txPairId={props.txId}
			maxFee={props.maxFee}
			gasFee={props.gasFee}
			/>
						
	<Row className="p-3 bg-white mb-2 moveItem">
		<Col>
			{props.createDate?<CLocaleTimestamp timestamp={props.createDate}/>:"-"}
		</Col>
		<Col>{props.eventName}</Col>
		<Col style={{color:"#F4B12E",cursor:"pointer"} } onClick={()=>{
			setShowDetailModal(true);}}>
			{shrinkAddress(props.txId)}</Col>
		<Col><CLinkExplorer target={props.tokenName} type={"address"} address={props.fromAddress} text={shrinkAddress(props.fromAddress, 5)} copyText={props.fromAddress}/></Col>
		<Col><CLinkExplorer target={props.tokenName} type={"address"} address={props.toAddress} text={shrinkAddress(props.toAddress, 5)} copyText={props.toAddress}/></Col>
		<Col>{`${props.amount ?? "-"} ${props.tokenName && props.amount ? props.tokenName: ""}`}</Col>
		<Col>{`${props.gasFee ?? "-"} ${props.tokenName && props.gasFee ? getGasUnit(props.tokenName): ""}`}</Col>
		<Col>{props.pairId}</Col>
	</Row>
	</>
	)
}