import {Col, Row} from "react-bootstrap";

export default function WalletDistributeHistoryPageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
			<Col xs={1}>{"Status"}</Col>
			<Col xs={1}>{"Coin/Token"}</Col>
			<Col xs={1}>{"Amount"}</Col>
			<Col xs={2}>{"Gas Fee"}</Col>
			<Col xs={2}>{"From"}</Col>
			<Col xs={2}>{"To"}</Col>
			<Col xs={2}>{"Update"}</Col>
			<Col xs={1}>{"Blockchain Status"}</Col>
		</Row>;
}