import {Button, Container} from "react-bootstrap";
import CListPage from "../../../components/CListPage";
import TransactionPendingPageWalletConnectOutListItem, {ITransactionPendingPageWalletConnectOutListItem} from "./TransactionPendingPageWalletConnectOutListItem";
import TransactionPendingPageWalletConnectOutFilter from "./TransactionPendingPageWalletConnectOutFilter";
import TransactionPendingPageWalletConnectOutListHeader from "./TransactionPendingPageWalletConnectOutListHeader";
import { Dto_getPendingTransactionsResult } from "api/wallet_connect/wallet_connect_dto";
import { IDropdown } from "pages/components/CDropdown";
import { useState,useEffect } from "react";
import { IDropdown_Dto_GetTokenResult } from "model/custom_dropdown";
import { TokenApi } from "api/token/TokenApi";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import { NavigateFunction } from "react-router-dom";
import CSimplePagination from "../../../components/CSimplePagination";


interface ITransactionPendingPageWalletConnectOut{
	loading: boolean,
	setLoading: Function,
	navigate: NavigateFunction
}

export default function TransactionPendingPageWalletConnectOut(props: ITransactionPendingPageWalletConnectOut) {
	const [index, setIndex] = useState(1);
	const [list, setList] = useState<Dto_getPendingTransactionsResult[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [showDetailModal,setShowDetailModal] = useState(false);

	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});

	const [selectedEvent,setSelectedEvent] = useState<IDropdown>({
		name : "Out",
		value : "WALLET_OUT",
	})

	const [selectedToken,setSelectedToken] = useState<IDropdown>();

	const [selectTokenDropdown, setSelectTokenDropdown] = useState<IDropdown[]>([]);
	const generateItem = (value: ITransactionPendingPageWalletConnectOutListItem) => {
		return <TransactionPendingPageWalletConnectOutListItem key={value.txId} {...value}/>
	}
	useEffect(() => {
			fetchGetTokenList();
			fetchPendingTransactionsOut(1);
		}
		,[])

	async function fetchGetTokenList() {
		if(props.loading) return;
		props.setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setSelectTokenDropdown(newDropdownList);
			setSelectedToken(newDropdownList[0]);
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		});
	}

	async function fetchPendingTransactionsOut(page : number,search?:string,tokenId?:number,) {
		if(props.loading) return;
		props.setLoading(true);
		if(selectedToken?.value===undefined)return;
		try{
			setList([]);
			const result = await WalletConnectApi.getPendingTransactions({
				event : "WALLET_OUT",
				tokenId: selectedToken.value?parseInt(selectedToken.value):undefined,
				search: search,
				pageNumber : page,
				pageSize : listDisplay.value?parseInt(listDisplay.value):25,
			});
			if(result &&result.data.list){
				try{
					setList(result.data.list);
					props.setLoading(false);
				}catch(e){
					setList([]);
					props.setLoading(false);
				}}
			else{
				setList([]);
				props.setLoading(false);
			}
		}catch(e){
		}finally{
			props.setLoading(false);
		}}

	return <Container>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={props.loading}
			onSubmit={(page: number) => fetchPendingTransactionsOut(page)}
			totalCount={totalCount}
			pageSize={listDisplay.value ? parseInt(listDisplay.value) : 25}
		/>
		<TransactionPendingPageWalletConnectOutFilter
			selectedEvent={selectedEvent}
			setSelectedEvent={setSelectedEvent}
			selectedToken={selectedToken}
			setSelectedToken={setSelectedToken}
			selectTokenDropdown={selectTokenDropdown}
			setSelectTokenDropdown={setSelectTokenDropdown}
			selectedListDisplay={listDisplay}
			setSelectedListDisplay={setListDisplay}
			onSearch={(search:string)=>{
				fetchPendingTransactionsOut(1,search);
			}
			}

		/>
		<TransactionPendingPageWalletConnectOutListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list}  itemGenerator={generateItem}/>
	</Container>
}