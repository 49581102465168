import {Button, Container, Tab, Tabs} from "react-bootstrap";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import GPDHistoryPageMint from "./mint/GPDHistoryPageMint";
import GPDHistoryPageBurn from "./burn/GPDHistoryPageBurn";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function GPDHistoryPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"GPD Histories"} firstUrl={"/contract/history"}/>
		<CPageTitle title={"GPD Histories"} subtitle={""}/>
		<Tabs
			defaultActiveKey="mint"
			id="gpd_history_tabs"
			className="mb-3"
		>
			<Tab tabClassName="bg-white text-black" eventKey="mint" title="Mint">
				<GPDHistoryPageMint loading={loading} setLoading={setLoading} navigate={navigate}/>
			</Tab>
			<Tab tabClassName="bg-white text-black" eventKey="burn" title="Burn">
				<GPDHistoryPageBurn loading={loading} setLoading={setLoading} navigate={navigate}/>
			</Tab>
		</Tabs>
	</Container>
}