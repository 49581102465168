const useProxy = process.env.REACT_APP_USE_PROXY;
const API_BASE_URL = useProxy === "true" ? "" : process.env.REACT_APP_API_BASE_URL_TESTNET;
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_TESTNET;

export class ApiUrl {
	private static instance: ApiUrl
	private constructor() {}

	public static API_TEST_PUBLIC_GET = `${API_BASE_URL}/test/public/get`;

	// Auth Controller
	public static API_OAUTH_TOKEN = `${API_BASE_URL}/api/oauth/token`;
	public static API_OAUTH_TOKEN_VALIDATION = `${API_BASE_URL}/api/oauth/token/validation`;

	// Admin Controller
	public static API_PRIVATE_USERS = `${API_BASE_URL}/api/private/users`;
	public static API_PRIVATE_USERS_ID = (userId: string) => `${API_BASE_URL}/api/private/users/${userId}`;
	public static API_PRIVATE_USERS_ID_OTP = (userId: string) => `${API_BASE_URL}/api/private/users/${userId}/otp`;
	public static API_PRIVATE_USERS_ID_ROLE = (userId: string) => `${API_BASE_URL}/api/private/users/${userId}/role`;
	public static API_PRIVATE_USERS_ID_STATUS = (userId: string) => `${API_BASE_URL}/api/private/users/${userId}/status`;

	// Login Controller
	public static API_PRIVATE_USER = `${API_BASE_URL}/api/private/user`;
	public static API_PRIVATE_USER_ACCESS = `${API_BASE_URL}/api/private/user/access`;

	// User Security Controller
	public static API_PRIVATE_USER_SECURITY = `${API_BASE_URL}/api/private/user/security`;
	public static API_PRIVATE_USER_SECURITY_OTP = `${API_BASE_URL}/api/private/user/security/otp`;
	public static API_PRIVATE_USER_SECURITY_PASSWORD = `${API_BASE_URL}/api/private/user/security/password`;
	public static API_PUBLIC_USER_SECURITY_OTP = `${API_BASE_URL}/api/public/user/security/otp`;

	// Dashboard Controller
	public static API_PRIVATE_DASHBOARD_WALLETS = `${API_BASE_URL}/api/private/dashboard/wallets`;

	// Data Center Controller
	public static API_PRIVATE_DATACENTER_DOWNLOAD = `${API_BASE_URL}/api/private/datacenter/download`;

	// GPD Controller
	public static API_PRIVATE_GPD_BURNING = `${API_BASE_URL}/api/private/gpd/burning`;
	public static API_PRIVATE_GPD_HISTORIES = `${API_BASE_URL}/api/private/gpd/histories`;
	public static API_PRIVATE_GPD_MINTING = `${API_BASE_URL}/api/private/gpd/minting`;
	public static API_PRIVATE_GPD_STATUS = `${API_BASE_URL}/api/private/gpd/status`;
	public static API_PRIVATE_GPD_WALLETS = `${API_BASE_URL}/api/private/gpd/wallets`;

	// Network Controller
	public static API_PRIVATE_NETWORKS = `${API_BASE_URL}/api/private/networks`;
	public static API_PRIVATE_NETWORKS_ID_TOKENS = (networkId: string) => `${API_BASE_URL}/api/private/networks/${networkId}/tokens`;
	public static API_PRIVATE_NETWORKS_ID_WALLETS = (networkId: string) => `${API_BASE_URL}/api/private/networks/${networkId}/wallets`;
	public static API_PRIVATE_NETWORKS_ID_WALLETS_ID_TOKENS = (networkId: string, walletId: string) => `${API_BASE_URL}/api/private/networks/${networkId}/wallets/${walletId}/tokens`;
	public static API_PRIVATE_NETWORKS_ID_GAS_PRICE = (networkId: string) => `${API_BASE_URL}/api/private/networks/${networkId}/gas-price`;
	public static API_PRIVATE_NETWORKS_ID_GAS_FEE = (networkId: string) => `${API_BASE_URL}/api/private/networks/${networkId}/gas-fee`;

	// Role Controller
	public static API_PRIVATE_ROLES = `${API_BASE_URL}/api/private/roles`;

	// Token Controller
	public static API_PRIVATE_TOKENS = `${API_BASE_URL}/api/private/tokens`;
	public static API_PRIVATE_TOKENS_ID_WALLETS = (tokenId: string) => `${API_BASE_URL}/api/private/tokens/${tokenId}/wallets`;
	public static API_PRIVATE_TOKENS_ID_WALLETS_BALANCE = (tokenId: string) => `${API_BASE_URL}/api/private/tokens/${tokenId}/wallets/balance`;




	// Fee Record Controller
	public static API_PRIVATE_TRANSACTIONS_FEES = `${API_BASE_URL}/api/private/transactions/fees`;
	public static API_PRIVATE_TRANSACTIONS_FEES_REALIZATION = `${API_BASE_URL}/api/private/transactions/fees/realization`;
	public static API_PRIVATE_TRANSACTIONS_FEES_SUMMARY = `${API_BASE_URL}/api/private/transactions/fees/summary`;
	public static API_PRIVATE_TRANSACTIONS_FEE_TOKENS = `${API_BASE_URL}/api/private/transactions/fee/tokens`;
	public static API_PRIVATE_TRANSACTIONS_FEES_HISTORIES = `${API_BASE_URL}/api/private/transactions/fees/histories`;
	public static API_PRIVATE_TRANSACTIONS_FEES_TOTAL = `${API_BASE_URL}/api/private/transactions/fees/total`;
	public static API_PRIVATE_TRANSACTIONS_FEES_HISTORIES_TRANSACTIONID_DOWNLOAD = (txId:string)=> `${API_BASE_URL}/api/private/transactions/fees/histories/${txId}/download`;


	// Transaction Controller
	public static API_PRIVATE_TRANSACTIONS = `${API_BASE_URL}/api/private/transactions`;
	public static API_PRIVATE_TRANSACTIONS_ID = (TransactionId:number)=> `${API_BASE_URL}/api/private/transactions/${TransactionId}`;
	public static API_PRIVATE_TRANSACTIONS_ID_REFUND = (TransactionId:number)=> `${API_BASE_URL}/api/private/transactions/${TransactionId}/refund`;
	public static API_PRIVATE_TRANSACTIONS_ID_RESEND = (TransactionId:number)=> `${API_BASE_URL}/api/private/transactions/${TransactionId}/resend`;
	public static API_PRIVATE_TRANSACTIONS_PENDING_ID_RESEND = (TransactionId:string)=> `${API_BASE_URL}/api/private/transactions/pendings/${TransactionId}/resend`;
	public static API_PRIVATE_TRANSACTIONS_PENDING = `${API_BASE_URL}/api/private/transactions/pendings`;
	public static API_PRIVATE_TRANSACTIONS_PENDING_SUMMARY = `${API_BASE_URL}/api/private/transactions/pendings/summary`;
	public static API_PRIVATE_TRANSACTIONS_PROCESS_STATUS = `${API_BASE_URL}/api/private/transactions/process-status`;

	// User Transaction Controller
	public static API_PRIVATE_USER_TRANSACTION = `${API_BASE_URL}/api/private/user-transaction`;

	// Wallet Connect Controller
	public static API_PRIVATE_WALLET_CONNECTS = `${API_BASE_URL}/api/private/wallet-connects`;
	public static API_PRIVATE_WALLET_CONNECTS_ID = (walletConnectId: string) => `${API_BASE_URL}/api/private/wallet-connects/${walletConnectId}`;
	public static API_PRIVATE_WALLET_CONNECTS_ID_BASIC = (walletConnectId: string) => `${API_BASE_URL}/api/private/wallet-connects/${walletConnectId}/basic`;
	public static API_PRIVATE_WALLET_CONNECTS_ID_FEE = (walletConnectId: string) => `${API_BASE_URL}/api/private/wallet-connects/${walletConnectId}/fee`;
	public static API_PRIVATE_WALLET_CONNECTS_ID_STATUS = (walletConnectId: string) => `${API_BASE_URL}/api/private/wallet-connects/${walletConnectId}/status`;

	// Wallet Distribute Controller
	public static API_PRIVATE_WALLET_DISTRIBUTE = `${API_BASE_URL}/api/private/wallet-distribute`;
	public static API_PRIVATE_WALLET_DISTRIBUTE_HISTORIES =  `${API_BASE_URL}/api/private/wallet-distribute/histories`;


	// Wallet Controller
	public static API_PRIVATE_WALLETS = `${API_BASE_URL}/api/private/wallets`;
	public static API_PRIVATE_WALLETS_ID_ALERTS = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/alerts`;
	public static API_PRIVATE_WALLETS_ID_BASIC = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/basic`;
	public static API_PRIVATE_WALLETS_ID_GAS_FEE = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/gas-fee`;
	public static API_PRIVATE_WALLETS_ID_STATUS = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/status`;
	public static API_PRIVATE_WALLETS_ID_BALANCE = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/balance`;
	public static API_PRIVATE_WALLETS_ID_TOKENS = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/tokens`;
	public static API_PRIVATE_WALLETS_ID_TOKENS_BALANCE = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/tokens/balance`;
	public static API_PRIVATE_WALLETS_ID_TOKENS_ID_BALANCE = (walletId: string, tokenId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/tokens/${tokenId}/balance`;
	public static API_PRIVATE_WALLETS_ID_USAGE = (walletId: string) => `${API_BASE_URL}/api/private/wallets/${walletId}/usage`;
	public static API_PRIVATE_WALLETS_ADMINS = `${API_BASE_URL}/api/private/wallets/admins`;
	public static API_PRIVATE_WALLETS_VALIDATION = `${API_BASE_URL}/api/private/wallets/validation`;

	// Log Controller
	public static API_PRIVATE_LOGS = `${API_BASE_URL}/api/private/logs`;
	public static API_PRIVATE_LOGS_DOWNLOAD = `${API_BASE_URL}/api/private/logs/download`;
}