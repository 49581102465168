import tether from "../../assets/symbols/tether.png"
import gpex from "../../assets/symbols/gpex.png"
import ethereum from "../../assets/symbols/ethereum.png"
import React from "react";
import {Dto_GetWalletBalancesResult} from "../../api/dashboard/dashboard_dto";
import {getMainnetSymbol} from "../../utils/util";
import CLinkExplorer from "../components/CLinkExplorer";

function getTokenUnit(networkName: string, tokenName: string) {
	if(tokenName) return tokenName;
	if(networkName === "Ethereum") return "ETH";
	else if(networkName === "GPEX") return "GPX";
}

export default function DashboardPageListItem(props: Dto_GetWalletBalancesResult) {

	let symbol = <img
		src={gpex}
		width="36"
		height="36"
		alt="settings"
		style={{ marginRight: "16px"}}
	/>
	if(props.networkName === "Ethereum" && props.tokenName === 'USDT') {
		symbol = <img
			src={tether}
			width="36"
			height="36"
			alt="settings"
			style={{ marginRight: "16px"}}
		/>
	}
	else if(props.networkName === "Ethereum" && props.tokenName === 'ETH') {
		symbol = <img
			src={ethereum}
			width="36"
			height="36"
			alt="settings"
			style={{ marginRight: "16px"}}
		/>
	}
	else if(props.networkName === "Ethereum" && (props.tokenName === '' || props.tokenName === undefined)) {
		symbol = <img
			src={ethereum}
			width="36"
			height="36"
			alt="settings"
			style={{ marginRight: "16px"}}
		/>
	}

	return <div className="p-1">
		<div className={"p-4 bg-white"}>
			<div className={"h5 mb-3"}>{`${props.walletName} (${getTokenUnit(props.networkName, props.tokenName)})`}</div>
			<div>
				<div style={{ display: "flex", flexDirection: "row",
					justifyContent: "space-between", alignItems: "center",
					marginBottom: "8px"
				}}>
					{symbol}
					{/*<div style={{ color: "#F4B12E"}}>{props.address}</div>*/}
					<CLinkExplorer
						target={props.networkName} type={"address"} address={props.address}
						text={props.address} copyText={props.address} width={300}
					/>
				</div>
			</div>
			<div>
				<div style={{ display: "flex", flexDirection: "row",
					justifyContent: "space-between", alignItems: "center",
					marginBottom: "8px"
				}}>
					<div>Amount</div>
					<div>{`${props.tokenBalance} ${getTokenUnit(props.networkName, props.tokenName)}`}</div>
				</div>
			</div>
			<div>
				<div style={{ display: "flex", flexDirection: "row",
					justifyContent: "space-between", alignItems: "center",
					marginBottom: "8px"
				}}>
					<div>Gas Fee Balance</div>
					<div>{`${props.walletBalance} ${getMainnetSymbol(props.networkName)}`}</div>
				</div>
			</div>
		</div>
	</div>
}