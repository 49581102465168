import {Button, Container, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Dto_GetAdminListResult} from "../../../../api/admin/admin_dto";
import danger from "../../../../assets/icons/danger.png";
import CTextField from "../../../components/CTextField";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {AdminApi} from "../../../../api/admin/AdminApi";
import {UserApi} from "../../../../api/user/UserApi";

export interface ITransactionDetailRefundModal {
    show: boolean
    setShow: any
    onCancel?: any
    onOk : Function
}

export default function TransactionDetailRefundModal(props:ITransactionDetailRefundModal){
    const [otpCode, setOtpCode] = useState('');


    return(
        <>


        <Modal
                show={props.show}
                onHide={()=>{
                    if(props.onCancel) props.onCancel();
                    props.setShow(false)
                }}
                backdrop="static"
                keyboard={false}
        >
            <Form>
            <Modal.Header closeButton style={{ border: "none" }}>
                <Modal.Title>Refund Conversion</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ border: "none" }}>
                <Container className={"d-flex flex-column align-items-center"}>
                    <div className={"text-secondary text-center"}>Would you like to <strong>Refund</strong> the selected transaction?<br/>Received Asset will be returned to the owner</div>
                    <div className={"ps-3 pe-3 mb-4"}>
                        <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} placeholder={"Enter Google authentication code"} />
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                <Button
                    style={{ width: "360px"}}
                    variant="danger"
                    size={"lg"}
                    type={"submit"}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onOk(otpCode);
                        props.setShow(false);
                    }}>
                    Refund
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        </>
    )

}