import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSearchTextField from "../../components/CSearchTextField";

interface ITransactionRecordPageFilter{
	listAmountData : IDropdown
	setListAmountData : Function
	sortData : IDropdown
	setSortData : Function
	statusData : IDropdown
	setStatusData : Function
	onSearch : Function

}


const listAmountData: IDropdown[] = [
	{
		name : "25",
		value : "25",
	},
	{
		name : "50",
		value : "50",
	},
	{
		name : "100",
		value : "100",
	}
]

const sortData = [
	{
		name : "Deposit (Old)",
		value : "DATE_ASC"
	},
	{	name : "Deposit (Recent)",
		value : "DATE_DESC"
	},
	{
		name : "Deposit Amount (High)",
		value : "DEPOSIT_DESC"
	},
	{
		name : "Deposit Amount (Low)",
		value : "DEPOSIT_ASC"
	}

]

const statusData = [

	{
		name : "ALL",
		value : ""
	},

	{
		name : "Approved",
		value : "APPROVED",
	},
	{
		name : "Unrecognized",
		value : "UNRECOGNIZED"
	},
	{
		name : "Refund",
		value : "REFUND"
	},
	{
		name : "Failed",
		value : "FAILED"
	}
]


export default function TransactionRecordPageFilter(props:ITransactionRecordPageFilter) {
	return <Row className="mb-3 align-items-end">
			<Col xs={2}>
				<CDropdown id={"transaction-record-dropdown-list-display"} hint={"List Display"}
						   data={listAmountData}
						   selected={props.listAmountData}
						   onSelected={props.setListAmountData}/>
			</Col>
			<Col xs={3}>
				<CDropdown id={"transaction-record-dropdown-sort-by"} hint={"Sort by"}
						   data={sortData}
						   selected={props.sortData}
						   onSelected={props.setSortData}></CDropdown>
			</Col>
			<Col xs={2}>
				<CDropdown id={"transaction-record-dropdown-status"} hint={"Status"}
						   data={statusData}
						   selected={props.statusData}
						   onSelected={props.setStatusData}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"transaction-record-search"} placeholder={"Pair ID"} onSearch={props.onSearch}/>
			</Col>
		</Row>;
}