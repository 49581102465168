import {Button, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Dto_GetTransactionApproveResult} from "../../../api/wallet_connect/wallet_connect_dto";
import {weiToGwei} from "../../../utils/util";
import 'styles/listItem.css';
import CLocaleTimestamp from "../../components/CLocaleTimestamp";

const statusColor = (status?:string)=>{
	if (status==="APPROVED") return "success"
	else if (status==="UNRECOGNIZED") return "secondary"
	else if (status==="REFUND") return "danger"
	else if (status==="FAILED") return "secondary"
	else if (status==="SENDING") return "secondary"
	else if (status==="READY") return "danger"
	else if (status==="PENDING") return "primary"
	else if (status==="COMPLETE") return "success"
	else{
		return ""
	}
}

export default function TransactionRecordPageListItem(props: Dto_GetTransactionApproveResult) {
	const navigate = useNavigate();
	const onItemClicked = () => {
		navigate(`/wallet-connect/record/detail?=${props.txPairId}`, {
			state: {
				txPairId: props.txPairId
			}
		})
	}

	const calculateCommission = (userNetworkFee:number|undefined, userConversionFee:number|undefined) =>{
		if (userNetworkFee===undefined || userConversionFee===undefined) return;
		return userNetworkFee+userConversionFee;
	}
	
	return <Row
		style={{ cursor: "pointer"}}
		onClick={onItemClicked}
		className="p-3 bg-white mb-2 moveItem"
	>
		<Col


		>{props.txPairId?.toString()}</Col>
		<Col>
			{props.depositDate?<CLocaleTimestamp timestamp={props.depositDate}/> : "-"}
		</Col>
		<Col>
			<Row>{`${props.depositAmount} ${props.depositTokenName}`}</Row>
			<Row>{`(${props.depositNetworkName})`}</Row>
		</Col>
		<Col>{'>>'}</Col>
		<Col>
			{props.withdrawalDate?<CLocaleTimestamp timestamp={props.withdrawalDate}/> : "-"}
		</Col>
		<Col>
			<Row>{`${props.withdrawalAmount === null || props.withdrawalAmount === undefined ? "-" : props.withdrawalAmount} ${props.withdrawalTokenName}`}</Row>
			<Row>{`(${props.withdrawalNetworkName})`}</Row>
		</Col>
		<Col>
			<Row>{calculateCommission(props.userNetworkFee,props.userConversionFee)} {props?.withdrawalTokenName?props.withdrawalTokenName:"-"}</Row>
			<Row>{`(${props?.withdrawalNetworkName?props.withdrawalNetworkName:"-"})`}</Row>
		</Col>
		<Col>
			<Row>
				<Col className={"mb-2"}><Button variant={statusColor(props.status)} style={{ cursor: "default" }} disabled>{props.status}</Button></Col>
				{
					props.status === ("APPROVED"||"REFUND")? <Col><Button variant={`outline-${statusColor(props.blockchainStatus)}`} style={{ cursor: "default" }} disabled>{props.blockchainStatus}</Button></Col> : null
				}
			</Row>
		</Col>
	</Row>;
}