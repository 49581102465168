export const COLOR_MAIN = "#f4b12e";
export const FETCH_SIZE = 50;

export const MESSAGE_FILL_THE_BLANK = 'Please fill in the blank.'
export const MESSAGE_INVALID_VALUE = 'Please enter a valid value.'
export const MESSAGE_CHANGES_SAVED = 'Changes have been successfully saved.'
export const MESSAGE_CHANGES_FAILED = 'There was an error saving your changes.'

// CURRENCY UNITS
export const COIN_ETH = "ETH";
export const COIN_GPX = "GPX";

export const TOKEN_GPD = "GPD";
export const TOKEN_USDT = "USDT";