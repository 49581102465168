import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../../api/wallet_connect/wallet_connect_dto";
import CLocaleTimestamp from "../../components/CLocaleTimestamp";
interface ITransactionDetailPageWithdrawalRefundInformation{
	detail?:Dto_GetTransactionDetail
}

export default function TransactionDetailPageWithdrawalRefundInformation(props:ITransactionDetailPageWithdrawalRefundInformation) {
	return <Container>
		<Row style={{ width: "620px", height: "400px" }} className={"p-4 mb-4 bg-white rounded d-flex flex-column align-items-start"}>
			<Row className={"h4 mb-3 pb-3 border-2 border-bottom"}>Withdrawal / Refund Information</Row>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Date</div>
				<div>{props.detail?.withdrawalDate?<CLocaleTimestamp timestamp={props.detail.withdrawalDate}/>:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Event</div>
				<div>{props.detail?.withdrawalTokenName?`Withdrawal ${props.detail.withdrawalTokenName}`:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>System Status</div>
				<div>{props.detail?.status?props.detail.status:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Approved / Rejected By</div>
				<div>{props.detail?.unrecognizedApprovedEmail?props.detail?.unrecognizedApprovedEmail:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Blockchain Tx Status</div>
				<div>{props.detail?.blockchainStatus?props.detail.blockchainStatus:"-"}</div>
			</div>
		</Row>
	</Container>
}