import {Container} from "react-bootstrap";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import AccountSettingsPageUpperCard from "./AccountSettingsPageUpperCard";
import AccountSettingsPageBottomCard from "./AccountSettingsPageBottomCard";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {logout} from "../../../utils/session";
import AccountSettingsEditAccountInfoModal from "./modals/AccountSettingsEditAccountInfoModal";
import {Dto_GetAccountInfoResult} from "../../../api/user/user_dto";
import {UserApi} from "../../../api/user/UserApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../constants/value";
import AccountSettingsEditPasswordModal from "./modals/AccountSettingsEditPasswordModal";
import AccountSettingsDisableOTPModal from "./modals/AccountSettingsDisableOTPModal";

export default function AccountSettingsPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	// user info
	const [userInfo, setUserInfo] = useState<Dto_GetAccountInfoResult>();
	const [name, setName] = useState('');
	const [mobile, setMobile] = useState('');
	const [chatId, setChatId] = useState('');

	const [tfa, setTfa] = useState(false);
	const [editAccount, setEditAccount] = useState(false);
	const [editPassword, setEditPassword] = useState(false);
	const [disableOTP, setDisableOTP] = useState(false);

	const [authFunction, setAuthFunction] = useState<any>(() => {

	});

	useEffect(() => {
		fetchUserDetail();
	}, [])

	async function fetchUserDetail() {
		setLoading(true);
		const result = await UserApi.getAccountInfo();
		if(result && result.data.detail) {
			setLoading(false)
			setUserInfo(result.data.detail);
			setName(result.data.detail.userName);
			setMobile(result.data.detail.mobile);
			setChatId(result.data.detail.chatId);
		} else {
			setLoading(false)
			setUserInfo(undefined);
		}
	}

	return <Container>
		<AccountSettingsEditAccountInfoModal
			name={name} setName={setName}
			mobile={mobile} setMobile={setMobile}
			chatId={chatId} setChatId={setChatId}
			show={editAccount} setShow={setEditAccount} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AccountSettingsEditPasswordModal show={editPassword} setShow={setEditPassword} onConfirm={(result: boolean) => {
			if(result) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<AccountSettingsDisableOTPModal show={disableOTP} setShow={setDisableOTP} onConfirm={(result: boolean) => {
			if(result) {
				logout();
				navigate('/login');
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}}/>
		<CBreadCrumb first={"Account Settings"} firstUrl={"/settings/account"}/>
		<CPageTitle title={"Account Settings"} subtitle={""}/>
		<AccountSettingsPageUpperCard userInfo={userInfo} setEditAccount={setEditAccount} setPassword={setEditPassword} setDisableOTP={setDisableOTP}/>
		<div style={{ marginBottom: "16px"}}/>
		<AccountSettingsPageBottomCard {...userInfo!}/>
	</Container>
}