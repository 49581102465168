import {Button, Col, Container, Row} from "react-bootstrap";
import CListPage from "../components/CListPage";
import CPageTitle from "../components/CPageTitle";
import LogDataPageListItem from "./LogDataPageListItem";
import LogDataPageListHeader from "./LogDataPageListHeader";
import CBreadCrumb from "../components/CBreadCrumb";
import {useEffect, useState} from "react";
import {LogApi} from "../../api/log/LogApi";
import {Dto_GetLogListResult} from "../../api/log/log_dto";
import LogDataPageFilter from "./LogDataPageFilter";
import CSimplePagination from "../components/CSimplePagination";
import CLoadingDimmer from "../components/CLoadingDimmer";
import CTotalCount from "../components/CTotalCount";
import {IDropdown} from "../components/CDropdown";
import {logout} from "../../utils/session";
import {useNavigate} from "react-router-dom";
import {TokenManager} from "../../states/token";
import Axios from "axios";
import {ApiUrl} from "../../constants/ApiUrl";

export default function LogDataPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [index, setIndex] = useState(1);
	const [list, setList] = useState<Dto_GetLogListResult[]>([])
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});
	const [category, setCategory] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);
	const [type, setType] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);
	const [status, setStatus] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);

	const generateItem = (value: Dto_GetLogListResult) => {
		return <LogDataPageListItem key={value.id} {...value} />
	}

	useEffect(() => {
		fetchLogList(1)
	}, [])

	async function fetchLogList(page: number, search?: string) {
		if(loading) return;
		setLoading(true);
		const result = await LogApi.getLogList({
			pageNumber: page,
			pageSize: listDisplay.value ? parseInt(listDisplay.value) : 25,
			category: category.value,
			type: type.value,
			status: status.value,
			searchFilter: search
		});
		if(result && result.data.pagingInfo.totalCount && result.data.list) {
			try {
				setTotalCount(result.data.pagingInfo.totalCount)
				if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
				setList(result.data.list);
				setLoading(false);
			} catch(e) {
				setTotalCount(0)
				if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
				setList([]);
				setLoading(false)
			}
		}
		else {
			setTotalCount(0)
			if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
			setList([]);
			setLoading(false)
		}
	}

	async function onDownload() {
		if(loading) return;
		setLoading(true);
		const accessToken = TokenManager.getAccessToken();
		const refreshToken = TokenManager.getRefreshToken();
		if(!accessToken || !refreshToken) logout();
		try {
			Axios(ApiUrl.API_PRIVATE_LOGS_DOWNLOAD, {
				method: "get",
				headers: {
					'Content-Type': 'application/json',
					'Cookie': 'gpex_lang=ko',
					"Authorization": `Bearer ${accessToken}`
				},
				responseType: "blob",
				params: {
					pageNumber: 1,
					pageSize: 10000,
					// category:
					// type:
					// status:
					// searchFilter:
				}
			}).then((response) => {
				const date = new Date();
				const name = `activity_log_${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}.csv`;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", name);
				link.style.cssText = "display:none";
				document.body.appendChild(link);
				link.click();
				link.remove();
			}).catch((error) => {

			}).finally(() => {
				setLoading(false);
			});
		} catch (e) {

		}
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Activity Logs"} firstUrl={"/log/activity"}/>
		<CPageTitle title={"Activity Logs"} subtitle={"You can check activity history of all administrators that occurred in the admin console."}/>

		<Row>
			<Col>
				{totalCount !== 0 ? <CTotalCount totalCount={totalCount}/> : <div/> }
				<CSimplePagination
					index={index}
					setIndex={setIndex}
					loading={loading}
					onSubmit={(page: number) => fetchLogList(page)}
					totalCount={totalCount}
					pageSize={pageSize}
				/>
			</Col>
			<Col className={"d-flex justify-content-end align-items-end"}>
				<Button variant="dark"
				        onClick={onDownload}>CSV Download</Button>
			</Col>
		</Row>
		<LogDataPageFilter
			listDisplay={listDisplay} setListDisplay={setListDisplay}
			category={category} setCategory={setCategory}
			type={type} setType={setType}
			status={status} setStatus={setStatus}
			onSearch={(search: string) => {
				setIndex(1)
				fetchLogList(1, search)
			}}
		/>
		<LogDataPageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}