import {Col, Form, Row} from "react-bootstrap";
import CDropdown, { IDropdown } from "../../../components/CDropdown";
import CSearchTextField from "../../../components/CSearchTextField";

interface ITransactionPendingPageWalletConnectInFilter {
	selectedEvent : IDropdown,
	setSelectedEvent : Function,
	selectedToken ?: IDropdown,
	setSelectedToken : Function,
	selectTokenDropdown : IDropdown[],
	setSelectTokenDropdown : Function,
	selectedListDisplay : IDropdown,
	setSelectedListDisplay : Function,
	onSearch : Function,
}

export default function TransactionPendingPageWalletConnectInFilter(props:ITransactionPendingPageWalletConnectInFilter) {

	const listData:IDropdown[] = [
		{
			name : "25",
			value : "25",
		},
		{
			name : "50",
			value : "50",
		},
		{
			name : "100",
			value : "100",
		}
	]

	const eventDropdown: IDropdown[] = [
		{
			name: "In",
			value: "WALLET_IN",
		},
	];

	return <Row className="mb-3 align-items-end">
		<Col xs={2}>
			<CDropdown id={"transaction-record-dropdown-list-display"} hint={"List Display"} data={listData} selected={props.selectedListDisplay} onSelected={props.setSelectedListDisplay}></CDropdown>
		</Col>
		<Col xs={2}>
			<CDropdown id={"transaction-record-dropdown-sort-by"} hint={"Event"} data={eventDropdown} selected={props.selectedEvent} onSelected={props.setSelectedEvent} ></CDropdown>
		</Col>
		<Col xs={2}>
			<CDropdown id={"transaction-record-dropdown-status"} hint={"Select Token"} data={props.selectTokenDropdown} selected={props.selectedToken}  onSelected={props.setSelectedToken}></CDropdown>
		</Col>
		<Col></Col>
		<Col xs={4} className={"d-flex justify-content-end"}>
			<CSearchTextField id={"transaction-record-search"} placeholder={"Pair ID"} onSearch={props.onSearch}/>
		</Col>
	</Row>;
}