import {Button, Col, Container, Row} from "react-bootstrap";
import TransactionDetailPageDepositInformation from "./TransactionDetailPageDepositInformation";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import TransactionDetailPageTransactionInformationDeposit from "./TransactionDetailPageTransactionInformationDeposit";
import TransactionDetailPageWithdrawalRefundInformation from "./TransactionDetailPageWithdrawalRefundInformation";
import TransactionDetailPageTransactionInformationWithdraw from "./TransactionDetailPageTransactionInformationWithdraw";
import TransactionDetailPageChargeFee from "./TransactionDetailPageChargeFee";
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {WalletConnectApi} from "../../../api/wallet_connect/WalletConnectApi";
import {
	Dto_GetTransactionApproveResult,
	Dto_GetTransactionDetail
} from "../../../api/wallet_connect/wallet_connect_dto";
import TransactionDetailRefundModal from "./modals/TransactionDetailRefundModal";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../constants/value";
import {SmartContractApi} from "../../../api/smart_contract/SmartContractApi";
import TransactionDetailResendModal from "./modals/TransactionDetailResendModal";
import {ApiUrl} from "../../../constants/ApiUrl";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import {UserApi} from "../../../api/user/UserApi";

export interface ITransactionDetailPage {

}

export default function TransactionDetailPage() {


	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const query = searchParams.get('pairId');
	const [loading,setLoading] = useState(false);
	const [ refund, setRefund ] = useState(false);
	const [ resend, setResend ] = useState(false);
	const [detail,setDetail] = useState<Dto_GetTransactionDetail>();

	const [accessResend, setAccessResend] = useState(false);
	const [accessRefund, setAccessRefund] = useState(false);

	const txPairId = location.state.txPairId;
	const status = location.state.status;

	useEffect(()=>{
		fetchTransactionDetail()
		getAccess();
	},[])

	async function fetchTransactionDetail(){
		if(loading) return;
		setLoading(true);
		const result = await WalletConnectApi.getTransactionDetail(txPairId);

		if(result){
			try{
				setDetail(result.data.detail);
				setLoading(false);
			}catch(e){
				setDetail(undefined);
				setLoading(false);
			}

		} else {
			setDetail(undefined);
			setLoading(false);
		}
	}

	async function getAccess() {
		const promises = [
			UserApi.getUserAccess("RESEND_FAIL_TR"),
			UserApi.getUserAccess("REFUND_UNRECOGNIZED_TR")
		]
		const [res1, res2] = await Promise.all(promises);
		setAccessResend(res1);
		setAccessRefund(res2);
	}

	async function refunding(otpCode: string) {
		if(loading) return;
		try {
			setLoading(true);
			WalletConnectApi.setRefund(txPairId, {
				otpCode : otpCode
			})
				.then((res) => {
				if(res.success) {
					window.location.reload();
					alert(MESSAGE_CHANGES_SAVED)
					navigate(-1);
				} else {
				}
			}).catch((error) => {
				alert(error)
			}).finally(() => {
				setLoading(false);
			})
		} catch(e) {
			return;
		}
	}

	async function resending(otpCode: string) {
		if(loading) return;
		try {

			setLoading(true);
			WalletConnectApi.setResend(txPairId, {
				otpCode : otpCode
			})
				.then((res) => {
					if(res.success) {
						window.location.reload();
						alert(MESSAGE_CHANGES_SAVED)
						navigate(-1);
					} else {
					}
				}).catch((error) => {
				alert(error)
			}).finally(() => {
				setLoading(false);
			})
		} catch(e) {
			return;
		}
	}

	const detailBtn = (status:string) => {
		if (status==="UNRECOGNIZED") {
			return <Button className={"mt-4 mb-4"}
			               size={"lg"}
			               variant={"danger"}
			               disabled={!accessRefund}
			               onClick={showRefundModal}
			>Refund</Button>
		}else if(status ==="FAILED"){
			return <Button className={"mt-4 mb-4"}
			               size={"lg"}
			               variant={"success"}
			               disabled={!accessResend}
			               onClick={showResendModal}
			>Resend</Button>
		}
	}


	const showRefundModal = () => {
		setRefund(true);
	}

	const showResendModal = () =>{
		setResend(true);
	}


	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<TransactionDetailRefundModal show={refund} setShow={setRefund}
		                              onOk={(otpCode:string) => {
			                              refunding(otpCode)
		                              }}/>

		<TransactionDetailResendModal show={resend} setShow={setResend}
		                              onOk={(otpCode:string) => {
			                              resending(otpCode)
		                              }}/>
		<CBreadCrumb
			first={"Transaction Approve"} firstUrl={"/wallet-connect/approve"}
			second={"Transaction Detail"} secondUrl={""}
		/>
		<CPageTitle title={"Transaction Detail"} subtitle={`Tx Pair ID: ${detail?.txPairId?detail.txPairId:"-"}`}/>
		<Row>
			<div className={"d-flex justify-content-end"}>
				{detailBtn(status)}
			</div>
		</Row>
		<Row>
			<Col>
				<TransactionDetailPageDepositInformation detail={detail}/>
				<TransactionDetailPageTransactionInformationDeposit detail={detail}/>
			</Col>
			<Col>
				<TransactionDetailPageWithdrawalRefundInformation detail={detail}/>
				<TransactionDetailPageTransactionInformationWithdraw detail={detail}/>
				<TransactionDetailPageChargeFee detail={detail}/>
			</Col>
		</Row>
	</Container>
}