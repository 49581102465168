import {Button, Modal, Form} from "react-bootstrap";
import {useState} from "react";
import CSelectTextField from "../../components/CSelectTextField";
import CCalendarModal from "../../components/modal/CCalendarModal";
import {TokenManager} from "../../../states/token";
import {logout} from "../../../utils/session";
import Axios from "axios";
import {ApiUrl} from "../../../constants/ApiUrl";

export interface IVerifyTFAModalComponent {
    show: boolean, setShow: any,
    loading: boolean, setLoading: Function
    onCancel?: any,
    onOk: Function,
    selectedValueList: string[],
}

export default function DashboardPageDataCenterModal(props: IVerifyTFAModalComponent) {
    const [showCalendarToModal, setShowCalendarToModal] = useState(false);
    const [showCalendarFromModal, setShowCalendarFromModal] = useState(false);
    const [selectDateFrom, setSelectDateFrom] = useState(new Date());
    const [selectDateTo, setSelectDateTo] = useState(new Date());

    async function onDownload(type: string) {
        const accessToken = TokenManager.getAccessToken();
        const refreshToken = TokenManager.getRefreshToken();

        const fromDate = `${selectDateFrom.getFullYear()}-${(selectDateFrom.getMonth()+1).toString().padStart(2, '0')}-${selectDateFrom.getDate().toString().padStart(2, '0')}`;
        const toDate = `${selectDateTo.getFullYear()}-${(selectDateTo.getMonth()+1).toString().padStart(2, '0')}-${selectDateTo.getDate().toString().padStart(2, '0')}`;

        if(!accessToken || !refreshToken) logout();
        try {
            Axios(ApiUrl.API_PRIVATE_DATACENTER_DOWNLOAD, {
                method: "get",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${accessToken}`
                },
                responseType: "blob",
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                    data: type,
                }
            }).then((response) => {
                const date = new Date();
                const name = `DATA_CENTER_${type}_${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}.csv`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", name);
                link.style.cssText = "display:none";
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch((error) => {

            })
        } catch (e) {

        }
    }

    async function setDownloadSchedule() {
        let downloadSchedule: any[] = [];
        props.selectedValueList.map((value) => {
            downloadSchedule.push(onDownload(value));
        })

        if(props.loading) return;
        props.setLoading(true);
        await Promise.all(downloadSchedule);
        props.setLoading(false);
    }

    return (
        <>
            <CCalendarModal show={showCalendarFromModal} setShow={setShowCalendarFromModal}
                            onOk={(date: Date) => {
                                setSelectDateFrom(date);
                            }}/>

            <CCalendarModal show={showCalendarToModal} setShow={setShowCalendarToModal}
                            onOk={(date: Date) => {
                                setSelectDateTo(date);
                            }}/>
            <Modal
            show={props.show}
            onHide={()=>{
                if(props.onCancel) props.onCancel();
                props.setShow(false)
            }}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton style={{ border: "none" }}>
                <Modal.Title>Download Data</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none", textAlign: "center" }}>
                <div className={"mb-2 d-flex justify-content-start align-items-center"}>
                    <div style={{ width: "120px" }}>From</div>
                    <div>
                        <CSelectTextField
                            id={"data-center-select-date-to"}
                            placeholder={`${selectDateFrom.getFullYear()}/${selectDateFrom.getMonth()+1}/${selectDateFrom.getDate() } - ${selectDateFrom.getHours()}:${selectDateFrom.getMinutes()}:${selectDateFrom.getSeconds()}`}
                            onSelect={()=>{
                                setShowCalendarFromModal(true);
                            }}/>
                    </div>
                </div>
                <div className={"mb-2 d-flex justify-content-start align-items-center"}>
                    <div style={{ width: "120px" }}>To</div>
                    <div>
                        <CSelectTextField
                            id={"data-center-select-date-from"}
                            placeholder={`${selectDateTo.getFullYear()}/${selectDateTo.getMonth()+1}/${selectDateTo.getDate() } - ${selectDateTo.getHours()}:${selectDateTo.getMinutes()}:${selectDateTo.getSeconds()}`}
                            onSelect={() => {
                                setShowCalendarToModal(true);
                            }}/>
                    </div>
                </div>
            </Modal.Body>
            <div className={"ps-3 pe-3 mb-4"}>
                <div>
                    <h5>Selected Data</h5>
                    <div className={"border border-1 rounded ps-3 pe-3 pt-3 pb-1"}>
                        {props.selectedValueList.map((text) => <div key={text} className={"mb-2"}>
                            {text}
                        </div>)}
                    </div>
                </div>
            </div>
            <Modal.Footer style={{ border: "none" }}>
                <Button
                    variant={"dark"}
                    type={"submit"}
                    onClick={(e) => {
                        e.preventDefault();
                        setDownloadSchedule();
                        props.setShow(false);
                    }}>
                    Confirm
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
};