import {DataResponse} from "../../model/response_model";
import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_GetTransactionProcessStatusResult} from "./transaction_dto";

export class TransactionApi {
	private static instance: TransactionApi
	private constructor() {}

	public static async getTransactionProcessStatus():
		Promise<{code: string, msg: string, data: Dto_GetTransactionProcessStatusResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TRANSACTIONS_PROCESS_STATUS);
	}

	public static async setTransactionProcessStatus(otpCode: string):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_TRANSACTIONS_PROCESS_STATUS, {otpCode});
	}
}