import {Button, Container, Modal} from "react-bootstrap";
import React, { useState,useEffect } from "react";
import { IDropdown_Dto_GetTokenResult, IDropdown_Dto_GetWalletsByTokenResult } from "model/custom_dropdown";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import { TokenApi } from "api/token/TokenApi";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import CTextField from "../../../components/CTextField";
import CLoadingDimmer from "../../../components/CLoadingDimmer";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import { MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK } from "constants/value";

export interface IRealizeFeeModalComponent {
	show: boolean
	setShow: any
	// onConfirm: any
	selectedTokenData ?: IDropdown,
	setSelectedTokenData : Function,
	tokenDropDownList : IDropdown[],
	selectedWalletData ?: IDropdown,
	setSelectedWalletData : Function,
	walletDropDownList : IDropdown[],
	fetchWalletsByToken : Function,
	fetchGetTokenList : Function,
}

export default function CRealizeFeeModal(props: IRealizeFeeModalComponent) {
	const [loading, setLoading] = useState(false);
	const [showTfa,setShowTfa] = useState(false);
	const [selectedToken, setSelectedToken] = useState<IDropdown>();
	const [tokenDropDownList,setTokenDropDownList] = useState<IDropdown_Dto_GetTokenResult[]>([])
	const [selectedMainnet, setSelectedMainnet] = useState("-");
	const [selectedMainnetValue, setSelectedMainnetValue] = useState<number|undefined>();
	const [walletDropDownList, setWalletDropDownList] = useState<IDropdown_Dto_GetWalletsByTokenResult[]>([]);
	const [selectedFromWallet, setSelectedFromWallet] = useState<IDropdown>();
	const [selectedToWallet, setSelectedToWallet] = useState<IDropdown>();
	const [unrealizedFeeTotal, setUnrealizedFeeTotal] = useState("-");
	useEffect(()=>{
		fetchGetTokenList()
		// fetchUnrealizedFeeTotal()

	},[])

	useEffect(()=>{
		fetchUnrealizedFeeTotal();

	},[selectedFromWallet])


	async function fetchGetTokenList() {
		if(loading) return;
		setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setTokenDropDownList(newDropdownList);
		}).catch((error) => {
			setLoading(false);
		}).finally(() => {
			setLoading(false);
		});
	}


	async function fetchWalletsByToken(tokenId: number) {
		if(loading) return;
		// setLoading(true)

		try{
			const res = await TokenApi.getWalletsByToken(tokenId);
			const newWalletDropdownList :IDropdown_Dto_GetWalletsByTokenResult[] = res.data.list.map((item)=>({
				name : item.walletName,
				value : item.walletId.toString(),
				data : item,
			}));
			setWalletDropDownList(newWalletDropdownList);
			// setLoading(false);


		}catch(e){

		}finally{
			// setLoading(false);
		}
	}



	async function fetchUnrealizedFeeTotal(){
		if(loading)return;
		setLoading(true);

		if(!selectedToken?.value||!selectedFromWallet?.value) {
			setLoading(false);
			return;
		};
		try {
			const res = await WalletConnectApi.getUnrealizedFeeTotal({
				tokenId : parseInt(selectedToken?.value),
				walletId : parseInt(selectedFromWallet?.value)
			})
			let totalFee = res.data.totalFee;
			setUnrealizedFeeTotal(totalFee);
			setLoading(false);
		} catch (e) {

		} finally {
			setLoading(false);
		}
	}

	async function setRealizeFee(otpCode:string){
		if(loading)return;
		setLoading(true);

		if(!selectedToken?.value||!selectedFromWallet?.value||!selectedToWallet?.value||!selectedMainnetValue) {setLoading(false); return;}
		try {
			const res = await WalletConnectApi.setRealizeFee({
				fromWalletId : parseInt(selectedFromWallet.value),
				toWalletId : parseInt(selectedToWallet.value),
				tokenId : parseInt(selectedToken.value),
				networkId : selectedMainnetValue,
				otpCode : otpCode
			}).then(res=>{
				if(res && res.success){
					alert(MESSAGE_CHANGES_SAVED)
					window.location.reload();
			}else{
				alert(MESSAGE_CHANGES_FAILED)
			}
			
		})
		}catch(e){

		}finally{
			setLoading(false);
		}
	}

	return (
		<>
			<CLoadingDimmer loading={loading} setLoading={setLoading}></CLoadingDimmer>
			<CVerifyTFAModal show={showTfa} setShow={setShowTfa}  onOk={(otpCode:string)=>{
				setRealizeFee(otpCode);

			}}/>
			<Modal
				show={props.show}
				onHide={()=>props.setShow(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton style={{ border: "none" }}>
					<Modal.Title>Realize Fee</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ border: "none" }}>
					<Container>
						<div className={"mb-3"}>
							<h6 className={"mb-2"}>Select Token</h6>
							<CDropdown id={"realize-fee-modal-select-token-dropdown"} data={tokenDropDownList} selected={selectedToken} onSelected={(select:IDropdown_Dto_GetTokenResult)=>{
								setSelectedToken(select)
								setSelectedMainnet(select.data.networkName);
								setSelectedMainnetValue(select.data.networkId);

								if(selectedToken!==select) {setWalletDropDownList([]); setSelectedFromWallet(undefined); setSelectedToWallet(undefined); setUnrealizedFeeTotal("-")}
								if(!select||!select.value) {
									return;
								}
								fetchWalletsByToken(select.data.tokenId);
							}}/>
						</div>

						<div className={"mb-3"}>
							<h6 className={"mb-2"}>Select Mainnet</h6>
							<CTextField id={"gpd-mint-page-mint-amount-textfield"} text={selectedMainnet} disabled setText={setSelectedMainnet}></CTextField>
						</div>

						<div className={"mb-4"}>
							<h6 className={"mb-2"}>From Wallet</h6>
							<CDropdown id={"realize-fee-modal-from-wallet-dropdown"} data={walletDropDownList} selected={selectedFromWallet} onSelected={(selected:IDropdown)=>{
								if(selected!==selectedToWallet)setSelectedFromWallet(selected);

							}}/>
						</div>
						<div className={"mb-3"}>
							<h6 className={"mb-2"}>Unrealized Fee</h6>
							<div style={{ width: "100%", padding: "8px 16px",
								borderRadius: "6px", border: "1px solid #dddddd", backgroundColor: "#cccccc",
								textAlign: "center"
							}} >{unrealizedFeeTotal}</div>
						</div>
						<div className={"mb-4"}>
							<h6 className={"mb-2"}>To Wallet</h6>
							<CDropdown id={"realize-fee-modal-to-wallet-dropdown"} data={walletDropDownList} selected={selectedToWallet} onSelected={(selected:IDropdown)=>{
								if(selected!==selectedFromWallet)setSelectedToWallet(selected);

							}}/>
						</div>
					</Container>
				</Modal.Body>
				<Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
					<Button
						style={{ width: "360px"}}
						variant="dark"
						size={"lg"}
						onClick={() => {
							// if(props.onConfirm) props.onConfirm();
							if(!selectedToken||!selectedFromWallet||!selectedToWallet||!selectedMainnetValue){alert(MESSAGE_FILL_THE_BLANK); return};
							props.setShow(false);
							setShowTfa(true);
						}}>
						{/* {props.onConfirm ?? "Confirm"} */}
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};