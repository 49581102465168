import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSearchTextField from "../../components/CSearchTextField";

export interface IAdminSettingsPageFilter {
	role: IDropdown
	setRole: Function
	onSearch: Function
}

const roleData = [
	{
		name: "All",
		value: undefined,
	},
	{
		name: "1",
		value: "1",
	},
	{
		name: "2",
		value: "2",
	},
	{
		name: "3",
		value: "3",
	},
]

export default function AdminSettingsPageFilter(props: IAdminSettingsPageFilter) {
	return <Row className="mb-3 align-items-end">
			<Col xs={2}>
				<CDropdown id={"admin-settings-dropdown-role"} hint={"Role"} data={roleData} selected={props.role} onSelected={props.setRole}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"transaction-approve-search"} placeholder={"Name / ID"} onSearch={props.onSearch}/>
			</Col>
		</Row>;
}