import {Button, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import WalletManagementEditPageBasicInfo from "./WalletManagementEditPageBasicInfo";
import CBreadCrumb from "../../../components/CBreadCrumb";
import WalletManagementEditPageAlert from "./WalletManagementEditPageAlert";
import WalletManagementEditPageAddToken from "./WalletManagementEditPageAddToken";
import CLoadingDimmer from "../../../components/CLoadingDimmer";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Dto_GetTokensByWalletResult, Dto_GetWalletsManagementResult} from "../../../../api/wallet/wallet_dto";
import {IDropdown_Dto_GetTokensByNetworkResult, IDropdown_Dto_GetTokensByWalletResult, IDropdown_Dto_GetWalletAdminResult} from "../../../../model/custom_dropdown";
import {IDropdown} from "../../../components/CDropdown";
import {Dto_GetTokensByNetworkResult} from "../../../../api/network/network_dto";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";

export default function WalletManagementEditPage() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const item: Dto_GetWalletsManagementResult = location.state.item;
	const accessUpdateWalletBasic = location.state.accessUpdateWalletBasic;
	const accessUpdateWalletAlert = location.state.accessUpdateWalletAlert;
	const accessUpdateWalletToken = location.state.accessUpdateWalletToken;
	const accessDisableWallet = location.state.accessDisableWallet;

	const [showWalletStatusModal, setShowWalletStatusModal] = useState(false);
	const [walletStatus, setWalletStatus] = useState(false);

	// basic info
	const [walletAddress, setWalletAddress] = useState('');
	const [walletName, setWalletName] = useState('');
	const [selectedMainTokenDropdown, setSelectedMainTokenDropdown] =
		useState<IDropdown_Dto_GetTokensByWalletResult>();
	const [mainTokenDropdownList, setMainTokenDropdownList] =
		useState<IDropdown_Dto_GetTokensByWalletResult[]>([])

	// alert
	const [minimumBalanceAlert, setMinimumBalanceAlert] = useState('');
	const [minimumFeeBalanceAlert, setMinimumFeeBalanceAlert] = useState('');
	const [alertGenerated, setAlertGenerated] = useState<IDropdown>({
		name: "Yes",
		value: "true",
	});
	const [alertAdminList, setAlertAdminList] = useState<IDropdown_Dto_GetWalletAdminResult[]>([]);
	const [selectedAlertAdminList, setSelectedAlertAdminList] = useState<IDropdown_Dto_GetWalletAdminResult[]>([]);

	// add token
	const [addTokenList, setAddTokenList] = useState<Dto_GetTokensByWalletResult[]>([]);
	const [networkTokenList, setNetworkTokenList] = useState<IDropdown_Dto_GetTokensByNetworkResult[]>([]);
	const [selectedToken, setSelectedToken] = useState<IDropdown_Dto_GetTokensByNetworkResult>();

	useEffect(() => {
		getWalletUsage();
	}, [])

	async function getWalletUsage() {
		WalletApi.getWalletStatus(item.walletId).then((res) => {
			if(res.success) {
				setWalletStatus(res.data.status === "ENABLED");
			}
		});
	}

	async function updateWalletStatus(status: boolean, otpCode: string) {
		WalletApi.setWalletStatus(item.walletId, {
			otpCode,
			status: status ? "ENABLED" : "DISABLED",
		}).then((res) => {
			if(res.success) {
				alert(MESSAGE_CHANGES_SAVED)
				navigate(-1);
			}
			else {
				alert(MESSAGE_CHANGES_FAILED);
			}
		});
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal show={showWalletStatusModal} setShow={setShowWalletStatusModal} onOk={(otpCode: string) => {
			updateWalletStatus(!walletStatus, otpCode);
		}}/>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Edit"} secondUrl={"/wallet/management/edit"}
		/>
		<CPageTitle title={item.walletName} subtitle={"Edit"}/>
		<Row className={"d-flex justify-content-center mb-2"}>
			<Row style={{ width: "768px" }} >
				<div className={"d-flex flex-row justify-content-end"}>
					{
						walletStatus ?
						<Button className={"mb-4"}
					        size={"lg"}
					        variant={"danger"}
					        disabled={!accessDisableWallet}
					        onClick={() => setShowWalletStatusModal(true)}
						>Wallet Disable</Button> :
						<></>
					}
				</div>
			</Row>
		</Row>
		<WalletManagementEditPageBasicInfo
			item={item}
			loading={loading} setLoading={setLoading}
			walletAddress={walletAddress} setWalletAddress={setWalletAddress}
			walletName={walletName} setWalletName={setWalletName}
			selectedMainTokenDropdown={selectedMainTokenDropdown} setSelectedMainTokenDropdown={setSelectedMainTokenDropdown}
			mainTokenDropdownList={mainTokenDropdownList} setMainTokenDropdownList={setMainTokenDropdownList}
			disabled={!accessUpdateWalletBasic || (!walletName || !selectedMainTokenDropdown)}/>
		{!item.externalYn ? <WalletManagementEditPageAlert
			item={item}
           loading={loading} setLoading={setLoading}
           minimumBalanceAlert={minimumBalanceAlert} setMinimumBalanceAlert={setMinimumBalanceAlert}
			minimumFeeBalanceAlert={minimumFeeBalanceAlert} setMinimumFeeBalanceAlert={setMinimumFeeBalanceAlert}
			alertGenerated={alertGenerated} setAlertGenerated={setAlertGenerated}
			alertAdminList={alertAdminList} setAlertAdminList={setAlertAdminList}
			selectedAlertAdminList={selectedAlertAdminList} setSelectedAlertAdminList={setSelectedAlertAdminList}
            disabled={!accessUpdateWalletAlert || (!minimumBalanceAlert || !minimumFeeBalanceAlert || selectedAlertAdminList.length === 0)}
		/> : <></>}
		<WalletManagementEditPageAddToken item={item} disabled={!accessUpdateWalletToken}
		                                  loading={loading} setLoading={setLoading}
		                                  networkTokenList={networkTokenList} setNetworkTokenList={setNetworkTokenList}
		                                  addTokenList={addTokenList} setAddTokenList={setAddTokenList}
		                                  selectedToken={selectedToken} setSelectedToken={setSelectedToken}
		/>
	</Container>
}