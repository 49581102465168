interface IPageTitleComponent {
	title: string,
	subtitle: string,
}

export default function CPageTitle(props: IPageTitleComponent) {
	return <div>
		<div className="h1 pt-4">{props.title}</div>
		<div className="fs-5 pt-2 pb-5 text-secondary">{props.subtitle}</div>
	</div>
}