import 'styles/sidebar.css';
import 'styles/layout.css';
import {Container, Row} from "react-bootstrap";
import DashboardPageListItem from "./DashboardPageListItem";
import CGridListPage from "../components/CGridListPage";
import {Dto_GetWalletBalancesResult} from "../../api/dashboard/dashboard_dto";

export interface IDashboardPageOnlineWalletList {
    loading: boolean
    setLoading: Function
    onlineWalletList: Dto_GetWalletBalancesResult[]
    setOnlineWalletList: Function
}

function DashboardPageHotWalletList(props: IDashboardPageOnlineWalletList) {
    const generateItem = (value: Dto_GetWalletBalancesResult) => {
        return <DashboardPageListItem key={JSON.stringify(value)} {...value} />
    }

  return <Container>
      <Row className={"p-3 mb-3 bg-white"}>Current Balance (Hot Wallet)</Row>
      {props.loading ? <div className={"d-flex flex-row justify-content-center pt-5 pb-5"}>
          <div className="spinner" />
      </div> : props.onlineWalletList.length === 0 ? 'No Data' : ""}
      <CGridListPage list={props.onlineWalletList} itemGenerator={generateItem}/>
    </Container>;
}

export default DashboardPageHotWalletList;
