import {Button, Col, Row} from "react-bootstrap";
import {getGasUnit, shrinkAddress} from "../../../../utils/util";
import TransactionPendingDetailModal from "../modals/TransactionPendingDetailModal";
import {useState} from "react";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";
import {IDropdown} from "../../../components/CDropdown";
import CLinkExplorer from "../../../components/CLinkExplorer";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";

export interface ITransactionPendingPageOtherListItem {
	createDate : string,
	eventName : string
	event: string
	txId : string,
	fromAddress : string,
	fromWalletName : string,
	toAddress : string,
	toWalletName : string,
	maxFee : number
	amount : number,
	tokenName : string,
	gasFee : number,
	networkName : string,
	networkId : number,
	// blockStatus : string,
	pairId : number,
}

export default function TransactionPendingPageOtherListItem(props: ITransactionPendingPageOtherListItem) {
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [loading,setLoading] = useState(false);

	async function setResendCombined(txId: string, event: string){
		if(loading) return;
		setLoading(true);
		WalletConnectApi.setResendCombined(txId,
			{
				event: event,
			}
		).then((result) => {
			if(result.success){
				alert(MESSAGE_CHANGES_SAVED);
				window.location.reload();
			} else{
				alert(result.msg);
			}
		}).catch((error) => {
			alert(MESSAGE_CHANGES_FAILED);
		}).finally(() => {
			setLoading(false);
		})
	}

	return (
		<>
			<CLoadingDimmer loading={loading} setLoading={setLoading} />
			<TransactionPendingDetailModal show={showDetailModal} setShow={setShowDetailModal}
			                               pairId={props.pairId}
			                               date = {props.createDate}
			                               amount = {props.amount}
			                               fromAddress={props.fromAddress}
			                               toAddress={props.toAddress}
			                               mainnetName={props.networkName}
			                               mainnetId={props.networkId}
			                               txPairId={props.txId}
			                               maxFee={props.maxFee}
			                               gasFee={props.gasFee}
			                               setResendCombined={setResendCombined}/>
			<Row className="p-3 bg-white mb-2 moveItem">

				<Col>{props.createDate?<CLocaleTimestamp timestamp={props.createDate}/>:"-"}</Col>
				<Col>{props.eventName}</Col>
				<Col style={{color:"#F4B12E",cursor:"pointer"} } onClick={()=>{
					setShowDetailModal(true);
				}}>{shrinkAddress(props.txId?props.txId:"-")}</Col>
				<Col><CLinkExplorer target={props.tokenName} type={"address"} address={props.fromAddress} text={shrinkAddress(props.fromAddress, 5)} copyText={props.fromAddress}/></Col>
				<Col><CLinkExplorer target={props.tokenName} type={"address"} address={props.toAddress} text={shrinkAddress(props.toAddress, 5)} copyText={props.toAddress}/></Col>
				<Col>{`${props.amount ?? "-"} ${props.tokenName && props.amount ? props.tokenName: ""}`}</Col>
				<Col>{`${props.gasFee ?? "-"} ${props.tokenName && props.gasFee ? getGasUnit(props.tokenName): ""}`}</Col>
				<Col><Button className="btn-dark" onClick={() => {
					setResendCombined(props.txId, props.event);
				}}>Resend Tx</Button></Col>
			</Row>
		</>
	)
}