import {Col, Container, Row} from "react-bootstrap";
import {useEffect} from "react";
import {Dto_GetMainnetResult} from "../../../../api/network/network_dto";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult} from "../../../../model/custom_dropdown";
import CDropdown from "../../../components/CDropdown";

export interface IWalletManagementSetGasFeePageMainnet {
	mainnetList: IDropdown_Dto_GetMainnetResult[], setMainnetList: Function
	selectedMainnet?: IDropdown_Dto_GetMainnetResult, setSelectedMainnet: Function
	onReset: Function
}

export default function WalletManagementSetGasFeePageMainnet(
	props: IWalletManagementSetGasFeePageMainnet
) {

	useEffect(() => {
		init();
	}, [])

	async function init() {
		const res = await fetchMainnetList();
		const newDropdownList: IDropdown_Dto_GetMainnetResult[] = [];
		if(res.length > 0) {
			for(let i=0; i<res.length; i++) {
				const item = res[i];
				newDropdownList.push({
					name: item.name,
					value: item.id.toString(),
					data: item
				})
			}
			props.setMainnetList(newDropdownList);
		}
	}

	async function fetchMainnetList(): Promise<Dto_GetMainnetResult[]> {
		const result = await NetworkApi.getMainnet({pageNumber: 1, pageSize: 20,});
		if(result && result.data.list) {
			return result.data.list;
		} else {
			return [];
		}
	}

	return <Container>
		<Row className={"d-flex justify-content-center mb-5"}>
			<Row style={{ width: "768px" }} className={"p-5 bg-white rounded"}>
				<Row className={"h5 mb-5"}>Mainnet Info</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Wallet Mainnet</Col>
					<Col xs={8}>
						<CDropdown id={'wallet-management-create-step1-wallet-mainnet'} data={props.mainnetList}
						           selected={props.selectedMainnet}
						           onSelected={(item: IDropdown_Dto_GetMainnetResult) => {
							           if(item && item.data) {
								           props.setSelectedMainnet(item);
										   props.onReset();
							           }
						           }}/></Col>
				</Row>
			</Row>
		</Row>
	</Container>
}