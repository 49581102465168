import { enc, AES } from 'crypto-js';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const ENCRYPTION_KEY = 'my-secret-key';

export class TokenManager {
	private static instance: TokenManager
	private constructor() {}

	private static setCookie(name: string, value: string, days: number) {
		let expires = "";
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}

	private static getCookie(name: string) {
		const nameEQ = name + "=";
		const ca = document.cookie.split(';');
		for(let i=0;i < ca.length;i++) {
			let c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	// 암호화 함수
	private static encrypt(plaintext: string): string {
		return AES.encrypt(plaintext, ENCRYPTION_KEY).toString();
	}

	// 복호화 함수
	private static decrypt(ciphertext: string): string {
		return AES.decrypt(ciphertext, ENCRYPTION_KEY).toString(enc.Utf8);
	}

	// access token을 암호화해서 localStorage에 저장
	public static setAccessToken(token: string): void {
		const encryptedToken = this.encrypt(token);
		localStorage.setItem(ACCESS_TOKEN_KEY, encryptedToken);
	}

	// access token을 가져올 때는 복호화해서 반환
	public static getAccessToken(): string | null {
		const encryptedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
		if (encryptedToken) {
			return this.decrypt(encryptedToken);
		}
		return null;
	}

	// refresh token을 암호화해서 cookie에 저장
	public static setRefreshToken(token: string): void {
		const encryptedToken = this.encrypt(token);
		this.setCookie(REFRESH_TOKEN_KEY, encryptedToken, 30); // 30일간 유지
	}

	// refresh token을 가져올 때는 복호화해서 반환
	public static getRefreshToken(): string | null {
		const encryptedToken = this.getCookie(REFRESH_TOKEN_KEY);
		if (encryptedToken) {
			return this.decrypt(encryptedToken);
		}
		return null;
	}
}