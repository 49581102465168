import {BaseHttp} from "../BaseHttp";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";
import {Dto_GetGPDStatusResult, Dto_GetGPDWalletsResult, Dto_GetGPDHistory, Dto_GetGPDHistoryResult, Dto_CreateMinting, Dto_CreateBurning,} from "./smart_contract_dto";
import {ApiUrl} from "../../constants/ApiUrl";

export class SmartContractApi {
	private static instance: SmartContractApi
	private constructor() {}

	public static async getGPDHistory(params: Dto_GetGPDHistory ):
		Promise<{code: string, msg: string, data: { list: Dto_GetGPDHistoryResult[], pagingInfo: PagingInfoResponse }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_GPD_HISTORIES, params);
	}

	public static async getGPDWallets():
		Promise<{code: string, msg: string, data: { list: Dto_GetGPDWalletsResult[], pagingInfo: PagingInfoResponse }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_GPD_WALLETS);
	}

	public static async getGPDStatus():
		Promise<{code: string, msg: string, data: Dto_GetGPDStatusResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_GPD_STATUS);
	}

	public static async createMinting(data: Dto_CreateMinting):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_PRIVATE_GPD_MINTING, data);
	}

	public static async createBurning(data: Dto_CreateBurning):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_PRIVATE_GPD_BURNING, data);
	}
}