import CDropdown, {IDropdown} from "./CDropdown";

export interface ITitleDropdownComponent {
	id: string
	title: string
	selected?: IDropdown
	data: IDropdown[]
	onSelected?: Function
}

export default function CTitleDropdown(props: ITitleDropdownComponent) {
	return <div>
		<div className="pb-2">{props.title}</div>
		<div>
			<CDropdown id={props.id} data={props.data} selected={props.selected}
	           onSelected={(item: IDropdown) => {
				   if(props.onSelected) props.onSelected(item)}
			}/>
		</div>
	</div>
}