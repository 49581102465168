import {Button, Modal, Form} from "react-bootstrap";
import CTextField from "../CTextField";
import {useState} from "react";
import {AuthApi} from "../../../api/auth/AuthApi";
import {UserSecurityApi} from "../../../api/user_security/UserSecurityApi";
import {TokenManager} from "../../../states/token";
import {AuthTimer} from "../../../states/AuthTimer";

export interface IRegisterTFAModalComponent {
    show: boolean
    setShow: any
    onCancel?: any
    onOk: any
    otpImageUrl: string
    otpKey: string
    email: string
    password: string
}

export default function CRegisterTFAModal(props: IRegisterTFAModalComponent) {
    const [loading, setLoading] = useState(false);
    const [otpCode, setOtpCode] = useState('');

    // 로그인 OTP 등록
    async function registerOTP() {
        UserSecurityApi.setOtpSettingInfo({
            email: props.email,
            password: props.password,
            otpKey: props.otpKey,
            otpCode: otpCode,
        }).then((registerOTPResult) => {
            if(registerOTPResult.success) {
                alert("Login OTP has been registered.")
                login();
            }
            else {
                alert(registerOTPResult.msg);
                setLoading(false)
                return;
            }
        })
    }

    // 2차 로그인
    async function login() {
        AuthApi.newSessionStep2({
            authType: "add_auth",
            grantType: "password",
            email: props.email,
            password: props.password,
            otpCode: otpCode,
        }).then((res) => {
            if (res.success) {
                setLoading(false);
                TokenManager.setAccessToken(res.data.accessToken);
                TokenManager.setRefreshToken(res.data.refreshToken);
                AuthTimer.getInstance().restart();
                props.onOk(true);
                props.setShow(false);
            }
            else {
                alert(res.msg)
                setLoading(false);
            }
        }).catch((err) => {
            alert(err)
            setLoading(false);
        })
    }

    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Two-Factor Authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ border: "none", marginBottom: "36px", textAlign: "center" }}>
                        To be able to authorize transactions you need to scan this QR Code and enter the verification code below
                    </Modal.Body>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
                    }}>
                        <img style={{ marginBottom: "32px" }} src={props.otpImageUrl} alt={"otpImage"} width={200} height={200} />
                        <div style={{ marginBottom: "32px" }}>{props.otpKey}</div>
                    </div>
                    <div className={"ps-3 pe-3 mb-4"}>
                        <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} />
                    </div>
                    <Modal.Footer style={{ border: "none" }}>
                        <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                if(loading) return;
                                setLoading(true);
                                registerOTP();
                            }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
        </Modal>
        </>
    );
};