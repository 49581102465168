import {Col, Row} from "react-bootstrap";

export default function FeeRecordPageUnrealizedFeeListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Date</Col>
		<Col xs={2}>Tx Pair Id</Col>
		<Col>Gas Coverage Fee</Col>
		<Col>Conversion Fee</Col>
		<Col>Status</Col>
	</Row>
}