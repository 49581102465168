import {Button, Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import {useEffect, useState} from "react";
import CBreadCrumb from "../../../components/CBreadCrumb";
import {useLocation, useNavigate} from "react-router-dom";

export default function WalletManagementAddPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const [accessCreateWallet, setAccessCreateWallet] = useState(false);
	const [selected, setSelected] = useState('');
	const onNextClicked = () => {
		if(selected === '') {
			return;
		}
		if(selected === 'cold') navigate('/wallet/management/add/cold')
		else if(selected === 'hot') navigate("/wallet/management/add/hot");
	}

	useEffect(() => {
		try {
			const accessCreateWallet = location.state.accessCreateWallet;
			setAccessCreateWallet(accessCreateWallet)
		} catch(e) {
			navigate(-1);
		}
	})
	return <Container>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Add New Wallet"} secondUrl={"/wallet/management/add"}
		/>
		<CPageTitle title={"Add New Wallet"} subtitle={"Select type"}/>
		<Row className={"bg-white p-5"}>
			<Row className={"h5 mb-5"}>Wallet Type</Row>
			<Row className={"mb-5"}>
				<Col
					style={{
						cursor: "pointer",
						backgroundColor: selected === 'cold' ? "#F4B12E" : "#cccccc",
						color: selected === 'cold' ? "white" : "#999999",
					}}
					onClick={() => setSelected('cold')}
					className={"pt-5 pb-5 d-flex justify-content-center rounded h5"}>Add Cold Wallet</Col>
				<Col xs={1}/>
				<Col
					style={{
						cursor: "pointer",
						backgroundColor: selected === 'hot' ? "#F4B12E" : "#cccccc",
						color: selected === 'hot' ? "white" : "#999999",
					}}
					onClick={() => setSelected('hot')}
					className={"pt-5 pb-5 d-flex justify-content-center rounded h5"}>Create Hot Wallet</Col>
			</Row>
			<Row xs={4} className={"justify-content-center"}>
				<Button size={"lg"} variant={"dark"} onClick={onNextClicked} disabled={!accessCreateWallet}>Next</Button>
			</Row>
		</Row>
	</Container>
}