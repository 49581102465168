import DashboardPage from "pages/dashboard/DashboardPage";
import LoginPage from "pages/login/LoginPage";
import MainLayout from "pages/MainLayout";
import {Route, Routes, useNavigate} from "react-router-dom";
import 'styles/theme.css'
import LogDataPage from "./pages/log/LogDataPage";
import WalletDistributePage from "./pages/wallet/wallet_distribute/WalletDistributePage";
import WalletManagementPage from "./pages/wallet/wallet_management/WalletManagementPage";
import ConnectSettingsPage from "./pages/wallet_connect/connect_settings/ConnectSettingsPage";
import TransactionApprovePage from "./pages/wallet_connect/transaction_approve/TransactionApprovePage";
import TransactionRecordPage from "./pages/wallet_connect/transaction_record/TransactionRecordPage";
import CommissionRecordPage from "./pages/wallet_connect/commission_record/CommissionRecordPage";
import TransactionPendingPage from "./pages/wallet_connect/transaction_pending/TransactionPendingPage";
import AdminSettingsPage from "./pages/settings/admin_settings/AdminSettingsPage";
import AccountSettingsPage from "./pages/settings/account_settings/AccountSettingsPage";
import GPDMintPage from "./pages/smart_contract/gpd_mint/GPDMintPage";
import GPDHistoryPage from "./pages/smart_contract/gpd_history/GPDHistoryPage";
import WalletManagementAddPage from "./pages/wallet/wallet_management/add/WalletManagementAddPage";
import WalletManagementEditPage from "./pages/wallet/wallet_management/edit/WalletManagementEditPage";
import WalletManagementCreateAddHotWalletPage from "./pages/wallet/wallet_management/add/WalletManagementCreateAddHotWalletPage";
import WalletManagementCreateStep2Page from "./pages/wallet/wallet_management/add/WalletManagementCreateStep2Page";
import WalletManagementAddExternalWalletPage from "./pages/wallet/wallet_management/add/WalletManagementCreateAddColdWalletPage";
import ConnectSettingsPageEditConnectPair from "./pages/wallet_connect/connect_settings/ConnectSettingsPageEditConnectPair";
import TransactionDetailPage from "./pages/wallet_connect/transaction_detail/TransactionDetailPage";
import {useEffect} from "react";
import {setNavigateFunction} from "./navigation";
import {AuthTimer} from "./states/AuthTimer";
import WalletDistributeHistoryPage from "./pages/wallet/wallet_distribute_history/WalletDistributeHistoryPage";
import WalletManagementSetGasFeePage from "./pages/wallet/wallet_management/gas_fee/WalletManagementSetGasFeePage";
import DashboardPageDataCenter from "./pages/dashboard/data_center/DashboardPageDataCenter";
import ConnectSettingsPageCreateConnectPair from "pages/wallet_connect/connect_settings/ConnectSettingsPageCreateConnectPair";
import {getCountry, getTimezonesForCountry} from "countries-and-timezones";
import {useRecoilState} from "recoil";
import {localeSettingState} from "./states/locale_atom";
import MetamaskTestPage from "./pages/metamask_test/MetamaskTestPage";

export default function App() {
    const navigate = useNavigate();
    const [locateState, setLocaleState] = useRecoilState(localeSettingState);

    useEffect(() => {
        setNavigateFunction(navigate);
    }, [navigate]);

    useEffect(() => {
        init();
    }, [])

    function init() {
        AuthTimer.getInstance().start();
        localeInitialize();
    }

    function localeInitialize() {
        const countryCode = getCountryCode();
        const country = getCountry(countryCode);
        const timezone = getTimezonesForCountry(countryCode);

        if (country && timezone) {
            setLocaleState({
                locale: country.name,
                offset: timezone[0].utcOffset,
            });
        }
    }

    const getCountryCode = (): string => {
        const locale = navigator.language || (navigator as any).userLanguage;
        return locale.split('-')[1];
    };

    return <>
        <Routes>
                {/* 헤더 포함 페이지 */}
                <Route element={<MainLayout/>}>
                    <Route path="/dashboard" element={<DashboardPage/>}></Route>
                    <Route path="/dashboard/datacenter" element={<DashboardPageDataCenter/>}></Route>

                    {/* Wallet*/}
                    <Route path="/wallet/distribute" element={<WalletDistributePage/>}></Route>
                    <Route path="/wallet/management" element={<WalletManagementPage/>}></Route>
                    <Route path="/wallet/management/add" element={<WalletManagementAddPage/>}></Route>
                    <Route path="/wallet/management/add/cold" element={<WalletManagementAddExternalWalletPage/>}></Route>
                    <Route path="/wallet/management/add/hot" element={<WalletManagementCreateAddHotWalletPage/>}></Route>
                    <Route path="/wallet/management/add/create-step2" element={<WalletManagementCreateStep2Page/>}></Route>
                    <Route path="/wallet/management/edit" element={<WalletManagementEditPage/>}></Route>
                    <Route path="/wallet/management/fee" element={<WalletManagementSetGasFeePage/>}></Route>
                    <Route path="/wallet/dist-history" element={<WalletDistributeHistoryPage/>}></Route>

                    {/* Wallet Connect /*/}
                    <Route path="/wallet-connect/settings" element={<ConnectSettingsPage/>}></Route>
                    <Route path="/wallet-connect/settings/create" element={<ConnectSettingsPageCreateConnectPair/>}></Route>
                    <Route path="/wallet-connect/settings/edit" element={<ConnectSettingsPageEditConnectPair/>}></Route>
                    <Route path="/wallet-connect/approve" element={<TransactionApprovePage/>}></Route>
                    <Route path="/wallet-connect/approve/detail" element={<TransactionDetailPage/>}></Route>
                    <Route path="/wallet-connect/record" element={<TransactionRecordPage/>}></Route>
                    <Route path="/wallet-connect/record/detail" element={<TransactionDetailPage/>}></Route>
                    <Route path="/wallet-connect/realize" element={<CommissionRecordPage/>}></Route>
                    <Route path="/wallet-connect/pending" element={<TransactionPendingPage/>}></Route>

                    {/* Settings*/}
                    <Route path="/settings/admin" element={<AdminSettingsPage/>}></Route>
                    <Route path="/settings/account" element={<AccountSettingsPage/>}></Route>

                    {/* Smart Contract*/}
                    <Route path="/contract/mint" element={<GPDMintPage/>}></Route>
                    <Route path="/contract/history" element={<GPDHistoryPage/>}></Route>

                    {/* Log*/}
                    <Route path="/log/activity" element={<LogDataPage/>}></Route>

                    <Route path="/test/metamask" element={<MetamaskTestPage/>}></Route>

                    {/* ETC */}
                    <Route path="/" element={<DashboardPage/>}></Route>
                    <Route path="*" element={<div>Not Found</div>}></Route>
                </Route>
                <Route path="/login" element={<LoginPage/>}></Route>
                <Route path="*" element={<div>Not Found</div>}></Route>
            </Routes>
    </>
}