import {Button, Modal, Form} from "react-bootstrap";
import CTextField from "../CTextField";
import {useState} from "react";
import {AuthApi} from "../../../api/auth/AuthApi";

export interface IVerifyTFAModalComponent {
    show: boolean,
    setShow: any,
    onCancel?: any,
    onOk: Function,
    onClick?: Function
}

export default function CVerifyTFAModal(props: IVerifyTFAModalComponent) {
    const [otpCode, setOtpCode] = useState('');
    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>{
                if(props.onCancel) props.onCancel();
                props.setShow(false)
            }}
            backdrop="static"
            keyboard={false}
        >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Two-Factor Authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ border: "none", textAlign: "center" }}>
                        Enter the verification code displayed on your mobile device to proceed with your request.
                    </Modal.Body>
                    <div className={"ps-3 pe-3 mb-4"}>
                        <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} />
                    </div>
                    <Modal.Footer style={{ border: "none" }}>
                        <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.onOk(otpCode);
                                props.setShow(false);
                            }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};