import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import CSearchTextField from "../../../components/CSearchTextField";

export interface IGPDHistoryPageBurnFilter {
	selectedListDisplay: IDropdown,
	setSelectedListDisplay: Function,
	onSearch: Function
}

export default function GPDHistoryPageBurnFilter(props: IGPDHistoryPageBurnFilter) {
	const dropdownData: IDropdown[] = [{
		name: "25",
		value: "25",
	},
	{
		name: "50",
		value: "50",
	},
	{
		name: "100",
		value: "100",
	}];

	return <Row className="mb-3 align-items-end">
			<Col xs={2}>
				<CDropdown id={"gpd-history-burn-dropdown-role"} hint={"List Display"} data={dropdownData}
				           selected={props.selectedListDisplay}
				           onSelected={props.setSelectedListDisplay}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"gpd-history-burn-search"} placeholder={"Burn ID"} onSearch={props.onSearch}/>
			</Col>
		</Row>;
}