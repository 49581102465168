import CPageTitle from "../../components/CPageTitle";
import {Alert, Button, Container, Overlay, Row} from "react-bootstrap";
import WalletDistributePageReceivingCard from "./WalletDistributePageReceivingCard";
import WalletDistributePageSendingCard from "./WalletDistributePageSendingCard";
import CBreadCrumb from "../../components/CBreadCrumb";
import 'styles/theme.css'
import {useEffect, useState} from "react";
import CCalendarModal from "../../components/modal/CCalendarModal";
import {WalletApi} from "../../../api/wallet/WalletApi";
import {NetworkApi} from "../../../api/network/NetworkApi";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import {Dto_GetBalanceByWalletResult, Dto_GetTokensByWalletResult, Dto_GetWalletsDistributeResult} from "../../../api/wallet/wallet_dto";
import {getFormattedNumber, getGasUnit, gweiToEth, transformFormattedNumberToNumber} from "../../../utils/util";
import {Dto_GetMainnetResult} from "../../../api/network/network_dto";
import {IDropdown_Dto_GetTokensByWalletResult, IDropdown_Dto_GetWalletsByTokenResult, IDropdown_Dto_GetWalletsDistributeResult} from "../../../model/custom_dropdown";
import { BigNumberish } from 'ethers'
import {WalletDistributeApi} from "../../../api/wallet_distribute/WalletDistributeApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK} from "../../../constants/value";
import CVerifyTFAModal from "../../components/modal/CVerifyTFAModal";
import {UserApi} from "../../../api/user/UserApi";
import {TokenApi} from "../../../api/token/TokenApi";

export default function WalletDistributePage() {
	const [showCalendar, setShowCalendar] = useState(false);
	const [showTfa, setShowTfa] = useState(false);
	const [loading, setLoading] = useState(false);

	const [offlineWalletList, setOfflineWalletList] = useState<Dto_GetWalletsDistributeResult[]>([]);
	const [onlineWalletList, setOnlineWalletList] = useState<Dto_GetWalletsDistributeResult[]>([]);
	const [mainnetList, setMainnetList] = useState<Dto_GetMainnetResult[]>([]);

	// wallet distribution sending wallet
	const [sendingWalletListDropdown, setSendingWalletListDropdown] = useState<IDropdown_Dto_GetWalletsDistributeResult[]>([])
	const [sendingWalletListDropdownSelected, setSendingWalletListDropdownSelected] = useState<IDropdown_Dto_GetWalletsDistributeResult|undefined>()
	const [sendingWalletSelectToken, setSendingWalletSelectToken] = useState<Dto_GetTokensByWalletResult[]>([]);
	const [sendingWalletSelectTokenDropdown, setSendingWalletSelectTokenDropdown] = useState<IDropdown_Dto_GetTokensByWalletResult[]>([]);
	const [sendingWalletSelectTokenDropdownSelected, setSendingWalletSelectTokenDropdownSelected] = useState<IDropdown_Dto_GetTokensByWalletResult|undefined>();
	const [sendingWalletAddress, setSendingWalletAddress] = useState('');
	const [sendingWalletBalance, setSendingWalletBalance] = useState('');
	const [sendingWalletTransferAmount, setSendingWalletTransferAmount] = useState('');
	const [sendingWalletTransactionFee, setSendingWalletTransactionFee] = useState('');
	const [sendingWalletTransactionFeeBalance, setSendingWalletTransactionFeeBalance] = useState('');

	// wallet distribution receiving wallet
	const [receivingWalletListDropdown, setReceivingWalletListDropdown] = useState<IDropdown_Dto_GetWalletsByTokenResult[]>([])
	const [receivingWalletListDropdownSelected, setReceivingWalletListDropdownSelected] = useState<IDropdown_Dto_GetWalletsByTokenResult|undefined>()
	const [receivingWalletMainnet, setReceivingWalletMainnet] = useState('');
	const [receivingWalletAddress, setReceivingWalletAddress] = useState('');
	const [receivingWalletBalance, setReceivingWalletBalance] = useState('');
	const [receivingWalletBalanceAfterTransfer, setReceivingWalletBalanceAfterTransfer] = useState('');

	const [accessDistributeWallet, setAccessDistributeWallet] = useState(false);

	useEffect(() => {
		if(loading) return;
		setLoading(true);
		const promises = [
			fetchWalletDistributeList(true),
			fetchWalletDistributeList(false),
			fetchMainnetList(),
			getAccess(),
		];
		Promise.all(promises).then((res: any) => {
			setOfflineWalletList(res[0]);
			setOnlineWalletList(res[1]);
			setMainnetList(res[2])
			const newDropdownList: IDropdown_Dto_GetWalletsDistributeResult[] = [];

			// put only hot wallet
			for(let i=0; i<res[1].length; i++) {
				const item: Dto_GetWalletsDistributeResult = res[1][i];
				newDropdownList.push({
					name: `${item.walletName} (${item.address})`,
					value: item.walletId.toString(),
					data: item
				});
			}
			setSendingWalletListDropdown(newDropdownList);
			setLoading(false);
		});
	}, [])

	async function fetchWalletDistributeList(external: boolean): Promise<Dto_GetWalletsDistributeResult[]> {
		const result = await WalletApi.getWallets_distribute({
			externalYn: external,
		});
		if(result && result.data.list) {
			return result.data.list;
		} else {
			return [];
		}
	}

	async function fetchMainnetList(): Promise<Dto_GetMainnetResult[]> {
		const result = await NetworkApi.getMainnet({pageNumber: 1, pageSize: 20,});
		if(result && result.data.list) {
			return result.data.list;
		} else {
			return [];
		}
	}

	async function fetchTokensByWallet(walletId: number): Promise<Dto_GetTokensByWalletResult[]> {
		if(loading) return [];
		setLoading(true)
		const res = await WalletApi.getTokensByWallet(walletId);
		setLoading(false)
		if(res && res.success) return res.data.list
		else return []
	}

	async function getAccess() {
		const access = await UserApi.getUserAccess("DISTRIBUTE_WALLET");
		setAccessDistributeWallet(access);
	}

	const onClick = async () => {
		setShowTfa(true);
	}

	async function onTransfer(otpCode: string) {
		if(loading) return;
		if(!sendingWalletListDropdownSelected || !sendingWalletListDropdownSelected?.data.walletId ||
			!sendingWalletSelectTokenDropdownSelected || !sendingWalletSelectTokenDropdownSelected?.data.tokenId ||
			!receivingWalletListDropdownSelected || !receivingWalletListDropdownSelected?.data.walletId) {
			alert(MESSAGE_FILL_THE_BLANK);
			return;
		}
		setLoading(true);

		const fromWalletId = sendingWalletListDropdownSelected?.data.walletId
		const toWalletId = receivingWalletListDropdownSelected?.data.walletId
		const tokenId = sendingWalletSelectTokenDropdownSelected?.data.tokenId
		WalletDistributeApi.sendWalletDistribute({
			fromWalletId: fromWalletId,
			toWalletId: toWalletId,
			tokenId: tokenId,
			amount: parseFloat(sendingWalletTransferAmount),
			otpCode: otpCode,
		}).then((res) => {
			if(res.success) {
				window.location.reload();
				alert(MESSAGE_CHANGES_SAVED)
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}).catch((error) => {
			alert(error)
		}).finally(() => {
			setLoading(false);
		});
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={onTransfer}/>
		<CCalendarModal show={showCalendar} setShow={setShowCalendar} onOk={(date: any) => {
			alert(date)
		}}/>
		<CBreadCrumb first={"Wallet Distribute"} firstUrl={"/wallet/distribute"}/>
		<CPageTitle title={"Wallet Distribute"} subtitle={"You can distribute the assets you have in your wallet."}/>
		<Row>
			<WalletDistributePageSendingCard
				walletOffline={offlineWalletList}
				walletOnline={onlineWalletList}
				walletDropdown={sendingWalletListDropdown}
				walletDropdownSelected={sendingWalletListDropdownSelected}
				setWalletDropdownSelected={setSendingWalletListDropdownSelected}
				walletTokens={sendingWalletSelectToken} walletTokensDropdown={sendingWalletSelectTokenDropdown}
				walletTokenDropdownSelected={sendingWalletSelectTokenDropdownSelected} setWalletTokenDropdownSelected={setSendingWalletSelectTokenDropdownSelected}
				walletAddress={sendingWalletAddress} setWalletAddress={setSendingWalletAddress}
				walletBalance={sendingWalletBalance} setWalletBalance={setSendingWalletBalance}
				transferAmount={sendingWalletTransferAmount} setTransferAmount={setSendingWalletTransferAmount}
				transactionFee={sendingWalletTransactionFee} setTransactionFee={setSendingWalletTransactionFee}
				transactionFeeBalance={sendingWalletTransactionFeeBalance}
				onTransactionAmountChanged={(text: string) => {
					const amount = parseFloat(text);
					if(amount < 0) {
						setSendingWalletTransferAmount('');
						return;
					}
					if(amount > transformFormattedNumberToNumber(sendingWalletBalance.split(' ')[0])) {
						setSendingWalletTransferAmount('');
						return;
					}
					// sendingWalletTransactionFee
					const receivingBalance = receivingWalletBalance.split(' ')[0];
					const rawReceivingWalletBalance = transformFormattedNumberToNumber(receivingBalance);
					const newBalance = rawReceivingWalletBalance + amount;
					if(!newBalance) setReceivingWalletBalanceAfterTransfer(`-`);
					else setReceivingWalletBalanceAfterTransfer(`${getFormattedNumber(newBalance, 3)} ${sendingWalletSelectTokenDropdownSelected?.data.tokenName}`);
				}}
				onWalletSelected={(selected: IDropdown_Dto_GetWalletsDistributeResult) => {
					if(loading) return;
					setLoading(true);
					let selectedWalletAddress = selected.data.address;
					let selectedNetworkId = selected.data.networkId;
					let selectedMainnet = '';

					// set mainnet in the receiving wallet
					for (let i = 0; i < mainnetList.length; i++) {
						if (mainnetList[i].id === selectedNetworkId) {
							selectedMainnet = mainnetList[i].name;
						}
					}

					setReceivingWalletListDropdown([])
					setSendingWalletListDropdownSelected(selected)
					setSendingWalletAddress(selectedWalletAddress)
					setSendingWalletSelectTokenDropdownSelected(undefined)
					setSendingWalletBalance('')
					setSendingWalletTransferAmount('')
					setReceivingWalletListDropdownSelected(undefined)
					setReceivingWalletMainnet(selectedMainnet)
					setReceivingWalletAddress('')
					setReceivingWalletBalance('')
					setReceivingWalletBalanceAfterTransfer('')
					setSendingWalletTransactionFee(`${selected.data.maxGasFee} ${getGasUnit(selectedMainnet)}`)

					// load token list
					const promises = [
						WalletApi.getTokensByWallet(selected.data.walletId),
						WalletApi.getBalanceByWallet(selected.data.walletId),
					]
					setLoading(true);
					Promise.all(promises).then((res: any) => {
						const res0: {code: string, msg: string, data: { list: Dto_GetTokensByWalletResult[] }, success: boolean} = res[0];
						const res1: {code: string, msg: string, data: Dto_GetBalanceByWalletResult, success: boolean} = res[1];
						setSendingWalletSelectToken(res0.data.list);
						const newDropdownList: IDropdown_Dto_GetTokensByWalletResult[] = [];
						for(let i=0; i<res0.data.list.length; i++) {
							newDropdownList.push({
								name: res0.data.list[i].tokenName,
								value: res0.data.list[i].tokenId.toString(),
								data: res0.data.list[i]
							})
						}
						setSendingWalletSelectTokenDropdown(newDropdownList);
						setSendingWalletTransactionFeeBalance(`${res1.data.walletBalance} ${getGasUnit(selectedMainnet)}`);
					}).catch((error) => {

					}).finally(() => {
						setLoading(false);
					});
				}}
				onTokenSelected={(selected: IDropdown_Dto_GetTokensByWalletResult) => {
					setSendingWalletSelectTokenDropdownSelected(selected)
					setSendingWalletTransferAmount('');
					setReceivingWalletBalance('')
					setReceivingWalletBalanceAfterTransfer('')
					if(sendingWalletListDropdownSelected) {
						WalletApi.getBalanceByWalletAndToken(sendingWalletListDropdownSelected?.data.walletId, selected.data.tokenId).then((res) => {
							if(res.success) {
								setSendingWalletBalance(`${res.data.tokenBalance} ${selected.name}`);
							}
						})
						TokenApi.getWalletsByToken(selected.data.tokenId, { excludeWalletConnect: true }).then((res) => {
							if(res.success) {
								const list = res.data.list;
								const newReceivingWalletList: IDropdown_Dto_GetWalletsByTokenResult[] = [];
								for(let i=0; i<list.length; i++) {
									if(list[i].networkId === sendingWalletListDropdownSelected.data.networkId &&
										list[i].walletId !== sendingWalletListDropdownSelected.data.walletId) {
										newReceivingWalletList.push({
											name: `${list[i].walletName} (${list[i].address})`,
											value: list[i].walletId.toString(),
											data: list[i]
										});
									}
								}
								setReceivingWalletListDropdown(newReceivingWalletList);
							}
						})
					}
					if(receivingWalletListDropdownSelected) {
						WalletApi.getBalanceByWalletAndToken(receivingWalletListDropdownSelected?.data.walletId, selected.data.tokenId).then((res) => {
							if(res.success) {
								setReceivingWalletBalance(`${res.data.tokenBalance} ${selected.name}`);
							}
						})
					}
				}}
			/>
			<div style={{ width: "16px"}}/>
			<WalletDistributePageReceivingCard
				walletOffline={offlineWalletList}
				walletOnline={onlineWalletList}
				walletDropdown={receivingWalletListDropdown}
				walletDropdownSelected={receivingWalletListDropdownSelected}
				setWalletDropdownSelected={setReceivingWalletListDropdownSelected}
				mainnet={receivingWalletMainnet} setMainnet={setReceivingWalletMainnet}
				walletAddress={receivingWalletAddress} setWalletAddress={setReceivingWalletAddress}
				walletBalance={receivingWalletBalance} setWalletBalance={setReceivingWalletBalance}
				balanceAfterTransfer={receivingWalletBalanceAfterTransfer} setBalanceAfterTransfer={setReceivingWalletBalanceAfterTransfer}
				onWalletSelected={(selected: IDropdown_Dto_GetWalletsByTokenResult) => {
					if(loading) return;
					setReceivingWalletListDropdownSelected(selected)
					setReceivingWalletAddress(selected.data.address)
					setLoading(true)
					if(!sendingWalletSelectTokenDropdownSelected) return;
					WalletApi.getBalanceByWalletAndToken(selected.data.walletId, sendingWalletSelectTokenDropdownSelected?.data.tokenId).then((res) => {
						if(res.success) {
							setReceivingWalletBalance(`${getFormattedNumber(res.data.tokenBalance, 3)} ${sendingWalletSelectTokenDropdownSelected?.data.tokenName}`);
							if(sendingWalletBalance && sendingWalletTransferAmount)
								setReceivingWalletBalanceAfterTransfer(`${getFormattedNumber(res.data.tokenBalance + parseFloat(sendingWalletTransferAmount))} ${sendingWalletSelectTokenDropdownSelected?.data.tokenName}`);
						} else {
							setReceivingWalletBalance(`${sendingWalletSelectTokenDropdownSelected?.data.tokenName} is not registered in this wallet`);
						}
					}).catch((error) => {

					}).finally(() => {
						setLoading(false)
					});
				}}
			/>
			<Button className="mt-5 mb-4" size="lg" variant="dark" onClick={onClick} disabled={!accessDistributeWallet}>Transfer</Button>
		</Row>
	</Container>
}