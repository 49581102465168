import {Form, InputGroup} from "react-bootstrap"

export enum ITextFieldType {
	text, email, number, password
}

export interface ITextFormFieldComponent {
	id: string
	text: string
	setText: any
	width?: number
	title?: string
	placeholder?: string
	hint?: string
	type?: ITextFieldType
	disabled?: boolean
	onChange?: Function
	unit?: string
}

export default function CTextField(props: ITextFormFieldComponent) {
	return <div style={{ width: props.width ? `${props.width}px`: ""}}>
		{props.title ? <Form.Label>{props.title}</Form.Label> : <></>}
		<InputGroup>
			<Form.Control
				id={props.id + "control"}
				value={props.text}
				onChange={e => {
					props.setText(e.target.value)
					if(props.onChange) props.onChange(e.target.value);
				}}
				type={props.type ? ITextFieldType[props.type] : "text"}
				className={"pt-2 pb-2"}
				disabled={props.disabled ?? false }
				placeholder={props.placeholder ?? "Enter a value"}
				autoFocus={true}
			/>
			{props.unit ? <InputGroup.Text>{props.unit}</InputGroup.Text>: <></>}
		</InputGroup>
				{props.hint ?
					<Form.Text
						id={props.id + "hint"}
						className="text-danger">{props.hint}</Form.Text> : <></>}
	</div>
}