import {Button, Col, Container, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import CTitleDropdown from "../../../components/CTitleDropdown";
import { useState } from 'react';
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import { useEffect } from 'react';
import { Dto_getPendingTotalResult } from "api/wallet_connect/wallet_connect_dto";
import { TokenApi } from "api/token/TokenApi";
import { IDropdown_Dto_GetTokenResult } from "model/custom_dropdown";


export interface ITransactionPendingPagePendingSummary {
	loading: boolean
	setLoading: Function
}


export default function TransactionPendingPagePendingSummary(props: ITransactionPendingPagePendingSummary) {
	
	const [data,setData] = useState<Dto_getPendingTotalResult>()

	const [selectedTransactionType,setSelectedTransactionType] = useState<IDropdown>({
		name : "All",
		value : "ALL",
	})

	const [selectedToken,setSelectedToken] = useState<IDropdown>();


	useEffect(()=>{
		fetchPendingTotal();
		fetchGetTokenList();	
	},[])


	useEffect(()=>{
		fetchPendingTotal(selectedTransactionType.value, selectedToken?.value);
	},[selectedTransactionType.value,selectedToken?.value])



	async function fetchPendingTotal(event?:string,tokenId?:string){
		if(props.loading) return;
		props.setLoading(true);

		const result = await WalletConnectApi.getPendingTotal({
			event: selectedTransactionType.value,
			tokenId: selectedToken?.value?parseInt(selectedToken.value):0,
		})
		setData(result.data);
		props.setLoading(false);
	}



	async function fetchGetTokenList() {
		if(props.loading) return;
		props.setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setSelectTokenDropdown(newDropdownList);
			setSelectedToken(newDropdownList[0]);
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		});
	}








	const transactionTypeDropdown: IDropdown[] = [{
		name: "All",
		value: "ALL",
	},
	{
		name: "In",
		value: "WALLET_IN",
	},
	{
		name: "Out",
		value: "WALLET_OUT",
	},
	{
		name: "COMMISSION",
        value: ".FEE",
	},
	{
		name: "GPD",
        value: "GPD",
	}
	,
	{
		name: "DISTRIBUTION",
        value: "DISTRIBUTION",
	}
];

	const [selectTokenDropdown, setSelectTokenDropdown] = useState<IDropdown[]>([]);



	return <Container>
		<Row style={{ width: "768px" }} className={"p-4 bg-white mb-5"}>
			<Col>
				<Row className={"mb-5"}>
					<Col>
						<CTitleDropdown id={"transaction-pending-page-pending-summary-transaction-type-dropdown"} title={"Transaction Type"} data={transactionTypeDropdown} selected={selectedTransactionType} onSelected={setSelectedTransactionType}/>
					</Col>
					<Col>
						<CTitleDropdown id={"transaction-pending-page-pending-summary-select-token-dropdown"} title={"Select Token"} data={selectTokenDropdown} selected={selectedToken} onSelected={setSelectedToken}/>
					</Col>
					
				</Row>


				<Row >
					<Col>
					{/* <Button className="btn-dark d-flex justify-content-center align-items-center w-100" onClick={()=>{ */}
						{/* fetchPendingTotal(selectedTransactionType.value, selectedToken?.value); */}
					{/* }}>Confirm</Button> */}
					</Col>
				</Row>


				<Row className={"bg-white pt-3 pb-1"}>
					<Col className={"d-flex justify-content-center"}><h5>Total Tx Pending Amount</h5></Col>
					<Col className={"d-flex justify-content-center"}><h5>Total Pending # </h5></Col>
				</Row>
				<Row className={"bt-5 mb-2"}>
					<Col className={"d-flex justify-content-center p-4 border border-2 border-radius "}><div>{data?.totalAmount} {data?.tokenName}</div></Col>
					<Col className={"d-flex justify-content-center p-4 border border-2 border-radius "}><div>{data?.totalCount}</div></Col>
				</Row>
			</Col>
		</Row>
	</Container>
}