import {Col, Row} from "react-bootstrap";

export default function AdminSettingsPageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Role</Col>
		<Col>Name</Col>
		<Col>Id</Col>
		<Col>Registration Date</Col>
		<Col>Last Login Date</Col>
		<Col>Security</Col>
		<Col></Col>
		<Col>Manage</Col>
	</Row>;
}