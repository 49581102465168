import {Button, Container} from "react-bootstrap";
import CListPage from "../../../components/CListPage";
import TransactionPendingPageOtherListItem, {ITransactionPendingPageOtherListItem} from "./TransactionPendingPageOtherListItem";
import TransactionPendingPageOtherFilter from "./TransactionPendingPageOtherFilter";
import TransactionPendingPageOtherListHeader from "./TransactionPendingPageOtherListHeader";
import { useState,useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import { Dto_getPendingTransactionsResult } from "api/wallet_connect/wallet_connect_dto";
import { IDropdown } from "pages/components/CDropdown";
import { TokenApi } from "api/token/TokenApi";
import { IDropdown_Dto_GetTokenResult } from "model/custom_dropdown";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import CSimplePagination from "../../../components/CSimplePagination";

export default function TransactionPendingPageOther({loading, setLoading, navigate}: {loading: boolean, setLoading: Function, navigate: NavigateFunction}) {
	const [index, setIndex] = useState(1);
	const [list, setList] = useState<Dto_getPendingTransactionsResult[]>([]);
	const [totalCount, setTotalCount] = useState(0);

	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});

	const [selectedEvent,setSelectedEvent] = useState<IDropdown>({
		name: "Fee Realization",
		value: "FEE",
	},)

	const [selectedToken,setSelectedToken] = useState<IDropdown>();


	const [selectTokenDropdown, setSelectTokenDropdown] = useState<IDropdown[]>([]);

	const generateItem = (value: ITransactionPendingPageOtherListItem) => {
		return <TransactionPendingPageOtherListItem key={value.txId}{...value}/>
	}

	useEffect(() => {
		fetchGetTokenList();
		fetchPendingTransactionsOther(1);
	},[])

	async function fetchGetTokenList() {
		if(loading) return;
		setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setSelectTokenDropdown(newDropdownList);
			setSelectedToken(newDropdownList[0]);
		}).catch((error) => {

		}).finally(() => {
			setLoading(false);
		});
	}

	async function fetchPendingTransactionsOther(page : number,search?:string,tokenId?:number,) {
		if(loading) return;
		setLoading(true);
		if(selectedToken?.value === undefined) return;

		setList([]);
		try{
			const result = await WalletConnectApi.getPendingTransactions({
				event : selectedEvent?.value?selectedEvent.value:"",
				tokenId: selectedToken.value?parseInt(selectedToken.value):undefined,
				search: search,
				pageNumber : page,
				pageSize : listDisplay.value?parseInt(listDisplay.value):25,
			});

			if(result && result.data.list){
				try{
					setList(result.data.list);
					setTotalCount(result.data.pagingInfo.totalCount)
					setLoading(false);
				}catch(e){
					setList([]);
					setTotalCount(0);
					setLoading(false);
				}
			}
			else{
				setList([]);
				setTotalCount(0);
				setLoading(false);
			}
		}catch(e){
		}finally{
			setLoading(false);
		}
	}

	return <Container>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchPendingTransactionsOther(page)}
			totalCount={totalCount}
			pageSize={listDisplay.value ? parseInt(listDisplay.value) : 25}
		/>
		<TransactionPendingPageOtherFilter
			selectedEvent={selectedEvent}
			setSelectedEvent={setSelectedEvent}
			selectedToken={selectedToken}
			setSelectedToken={setSelectedToken}
			selectTokenDropdown={selectTokenDropdown}
			setSelectTokenDropdown={setSelectTokenDropdown}
			selectedListDisplay={listDisplay}
			setSelectedListDisplay={setListDisplay}
			onSearch={(search:string)=>{
				fetchPendingTransactionsOther(1,search);
			}
			}

		/>
		<TransactionPendingPageOtherListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}