import {Button, Col, Row} from "react-bootstrap";
import CSettingButton from "../../components/CSettingButton";
import {useNavigate} from "react-router-dom";
import {Dto_GetWalletConnectionResult} from "../../../api/wallet_connect/wallet_connect_dto";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import {useState} from "react";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import CVerifyTFAModal from "pages/components/modal/CVerifyTFAModal";
import {shrinkAddress} from "../../../utils/util";
import CLinkExplorer from "../../components/CLinkExplorer";

export default function ConnectSettingsPageListItem(props: Dto_GetWalletConnectionResult) {
	const [loading, setLoading] = useState(false);
	const [showTfa, setShowTfa] = useState(false);
	const navigate = useNavigate();
	const onSettingClicked = () => {
		navigate("/wallet-connect/settings/edit", {
			state: {
				walletConnect: props
			}
		})
	}

	async function setWalletConnectionStatus(otpCode:string) {
		if(loading) return;
		setLoading(true);
		WalletConnectApi.setWalletConnectionStatus(props.pairId.toString(),{
				status : !props.status,
				otpCode : otpCode,
			}
		).then((res) => {
			if(res.success){
				window.location.reload();
			}
		}).catch((error) => {
		}).finally(() => {
			setLoading(false)
		});
	}

	return <>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={(otpCode:string)=>{
			setWalletConnectionStatus(otpCode);
		}}/>
		<Row className="p-3 bg-white mb-2 moveItem">
			<Col xs={1}>{props.pairId}</Col>
			<Col xs={1}>{props.inTokenName}</Col>
			<Col xs={1}>{props.outTokenName}</Col>
			<Col xs={2}>{`${props.conversionFee}%`}</Col>
			<Col xs={2}>
				<div>{`${props.inWalletName}`}</div>
				<CLinkExplorer type={"address"} target={props.inTokenName} address={props.inWalletAddress}
				               text={`(${shrinkAddress(props.inWalletAddress)})`} copyText={props.inWalletAddress}></CLinkExplorer>
			</Col>
			<Col xs={2}>
				<div>{`${props.outWalletName}`}</div>
				<CLinkExplorer type={"address"} target={props.outTokenName} address={props.outWalletAddress}
				               text={`(${shrinkAddress(props.outWalletAddress)})`} copyText={props.outWalletAddress}></CLinkExplorer>
			</Col>
			<Col xs={2} className="d-flex">
				{
					props.status ?
						<Button className="btn-success mb-1 mx-2" style={{height:"40.67px"}}  onClick={()=>setShowTfa(true)}>Enabled</Button>:
						<Button className="btn-secondary mb-1 mx-2" style={{height:"40.67px"}}  onClick={()=>setShowTfa(true)}>Disabled</Button>
				}
				<CSettingButton onClick={onSettingClicked} disabled={false}/>
			</Col>
		</Row>
	</>
}

