import {Button, Modal, Form, Container, Col} from "react-bootstrap";
import {useEffect, useState} from "react";
import CTextField from "../../../components/CTextField";
import {UserApi} from "../../../../api/user/UserApi";

export interface IGPDMintPageBurnModalComponent {
    show: boolean,
    setShow: any,
    onCancel?: any,
    onOk: Function,
    to: string
    amount: string
    gasFeeAmt: number
}

export default function GPDMintPageBurnModal(props: IGPDMintPageBurnModalComponent) {
    const [otpCode, setOtpCode] = useState('');
    const [adminUser, setAdminUser] = useState('');

    useEffect(() => {
        fetchAdminUser();
    }, [])

    async function fetchAdminUser() {
        UserApi.getAccountInfo().then((res) => {
            const userName = res.data.detail.userName;
            setAdminUser(userName);
        })
    }

    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>{
                if(props.onCancel) props.onCancel();
                props.setShow(false)
            }}
            backdrop="static"
            keyboard={false}
        >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Burn GPD</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ border: "none", textAlign: "center" }}>
                       <Container>
                           <Col className={"d-flex flex-row justify-content-between mb-4"}>
                               <div>To</div>
                               <div>{props.to}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-2"}>
                               <div>(C) Amount</div>
                               <div>{`${props.amount} GPD`}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-4"}>
                               <div>(C) Max Transaction Fee</div>
                               <div>{`${props.gasFeeAmt} GPX`}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-5"}>
                               <div>Approved By</div>
                               <div>{`${adminUser}`}</div>
                           </Col>
                       </Container>
                    </Modal.Body>
                    <div className={"ps-3 pe-3 mb-4"}>
                        <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} placeholder={"Enter Google authentication code"} />
                    </div>
                    <Modal.Footer style={{ border: "none" }}>
                        <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.onOk(otpCode);
                                props.setShow(false);
                            }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};