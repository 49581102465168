import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {ApiResponse, DataResponse} from "../../model/response_model";
import {Dto_NewSessionStep1, Dto_NewSessionStep2, Dto_NewSessionStep1Result, Dto_NewSessionStep2Result, Dto_RefreshSession, Dto_RefreshSessionResult} from "./auth_dto";

export class AuthApi {
	private static instance: AuthApi
	private constructor() {}

	public static async newSessionStep1(data: Dto_NewSessionStep1):
		Promise<{code: string, msg: string, data: Dto_NewSessionStep1Result, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_OAUTH_TOKEN, data);
	}

	public static async newSessionStep2(data: Dto_NewSessionStep2):
		Promise<{code: string, msg: string, data: Dto_NewSessionStep2Result, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_OAUTH_TOKEN, data);
	}

	public static async refreshSession(refreshToken: string):
		Promise<{code: string, msg: string, data: Dto_RefreshSessionResult, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_OAUTH_TOKEN, {
			grantType: "refresh_token",
			authType: "login",
			refreshToken: refreshToken,
		});
	}
}