import {Col, Row} from "react-bootstrap";
import {Dto_GetLogListResult} from "../../api/log/log_dto";
import CLocaleTimestamp from "../components/CLocaleTimestamp";

export default function LogDataPageListItem(props: Dto_GetLogListResult) {
	return <Row className="p-3 bg-white mb-2 moveItem">
			<Col xs={1}>{props.category}</Col>
			<Col xs={2}>{props.type}</Col>
		{props.status === 'COMPLETED' ? <Col xs={1} className={"text-success"}>{props.status}</Col> : <Col xs={1} className={"text-danger"}>{props.status}</Col>}
			<Col>{props.contents}</Col>
			<Col xs={2}>{props.email}</Col>
			<Col xs={1}>{props.accessIp}</Col>
			<Col xs={1}>
				<CLocaleTimestamp timestamp={props.createDate}/>
				{/*{props.createDate}*/}
			</Col>
		</Row>
}