import {Button, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import {useEffect, useState} from "react";
import CBreadCrumb from "../../../components/CBreadCrumb";
import {useLocation, useNavigate} from "react-router-dom";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import WalletManagementSetGasFeePageMainnet from "./WalletManagementSetGasFeePageMainnet";
import WalletManagementSetGasFeePageSetGasFee from "./WalletManagementSetGasFeePageSetGasFee";
import WalletManagementSetGasFeePageDynamicNetworkFee from "./WalletManagementSetGasFeePageDynamicNetworkFee";
import {IDropdown_Dto_GetMainnetResult} from "../../../../model/custom_dropdown";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";
import {TransactionApi} from "../../../../api/transactions/TransactionApi";
import CLoadingDimmer from "../../../components/CLoadingDimmer";

export default function WalletManagementSetGasFeePage() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState('');

	// mainnet
	const [mainnetList, setMainnetList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [selectedMainnet, setSelectedMainnet] = useState<IDropdown_Dto_GetMainnetResult>();

	// dynamic network fee
	const [proposedDynamicNetworkFee, setProposedDynamicNetworkFee] = useState('');

	const [maxGasPrice, setMaxGasPrice] = useState('');
	const [gasPrice, setGasPrice] = useState('');
	const [gasLimit, setGasLimit] = useState('');
	const [maxTransactionFee, setMaxTransactionFee] = useState('');
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showRestartModal, setShowRestartModal] = useState(false);
	const [schedulerProcessStatus, setSchedulerProcessStatus] = useState(false);

	useEffect(() => {
		checkProcessSchedulerStatus();
	}, [])

	async function checkProcessSchedulerStatus() {
		TransactionApi.getTransactionProcessStatus().then(res => {
			if(res.data.status === "ENABLED") {
				setSchedulerProcessStatus(true);
			}
			else {
				setSchedulerProcessStatus(false);
			}
		});
	}

	async function onSubmit(otpCode: string) {
		if(loading) return;
		if(!selectedMainnet || !selectedMainnet.data) return;

		let gasLimitNum = 0;
		let maxGasPriceNum = 0;
		try {
			gasLimitNum = parseInt(gasLimit);
			maxGasPriceNum = parseFloat(maxGasPrice);
		} catch(e) {
			return;
		}
		setLoading(true);
		NetworkApi.setNetworkGasFee(selectedMainnet?.data?.id, {
			gasLimit: gasLimitNum,
			maxGasPrice: maxGasPriceNum,
			otpCode: otpCode
		}).then((res) => {
			if(res.success) {
				alert(MESSAGE_CHANGES_SAVED)
				navigate(-1);
			} else {
				alert(MESSAGE_CHANGES_FAILED)
			}
		}).catch((error) => {
			alert(MESSAGE_CHANGES_FAILED)
		}).finally(() => {
			setLoading(false);
		})
	}

	async function onRestart(otpCode: string) {
		if(loading) return;
		setLoading(true);
		const res2 = await TransactionApi.setTransactionProcessStatus(otpCode)
		if(res2.success) {
			alert(MESSAGE_CHANGES_SAVED)
		} else {
			alert(MESSAGE_CHANGES_FAILED)
		}
		setLoading(false);
		window.location.reload();
	}

	async function onReset() {
		setSelectedMainnet(undefined);
		setProposedDynamicNetworkFee('')
		setMaxGasPrice('')
		setGasPrice('')
		setGasLimit('')
		setMaxTransactionFee('')
	}

	async function onMainnetChanged() {
		setProposedDynamicNetworkFee('')
		setMaxGasPrice('')
		setGasPrice('')
		setGasLimit('')
		setMaxTransactionFee('')
		checkProcessSchedulerStatus();
	}

	return <Container>
		<CVerifyTFAModal show={showSubmitModal} setShow={setShowSubmitModal} onOk={(otpCode: string) => {
			onSubmit(otpCode);
		}}/>
		<CVerifyTFAModal show={showRestartModal} setShow={setShowRestartModal} onOk={(otpCode: string) => {
			onRestart(otpCode);
		}}/>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Set Gas Fee"} secondUrl={"/wallet/management/fee"}
		/>
		<CPageTitle title={"Set Gas Fee"} subtitle={""}/>

		<WalletManagementSetGasFeePageMainnet
			mainnetList={mainnetList} setMainnetList={setMainnetList}
			selectedMainnet={selectedMainnet} setSelectedMainnet={setSelectedMainnet}
			onReset={onMainnetChanged}
		/>
		<WalletManagementSetGasFeePageDynamicNetworkFee
			selectedMainnet={selectedMainnet}
			proposedDynamicNetworkFee={proposedDynamicNetworkFee} setProposedDynamicNetworkFee={setProposedDynamicNetworkFee}
			gasPrice={gasPrice} setGasPrice={setGasPrice}
		/>
		<WalletManagementSetGasFeePageSetGasFee
			selectedMainnet={selectedMainnet}
			proposedDynamicNetworkFee={proposedDynamicNetworkFee}
			maxGasPrice={maxGasPrice} setMaxGasPrice={setMaxGasPrice}
			gasPrice={gasPrice} setGasPrice={setGasPrice}
			gasLimit={gasLimit} setGasLimit={setGasLimit}
			maxTransactionFee={maxTransactionFee} setMaxTransactionFee={setMaxTransactionFee}
		/>
		<Row style={{ width: "768px", paddingBottom: "36px"}} >
			<div className={"d-flex flex-row justify-content-end"}>
				<div><Button size={"lg"} variant={"primary"} className={"me-3"} disabled={schedulerProcessStatus} onClick={() => setShowRestartModal(true)}>Restart</Button></div>
				<div><Button size={"lg"} variant={"dark"} onClick={() => onReset()} className={"me-3"}>Reset</Button></div>
				<div><Button size={"lg"} variant={"dark"}
				             disabled={!selectedMainnet || !maxGasPrice || !gasPrice || !gasLimit || !maxTransactionFee}
				             onClick={() => setShowSubmitModal(true)}>Submit</Button></div>
			</div>
		</Row>
	</Container>
}