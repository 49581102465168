import {Button, Col, Container, Row} from "react-bootstrap";
import {COLOR_MAIN} from "../../../constants/value";
import {Dto_GetAccountInfoResult} from "../../../api/user/user_dto";
import CLocaleTimestamp from "../../components/CLocaleTimestamp";

export interface IAccountSettingsPageUpperCard {
	userInfo?: Dto_GetAccountInfoResult
	setEditAccount: Function
	setPassword: Function
	setDisableOTP: Function
}

export default function AccountSettingsPageUpperCard(props: IAccountSettingsPageUpperCard) {
	const userId = props.userInfo ? props.userInfo.userId : '';
	const userName = props.userInfo ? props.userInfo.userName : '';
	const email = props.userInfo ? props.userInfo.email : '';
	const mobile = props.userInfo ? props.userInfo.mobile : '';
	const chatId = props.userInfo ? props.userInfo.chatId : '';
	const createDate = props.userInfo ? <CLocaleTimestamp timestamp={props.userInfo.createDate}/> : '-';
	const lateLoginDate = props.userInfo ? <CLocaleTimestamp timestamp={props.userInfo.lastLoginDate}/>  : '-';
	const passwordDate = props.userInfo ? <CLocaleTimestamp timestamp={props.userInfo.passwordDate}/>  : '-';
	const otpDate = props.userInfo && props.userInfo.otpDate ? <CLocaleTimestamp timestamp={props.userInfo.otpDate}/> : '-';

	return <Container>
		<Row>
			{/* Account Info */}
			<Col className={"p-3 me-3 bg-white"}>
				<div className={"h4 d-flex flex-row justify-content-center"}>Account Information</div>
				<div style={{ height: "200px", marginTop: "16px", marginBottom: "16px" }}>
					<div style={{ borderBottom: "1px solid #eeeeee"}}></div>
					<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
						<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
							<div>Name</div>
							<div>{userName ?? '-'}</div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
							<div>ID</div>
							<div>{email ?? '-'}</div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
							<div>Mobile</div>
							<div>{mobile ?? '-'}</div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
							<div>Chat Id (Telegram)</div>
							<div>{chatId ?? '-'}</div>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between"}}>
							<div>Registration Date</div>
							<div>{createDate ?? '-'}</div>
						</div>
					</div>
				</div>
				<div className={"d-flex justify-content-center"}><Button size={"lg"} variant={"dark"} onClick={() => props.setEditAccount(true)}>Change</Button></div>
			</Col>

			{/* Change Password */}
			<Col className={"p-3 me-3 bg-white"}>
				<div className={"h4 d-flex flex-row justify-content-center"}>Change Password</div>
				<div style={{ height: "200px", marginTop: "16px", marginBottom: "16px" }}>
					<div style={{ borderBottom: "1px solid #eeeeee"}}></div>
					<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
						<div className={"mb-4"}>Date of last change</div>
						<div style={{ color: COLOR_MAIN }}>{passwordDate ?? '-'}</div>
					</div>
				</div>
				<div className={"d-flex justify-content-center"}><Button size={"lg"} variant={"dark"} onClick={() => props.setPassword(true)}>Change</Button></div>
			</Col>

			{/* Google OTP */}
			<Col className={"p-3 bg-white"}>
				<div className={"h4 d-flex flex-row justify-content-center"}>Two-factor Authentication</div>
				<div style={{ height: "200px", marginTop: "16px", marginBottom: "16px" }}>
					<div style={{ borderBottom: "1px solid #eeeeee"}}></div>
					<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
						<div className={"mb-4"}>Setting Date</div>
						<div style={{ color: COLOR_MAIN }}>{otpDate ?? '-'}</div>
					</div>
				</div>
				<div className={"d-flex justify-content-center"}><Button size={"lg"} variant={"danger"} onClick={() => props.setDisableOTP(true)}>Disable OTP</Button></div>
			</Col>
		</Row>
	</Container>
}