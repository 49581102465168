import CLoadingDimmer from "../components/CLoadingDimmer";
import CBreadCrumb from "../components/CBreadCrumb";
import CPageTitle from "../components/CPageTitle";
import {Button, Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CDropdown, {IDropdown} from "../components/CDropdown";
import {MESSAGE_FILL_THE_BLANK} from "../../constants/value";

export default function MetamaskTestPage() {
	const [loading, setLoading] = useState(false);

	const [userAddress, setUserAddress] = useState('');
	const [contractAddress, setContractAddress] = useState('');
	const [toAddress, setToAddress] = useState('');
	const [functionDropdownList, setFunctionDropdownList] = useState<IDropdown[]>([]);
	const [selectedFunction, setSelectedFunction] = useState<IDropdown>({
		name: "Send Token",
		value: "send",
	});
	const [value, setValue] = useState(0);

	const functionDropdownData: IDropdown[] = [
		{
			name: "Send Token",
			value: "send",
		},
		{
			name: "Mint Token",
			value: "mint",
		},
	];

	useEffect(() => {
		checkIfWalletIsConnected(setUserAddress).then((result) => {

		});
	}, []);

	useEffect(() => {
		// onAddressChanged(userAddress);
	}, [userAddress]);

	function ConnectComponent({ setUserAddress }: {setUserAddress: Function}) {
		return (
			<button style={{ padding: "8px 16px" }} onClick={() => connect(setUserAddress)}>
				Connect to MetaMask
			</button>
		);
	}

	async function connect(onConnected: Function) {
		// @ts-ignore
		if (!window.ethereum) {
			alert("Get MetaMask!");
			return;
		}

		// @ts-ignore
		const accounts = await window.ethereum.request({
			method: "eth_requestAccounts",
		});
		console.log(`## connected! >>`)
		console.log(accounts);
		onConnected(accounts[0]);
	}

	async function disconnect() {
		// @ts-ignore
		// window.ethereum.on('disconnect', handler: (error: ProviderRpcError) => void);
	}

	async function checkIfWalletIsConnected(onConnected: Function) {
		// @ts-ignore
		if (window.ethereum) {
			// @ts-ignore
			const accounts = await window.ethereum.request({
				method: "eth_accounts",
			});

			if (accounts.length > 0) {
				const account = accounts[0];
				onConnected(account);
				return;
			}
		}
	}

	async function send() {
		if(
			contractAddress === undefined || contractAddress === '' ||
			toAddress === undefined || toAddress === ''
		) {
			alert(MESSAGE_FILL_THE_BLANK);
			return;
		}
		const encodedAmount = value.toString(16).padStart(64, "0");
		const encodedAddress = toAddress.slice(2).padStart(64, "0");
		const params = [
			{
				from: userAddress,
				to: contractAddress,
				gas: '0x186a0', // 30400
				gasPrice: '0x2540be400', // 10000000000000
				data:
					`0xa9059cbb${encodedAddress}${encodedAmount}`,
			},
		];

		// @ts-ignore
		window.ethereum
			.request({
				method: 'eth_sendTransaction',
				params,
			}).then((result: any) => {
				console.log(`## eth_sendTransaction success >> ${JSON.stringify(result)}`);
			}).catch((error: any) => {
				console.log(`## eth_sendTransaction failed >> ${JSON.stringify(error)}`);
			});
	}

	async function mint() {
		if(
			contractAddress === undefined || contractAddress === '' ||
			toAddress === undefined || toAddress === ''
		) {
			alert(MESSAGE_FILL_THE_BLANK);
			return;
		}
		const encodedAmount = value.toString(16).padStart(64, "0");
		const encodedAddress = toAddress.slice(2).padStart(64, "0");
		const params = [
			{
				from: userAddress,
				to: contractAddress,
				gas: '0x186a0', // 30400
				gasPrice: '0x2540be400', // 10000000000000
				data:
					`0x40c10f19${encodedAddress}${encodedAmount}`,
			},
		];

		console.log(`## eth_sendTransaction()`);
		// @ts-ignore
		window.ethereum
			.request({
				method: 'eth_sendTransaction',
				params,
			}).then((result: any) => {
			console.log(`## eth_sendTransaction success >> ${JSON.stringify(result)}`);
		}).catch((error: any) => {
			console.log(`## eth_sendTransaction failed >> ${JSON.stringify(error)}`);
		});
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Metamask Test"} firstUrl={"/test/metamask"}/>
		<CPageTitle title={"Metamask Test Page"} subtitle={"This page is for the authentication test using metamask auth API"}/>
		{
			// @ts-ignore
			userAddress && (window.ethereum && window.ethereum.isConnected())? (
				<div style={{ display: "flex", flexDirection: "column", alignItems: "start"}}>
					<div style={{ marginBottom: "16px", display: "flex", flexDirection: "row"}}>
						<div style={{
							marginRight: "8px",
							backgroundColor: "lightgreen", color: "darkGreen", fontWeight: "700",
							borderRadius: "8px", padding: "8px 16px"
						}}>{userAddress}</div>
						<div style={{
							padding: "8px 16px",
							color: "green",
						}} onClick={disconnect}>
							{"Connected"}
						</div>
					</div>
					<CDropdown id={"metamask-test-function-dropdown"} hint={"Select Function"}
					           data={functionDropdownData}
					           selected={selectedFunction} onSelected={setSelectedFunction}></CDropdown>
					<div style={{ marginTop: "16px", marginBottom: "16px"}}>
						Contract Address
						<input style={{ marginLeft: "16px", width: "260px" }} value={contractAddress} onChange={(e) => {
							setContractAddress(e.target.value);
						}}/>
					</div>
					<div style={{ marginBottom: "16px"}}>
						To Address
						<input style={{ marginLeft: "16px", width: "260px" }} value={toAddress} onChange={(e) => {
							setToAddress(e.target.value);
						}}/>
					</div>
					<div style={{ marginBottom: "36px"}}>
						Value (wei)
						<input style={{ marginLeft: "16px", width: "260px" }} type={"number"} value={value} onChange={(e) =>
							setValue(parseFloat(e.target.value))}/>
					</div>
					{ selectedFunction.value === "mint" ?
						<button style={{ padding: "8px 16px" }} onClick={mint}>Mint Token</button> :
						<button style={{ padding: "8px 16px" }} onClick={send}>Send Token</button>
					}
				</div>
			) : (
				<ConnectComponent setUserAddress={setUserAddress}/>
			)
		}
	</Container>
}