import {ReactElement} from "react";

interface IListPageComponent {
	list: Array<any>;
	itemGenerator: (value: any) => ReactElement;
}

export default function CListPage(props: IListPageComponent) {
	return <div className={"pb-5"}>
		{props.list.map((value) => {
			try {
				return props.itemGenerator(value)
			} catch(e) {}
		})}
	</div>
}