import CLinkExplorer from 'pages/components/CLinkExplorer';
import React from 'react';
import { Button, Col, Container, Form, Modal } from 'react-bootstrap';
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";

interface ITransactionPendingDetailModal{
    show : boolean;
    setShow : Function;
    date : string,
	txPairId : string,
	fromAddress : string,
	fromWalletName ?: string,
	toAddress : string,
    toWalletName ?: string,
    maxFee : number
	amount : number,
	tokenName ?: string,
	gasFee : number,
	mainnetName : string,
	mainnetId : number,
	pairId : number,
    setResendCombined ?: Function
}

export default function TransactionPendingDetailModal(props:ITransactionPendingDetailModal) {
    return (
        <>
        <Modal show={props.show}   onHide={()=>{
                props.setShow(false)
            }} 
            backdrop="static"
            keyboard={false}>
          
          
          <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Fee Realization Transaction Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ border: "none", textAlign: "center" }}>
                   
                    </Modal.Body>
                    <Container className='p-4' >
                           <Col className={"d-flex flex-row justify-content-between mb-5"}>
                               <div><strong>Swap Transaction ID</strong></div>
                               <div>{props.pairId}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>Date</strong></div>
                               <div>{props.date?<CLocaleTimestamp timestamp={props.date}/>:"-"}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>Amount</strong></div>
                               <div>{props.amount} {props.tokenName?props.tokenName:"-"}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>From Address</strong></div>
                               <CLinkExplorer
                                   target={props.mainnetName} type={"address"} width={300}
                                   address={props.fromAddress} text={props.fromAddress} copyText={props.fromAddress}/>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>To Address</strong></div>
                               <CLinkExplorer
                                   target={props.mainnetName} type={"address"} width={300}
                                   address={props.toAddress} text={props.toAddress} copyText={props.toAddress}/>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>Txid</strong></div>
                                <CLinkExplorer
                                    target={props.mainnetName} type='tx' address={props.txPairId}
                                    text={props.txPairId}
                                    copyText={props.txPairId}
                                    width={300}/>
                           </Col>

                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>Mainnet</strong></div>
                               <div>{props.mainnetName}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-3"}>
                               <div><strong>Current Transaction Fee</strong></div>
                               <div>{props.gasFee}</div>
                           </Col>
                           <Col className={"d-flex flex-row justify-content-between mb-5"}>
                               <div><strong>Max Transaction Fee</strong></div>
                               <div>{props.maxFee}</div>
                           </Col>
                       </Container>
                    <Modal.Footer style={{ border: "none" }}>
                    <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setShow(false);
                                if(props.setResendCombined) props.setResendCombined(props.txPairId)
                            }}>
                            Resend Tx
                        </Button>
                        <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setShow(false);
                            }}>
                            Cancle
                        </Button>
                    </Modal.Footer>
                </Form>

        </Modal>
        </>
    );
}

