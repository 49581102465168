import {Col, Row} from "react-bootstrap";

export default function LogDataPageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
			<Col xs={1}>{"Category"}</Col>
			<Col xs={2}>{"Type"}</Col>
			<Col xs={1}>{"Status"}</Col>
			<Col>{"Contents"}</Col>
			<Col xs={2}>{"Id"}</Col>
			<Col xs={1}>{"Access Ip"}</Col>
			<Col xs={1}>{"ActivityDate"}</Col>
		</Row>;
}