import {getRecoil, setRecoil} from "recoil-nexus";
import {authRegisterAtState, authDisplayTimeSelector, authCurrentTimeState} from "./auth_user_atom";

export class AuthTimer {
	private static instance: AuthTimer;
	private timeoutId: NodeJS.Timeout | undefined;

	public static getInstance(): AuthTimer {
		if (!AuthTimer.instance) {
			AuthTimer.instance = new AuthTimer();
		}
		return AuthTimer.instance;
	}

	public restart() {
		this.stop();
		setRecoil(authRegisterAtState, Date.now());
		this.timeoutId = setInterval(() => {
			const now = Date.now();
			setRecoil(authCurrentTimeState, now);
		}, 1000);
	}

	public start() {
		clearTimeout(this.timeoutId);
		this.timeoutId = undefined;
		this.timeoutId = setInterval(() => {
			const now = Date.now();
			setRecoil(authCurrentTimeState, now);
		}, 1000);
	}

	public stop() {
		if (this.timeoutId) {
			setRecoil(authRegisterAtState, 0);
			setRecoil(authCurrentTimeState, 0);
			clearTimeout(this.timeoutId);
			this.timeoutId = undefined;
		}
	}
}