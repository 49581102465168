import {Button, Modal, Form} from "react-bootstrap";
import CTextField from "../CTextField";
import {useState} from "react";
import {AuthApi} from "../../../api/auth/AuthApi";
import {TokenManager} from "../../../states/token";
import {authUserState, IAuthUser} from "../../../states/auth_user_atom";
import {UserApi} from "../../../api/user/UserApi";
import {Dto_NewSessionStep2, Dto_NewSessionStep2Result} from "../../../api/auth/auth_dto";
import {useRecoilState} from "recoil";
import {AuthTimer} from "../../../states/AuthTimer";

export interface IVerifyLoginTFAModalComponent {
    show: boolean,
    setShow: any,
    onCancel?: any,
    onOk: any,
    email: string,
    password: string,
}

export default function CVerifyLoginTFAModal(props: IVerifyLoginTFAModalComponent) {
    const [loading, setLoading] = useState(false);
    const [otpCode, setOtpCode] = useState('');
    const [userInfo, setUserInfo] = useRecoilState<IAuthUser | undefined>(authUserState);

    async function login() {
        AuthApi.newSessionStep2({
            authType: "add_auth",
            grantType: "password",
            email: props.email,
            password: props.password,
            otpCode: otpCode,
        }).then((res) => {
            if (res.success) {
                setLoading(false);
                TokenManager.setAccessToken(res.data.accessToken);
                TokenManager.setRefreshToken(res.data.refreshToken);
                fetchAdminUser(res.data);
                props.onOk(true);
                props.setShow(false);
            }
            else {
                alert(res.msg)
                setLoading(false);
            }
        }).catch((err) => {
            alert(err)
            setLoading(false);
        })
    }

    async function fetchAdminUser(res: Dto_NewSessionStep2Result) {
        UserApi.getAccountInfo().then((accountResponse) => {
            const userName = accountResponse.data.detail.userName;
            setUserInfo({
                email: res.email,
                accessToken: res.accessToken,
                refreshToken: res.refreshToken,
                name: userName,
                roleId: accountResponse.data.detail.roleId,
                roleName: accountResponse.data.detail.roleName,
            })
            AuthTimer.getInstance().restart();
        })
    }

    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Two-Factor Authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ border: "none", textAlign: "center" }}>
                        Enter the verification code displayed on your mobile device to proceed with your request.
                    </Modal.Body>
                    <div className={"ps-3 pe-3 mb-4"}>
                        <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} />
                    </div>
                    <Modal.Footer style={{ border: "none" }}>
                        <Button
                            variant={"dark"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                login();
                            }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};