import {BaseHttp} from "../BaseHttp";
import {DataResponse} from "../../model/response_model";
import {Dto_GetWalletBalances, Dto_GetWalletBalancesResult} from "./dashboard_dto";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_GetWalletsDistributeResult} from "../wallet/wallet_dto";

export class DashboardApi {
	private static instance: DashboardApi
	private constructor() {}

	// internal error
	public static async getWalletBalances(params: Dto_GetWalletBalances):
		Promise<{code: string, msg: string, data: { list: Dto_GetWalletBalancesResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_DASHBOARD_WALLETS, params);
	}
}