import {Button, Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import CSearchTextField from "../../../components/CSearchTextField";
import CRealizeFeeModal from "./CRealizeFeeModal";
import {useEffect, useState} from "react";
import {IDropdown_Dto_GetTokenResult} from "../../../../model/custom_dropdown";
import { TokenApi } from './../../../../api/token/TokenApi';

interface IFeeRecordPageUnrealizedFeeFilter{
	selectedTokenData ?: IDropdown,
	setSelectedTokenData : Function,
	tokenDropDownList : IDropdown[],
	selectedWalletData ?: IDropdown,
	setSelectedWalletData : Function,
	walletDropDownList : IDropdown[],
	fetchWalletsByToken : Function,
	fetchGetTokenList : Function,
	onSearch : Function,
	accessRealize: boolean,
}

export default function FeeRecordPageUnrealizedFeeFilter(props:IFeeRecordPageUnrealizedFeeFilter) {
	const [show, setShow] = useState(false);
	const onRealizeClicked = () => {
		setShow(true)
	}

	return <>
		<Row>
			<CRealizeFeeModal show={show} setShow={setShow}
			                  fetchGetTokenList={props.fetchGetTokenList}
			                  fetchWalletsByToken={props.fetchWalletsByToken}
			                  selectedTokenData={props.selectedTokenData}
			                  selectedWalletData={props.selectedWalletData}
			                  setSelectedTokenData={props.setSelectedTokenData}
			                  setSelectedWalletData={props.setSelectedWalletData}
			                  tokenDropDownList={props.tokenDropDownList}
			                  walletDropDownList={props.walletDropDownList}
				// onConfirm={() => {}}
			/>
			<Row className={"mb-3 align-items-end"}>
				<div className={"d-flex justify-content-end"}>
					<Button size={"lg"} variant={"dark"} onClick={onRealizeClicked} disabled={!props.accessRealize}>Realize</Button>
				</div>
			</Row>
			<Row className="mb-3 d-flex justify-content-between align-items-end">
				<Col>
					<CDropdown id={"fee-record-page-unrealized-fee-dropdown-select-token"} width={360} hint={"Select Token"} data={props.tokenDropDownList}
					           selected={props.selectedTokenData}
					           onSelected={(selected: IDropdown) => {
						           props.setSelectedTokenData(selected);

						        //    if (props.selectedTokenData !== selected) {
							    //        props.setSelectedWalletData(undefined);
						        //    }
						           props.fetchWalletsByToken(selected.value);

								//    props.setSelectedWalletData(props.walletDropDownList[0]);

					           }}/>
				</Col>
				<Col>
					<CDropdown id={"fee-record-page-unrealized-fee-dropdown-select-wallet"} width={360} hint={"Select Wallet"} data={props.walletDropDownList}
					           selected={props.selectedWalletData} onSelected={props.setSelectedWalletData}></CDropdown>
				</Col>
				<Col>
					<div style={{display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center"}}>
						<CSearchTextField id={"wallet-management-search"} placeholder={"Pair ID"} onSearch={props.onSearch}/>
					</div>
				</Col>
			</Row>
		</Row>
	</>
}