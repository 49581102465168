import {Button, Form} from "react-bootstrap"
import React, {useState} from "react";
import search from "../../assets/icons/search.png";

export interface ISearchTextFieldComponent {
	id: string
	placeholder?: string
	onSearch?: Function
	enable?: boolean
}

export default function CSearchTextField(props: ISearchTextFieldComponent) {
	const [text, setText] = useState('');

	function onSearchEvent(e: any) {
		e.preventDefault();
		if(props.onSearch !== undefined) {
			props.onSearch(text);
		}
	}

	return <Form>
		<div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
			<div style={{ width: "320px", marginRight: "4px"}}><Form.Control
				className={"pt-2 pb-2"}
				id={props.id + "search"}
				type={"text"}
				value={text}
				onChange={e => setText(e.target.value)}
				placeholder={props.placeholder ?? "Enter a value"}/>
			</div>
			<Button
				className="hover"
				style={{ padding: "8px 16px", cursor: "pointer",
					border: "none", borderRadius: "6px", backgroundColor: "#dddddd" }}
				type={"submit"}
				onClick={onSearchEvent}>
				<img
					src={search}
					width="18"
					height="18"
					alt="search"
				/>
			</Button>
		</div>
	</Form>
}