import {AuthApi} from "../api/auth/AuthApi";
import {TokenManager} from "../states/token";
import {NavigateFunction} from "react-router-dom";
import {DataResponse} from "../model/response_model";
import {navigateTo} from "../navigation";
import {AuthTimer} from "../states/AuthTimer";

export async function checkLogin(): Promise<boolean> {
	const accessToken = TokenManager.getAccessToken();

	if (accessToken) {
		// const validationResult = await AuthApi.refreshSession({
		// 	token: accessToken,
		// });
		return true;
	} else {
		return false;
	}
}

export function logout(): void {
	TokenManager.setAccessToken('');
	AuthTimer.getInstance().stop();
	navigateTo('/login');
}

export async function checkValidResult(result: DataResponse|undefined, navigate?: NavigateFunction): Promise<boolean> {
	if(!result) return false;
	if(result.code === "9902") {
		return false;
	}
	else if(result.code === "9901") {
		return false;
	}
	else if(result.code === "9990") {
		return false;
	}
	else if(result.code === "9991") {
		return false;
	}
	else if(result.code === "9992") {
		TokenManager.setAccessToken('');
		navigateTo('/login');
		return false;
	}
	else if(result.code === "9993") {
		TokenManager.setAccessToken('');
		const result  = await refreshAccessToken();
		if(!result) navigateTo('/login');
		return false;
	}
	else if(result.code === "9999") {
		return false;
	}
	else {
		return true;
	}
}

export async function refreshAccessToken(): Promise<boolean> {
	const refreshToken = TokenManager.getRefreshToken();
	if(refreshToken) {
		const result = await AuthApi.refreshSession(refreshToken);
		if (result) {
			TokenManager.setAccessToken(result.data.accessToken);
			TokenManager.setRefreshToken(result.data.refreshToken);
			AuthTimer.getInstance().restart();
			return true;
		}
	}
	return false;
}