import {Button, Container, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import danger from "../../../../assets/icons/danger.png";
import {UserSecurityApi} from "../../../../api/user_security/UserSecurityApi";
import {AdminApi} from "../../../../api/admin/AdminApi";
import {Dto_GetAdminListResult} from "../../../../api/admin/admin_dto";

export interface IAdminSettingsLoginLockModalComponent {
    show: boolean
    setShow: any
    onConfirm: any
    user?: Dto_GetAdminListResult
}

export default function AdminSettingsLoginLockModal(props: IAdminSettingsLoginLockModalComponent) {
    const [tfa, setTfa] = useState(false);

    return (
        <>
            <CVerifyTFAModal show={tfa} setShow={setTfa} onOk={(code: string) => {
                if(!props.user) return;
                AdminApi.setAdminStatus(props.user.userId, {
                    lockedYn: true,
                    otpCode: code,
                }).then((result) => {
                    if(result.success) {
                        if(props.onConfirm) props.onConfirm(true);
                    }
                    else {
                        alert(result.msg);
                        return;
                    }
                }).catch((e) => {
                    alert(e);
                    return;
                })
            }}/>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
            <Modal.Title>Login Lock</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none" }}>
                <Container className={"d-flex flex-column align-items-center"}>
                    <div style={{ borderRadius: "80px", backgroundColor: "#FFF3D8", width: "80px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "32px" }}>
                        <img src={danger} width={40} color={"#FF8800"}/>
                    </div>
                    <div className={"text-secondary text-center"}>Do you want to switch selected admins to login lockout?</div>
                </Container>
            </Modal.Body>
            <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                <Button
                    style={{ width: "360px"}}
                    variant="dark"
                    size={"lg"}
                    onClick={() => {
                        props.setShow(false);
                        setTfa(true);
                    }}>{props.onConfirm ?? "Confirm"}
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};