import {Col, Container, Row, Stack} from "react-bootstrap";
import {Dto_GetAccountInfoResult} from "../../../api/user/user_dto";

export default function AccountSettingsPageBottomCard(userInfo: Dto_GetAccountInfoResult) {
	const level = userInfo ? userInfo.roleName : '';
	const processNames = userInfo ? userInfo.processNames : [];
	return <Container className={"border bg-white mb-5"}>
		<Col>
			<Row className={"mt-5 ms-4 me-4 mb-4"}>
				<Col>
					<div style={{ display: "flex", justifyContent: "space-between",}}>
						<h4>My Level</h4>
						<h4>{level ?? '-'}</h4>
					</div>
				</Col>
			</Row>
			<Row className={"h5 pt-3 pb-3 ps-5 pe-5 mb-4 bg-white"}>Allowed Access</Row>
			<Row className={"ps-5 pe-5 pb-4"}>
				<Stack gap={2}>
					{processNames ? processNames.map((name) => <div key={name}>{name}</div>) : <div></div>}
				</Stack>
			</Row>
		</Col>
	</Container>
}