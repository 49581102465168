import {BaseHttp} from "../BaseHttp";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";
import {ApiUrl} from "../../constants/ApiUrl";
import {
	Dto_createWalletConnection,
	Dto_createWalletConnectionResult,
	Dto_GetDownloadFeeListByFeeGroupId,
	Dto_getPendingTotal,
	Dto_getPendingTotalResult,
	Dto_getPendingTransactions,
	Dto_getPendingTransactionsResult,
	Dto_GetTransactionApprove,
	Dto_GetTransactionApproveResult,
	Dto_GetTransactionDetail,
	Dto_getUnralizedFeeTotal,
	Dto_getUnralizedFeeTotalResult,
	Dto_GetWalletConnection,
	Dto_GetWalletConnectionResult, Dto_getWalletsByTokenResult, Dto_setRealizeFee, Dto_setRefundAndResend,
	Dto_setRefundAndResendResult,
	Dto_setResendCombined,
	Dto_SetWalletConnectionBasic,
	Dto_SetWalletConnectionBasicResult,
	Dto_SetWalletConnectionFee,
	Dto_SetWalletConnectionFeeResult,
	Dto_SetWalletConnectionStatus,
	Dto_SetWalletConnectionStatusResult
} from "./wallet_connect_dto";

export class WalletConnectApi {
	private static instance: WalletConnectApi
	private constructor() {}

	// internal error
	public static async getWalletConnection(params: Dto_GetWalletConnection):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetWalletConnectionResult[] }, success: boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_WALLET_CONNECTS}`, params);
	}


	public static async setWalletConnectionStatus(pairId: string, params: Dto_SetWalletConnectionStatus):
		Promise<{code: string, msg: string, data: Dto_SetWalletConnectionStatusResult, success: boolean}> {
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_WALLET_CONNECTS_ID_STATUS(pairId)}`, params);
	}

	public static async getTransactionApprove(params:Dto_GetTransactionApprove):
	Promise<{code:string,msg:string,data:{ pagingInfo : PagingInfoResponse, list : Dto_GetTransactionApproveResult[] }, success : boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS}`,params);
	}

	public static async getTransactionDetail(txPairId:number):
	Promise<{code:string,msg:string,data:{ detail : Dto_GetTransactionDetail }, success : boolean}>{
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS_ID(txPairId)}`);
	}

	public static async setRefund(txPairId : number, params : Dto_setRefundAndResend):
	Promise<{code:string,msg:string,data: DataResponse, success : boolean}>{
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_TRANSACTIONS_ID_REFUND(txPairId)}`,params);
	}

	public static async setResend(txPairId : number, params : Dto_setRefundAndResend):
		Promise<{code:string,msg:string,data: Dto_setRefundAndResendResult, success : boolean}>{
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_TRANSACTIONS_ID_RESEND(txPairId)}`,params);
	}

	public static async setResendCombined(txPairId:string, params: Dto_setResendCombined):
		Promise<{code:string,msg:string,data: DataResponse, success: boolean}>{
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_TRANSACTIONS_PENDING_ID_RESEND(txPairId)}`,params);
		}

	public static async getPendingTotal( params:Dto_getPendingTotal):
	Promise<{code:string,msg:string,data: Dto_getPendingTotalResult, success : boolean}> {
        return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS_PENDING_SUMMARY}`,params);
    }

	public static async getPendingTransactions(params:Dto_getPendingTransactions):
	Promise<{code:string,msg:string,data: { pagingInfo : PagingInfoResponse, list :Dto_getPendingTransactionsResult[] }, success : boolean}> {
        return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS_PENDING}`,params);
    }



	public static async createWalletConnection(params:Dto_createWalletConnection):
	Promise<{code:string,msg:string,data: Dto_createWalletConnectionResult, success: boolean}> {
		return BaseHttp.post(`${ApiUrl.API_PRIVATE_WALLET_CONNECTS}`,params);
	}


	public static async setWalletConnectionBasic(pairId : string,params:Dto_SetWalletConnectionBasic):
	Promise<{code:string,msg:string,data: Dto_SetWalletConnectionBasicResult,success: boolean}> {
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_WALLET_CONNECTS_ID_BASIC(pairId)}`,params);
	}


	public static async setWalletConnectionFee(pairId : string, params : Dto_SetWalletConnectionFee):
	Promise<{code:string,msg:string,data: Dto_SetWalletConnectionFeeResult,success: boolean}> {
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_WALLET_CONNECTS_ID_FEE(pairId)}`,params);
	}

	public static async getWalletsByToken(tokenId:string):
	Promise<{code:string,msg:string,data: { pagingInfo : PagingInfoResponse, list :Dto_getWalletsByTokenResult[] },success: boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_TOKENS_ID_WALLETS(tokenId)}`);
	}

	public static async getUnrealizedFeeTotal(data : Dto_getUnralizedFeeTotal):
	Promise<{code:string,msg:string,data:Dto_getUnralizedFeeTotalResult,success: boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_TOTAL}`,data);
	}

	public static async setRealizeFee(data : Dto_setRealizeFee):
	Promise<{code:string,msg:string,data:DataResponse,success: boolean}> {
		return BaseHttp.put(`${ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_REALIZATION}`,data);
	}

	public static async getDownloadFeeListByFeeGroupId(txId:string):
	Promise<{code:string,msg:string,data:{pagingInfo : PagingInfoResponse,list:Dto_GetDownloadFeeListByFeeGroupId},success: boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_HISTORIES_TRANSACTIONID_DOWNLOAD(txId)}`);
	}
}