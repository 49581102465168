import React, {useRef} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";

export interface ILoadingDimmerComponent {
	loading: boolean
	setLoading: any
}

export default function CLoadingDimmer(props: ILoadingDimmerComponent) {
	return <Modal
		size={"sm"}
		show={props.loading}
		onHide={props.setLoading ? props.setLoading : ()=>props.setLoading(false)}
		backdrop="static"
		keyboard={false}
	>
		<div style={{ height: "60px"}} className={"d-flex justify-content-center align-items-center py-3"}>
			<Spinner
				animation="border" className={"me-4"}></Spinner>
			<h5 className={"mt-2"}>Now Loading..</h5>
		</div>
	</Modal>
}