import {Button, Container, Row} from "react-bootstrap";
import CTextField from "../../../components/CTextField";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import {IDropdown_Dto_GetTokensByWalletResult} from "../../../../model/custom_dropdown";
import {useEffect, useState} from "react";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {Dto_GetWalletsManagementResult} from "../../../../api/wallet/wallet_dto";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK} from "../../../../constants/value";
import {useNavigate} from "react-router-dom";

export interface IWalletManagementEditPageBasicInfo {
	item: Dto_GetWalletsManagementResult
	loading: boolean, setLoading: Function
	walletAddress: string, setWalletAddress: Function
	walletName: string, setWalletName: Function
	selectedMainTokenDropdown?: IDropdown_Dto_GetTokensByWalletResult,
	setSelectedMainTokenDropdown: Function
	mainTokenDropdownList: IDropdown_Dto_GetTokensByWalletResult[],
	setMainTokenDropdownList: Function
	disabled: boolean
}

export default function WalletManagementEditPageBasicInfo(props: IWalletManagementEditPageBasicInfo) {
	const navigate = useNavigate();
	const [showTfa, setShowTfa] = useState(false);

	const onMainTokenSelected = (item: IDropdown_Dto_GetTokensByWalletResult) => {
		props.setSelectedMainTokenDropdown(item);
	}

	useEffect(() => {
		init();
	}, [])

	async function init() {
		const tokenDropdownList = await getTokensByWallet(props.item);
		if(!tokenDropdownList) return;
		getBasicInfo(props.item, tokenDropdownList);
	}

	async function getTokensByWallet(wallet: Dto_GetWalletsManagementResult) {
		if(!wallet?.walletId) return;
		const res = await WalletApi.getTokensByWallet(wallet?.walletId, {
			nativeYn: false,
		});
		const newDropdownList: IDropdown_Dto_GetTokensByWalletResult[] = [];
		for(const item of res.data.list) {
			newDropdownList.push({
				name: item.tokenName,
				value: item.tokenId.toString(),
				data: item
			})
		}
		props.setMainTokenDropdownList(newDropdownList);
		return newDropdownList;
	}

	async function getBasicInfo(wallet: Dto_GetWalletsManagementResult, tokenDropdownList: IDropdown_Dto_GetTokensByWalletResult[]) {
		if(!props.item) return;
		const res = await WalletApi.getTokensByWallet(wallet?.walletId, {
			nativeYn: true
		})
		if(!res) return null;
		WalletApi.getWalletBasic(props.item.walletId).then((res) => {
			if(res) {
				props.setWalletName(res.data.detail.walletName);
				props.setWalletAddress(res.data.detail.address);

				const token = tokenDropdownList.find(t => t.data.tokenId === res.data.detail.mainTokenId);
				if(token) {
					props.setSelectedMainTokenDropdown(token)
				}
			}
		}).catch((error) => {

		}).finally(() => {

		});
	}

	function onReset() {
		props.setWalletName('');
		props.setSelectedMainTokenDropdown(undefined);
	}

	async function onSave(otpCode: string) {
		if(props.loading) return;
		if(!props.selectedMainTokenDropdown || !props.walletName || props.walletName === '') {
			alert(MESSAGE_FILL_THE_BLANK);
			return;
		}
		props.setLoading(true);
		WalletApi.setWalletBasic(props.item.walletId, {
			mainTokenId: props.selectedMainTokenDropdown.data.tokenId,
			walletName: props.walletName,
			otpCode: otpCode,
		}).then((res) => {
			if(res) {
				alert(MESSAGE_CHANGES_SAVED);
				navigate(-1);
			}
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		})
	}

	return <Container>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={(otpCode: string) => {
			onSave(otpCode);
		}}/>
		<Row className={"d-flex justify-content-center mb-5"}>
			<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
				<Row className={"h4 mb-5"}>Basic Info</Row>
				<div className={"d-flex justify-content-between align-items-center mb-4"}>
					<div>Wallet Address</div>
					<div style={{ width: "360px"}}>{props.walletAddress}</div>
				</div>
				<div className={"d-flex justify-content-between align-items-center mb-4"}>
					<div>Wallet Name</div>
					<CTextField id={'wallet-management-edit-basic-info-wallet-address-text'} text={props.walletName} setText={props.setWalletName} width={360}></CTextField>
				</div>
				<div className={"d-flex justify-content-between align-items-center mb-5"}>
					<div>Main Token</div>
					<CDropdown
						id={'wallet-management-edit-basic-info-main-token'}
						width={360}
						data={props.mainTokenDropdownList}
						selected={props.selectedMainTokenDropdown}
						onSelected={onMainTokenSelected}
					></CDropdown>
				</div>
				<div className={"d-flex justify-content-end"}>
					<Button size={"lg"} variant={"dark"} onClick={() => onReset()} className={"me-2"}>Reset</Button>
					<Button size={"lg"} variant={"dark"} disabled={props.disabled} onClick={() => {
						setShowTfa(true);
					}}>Save</Button>
				</div>
			</Row>
		</Row>
	</Container>
}