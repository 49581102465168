import {Breadcrumb} from "react-bootstrap";

export interface IBreadCrumbComponent {
	first: string
	firstUrl: string
	second?: string
	secondUrl?: string
	third?: string
	thirdUrl?: string
	fourth?: string
	fourthUrl?: string
}

export default function CBreadCrumb(props: IBreadCrumbComponent) {
	return <div className="mt-4">
		<Breadcrumb>
			<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
			{props.first ? <Breadcrumb.Item href={props.firstUrl ?? "/"} active={props.firstUrl === '' || props.firstUrl === '/'}>{props.first}</Breadcrumb.Item> : <></>}
			{props.second ? <Breadcrumb.Item href={props.secondUrl ?? "/"} active={props.secondUrl === '' || props.secondUrl === '/'}>{props.second}</Breadcrumb.Item> : <></>}
			{props.third ? <Breadcrumb.Item href={props.thirdUrl ?? "/"} active={props.thirdUrl === '' || props.thirdUrl === '/'}>{props.third}</Breadcrumb.Item> : <></>}
			{props.fourth ? <Breadcrumb.Item href={props.fourthUrl ?? "/"} active={props.fourthUrl === '' || props.fourthUrl === '/'}>{props.fourth}</Breadcrumb.Item> : <></>}
		</Breadcrumb>
	</div>
}