import {Button, Container, Row} from "react-bootstrap";
import CTextField from "../../components/CTextField";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import {useState,useEffect} from "react";
import {IDropdown_Dto_GetTokenByNetworkAndWalletResult, IDropdown_Dto_GetTokenByNetworkResult, IDropdown_Dto_GetTokensResult, IDropdown_Dto_GetWalletByNetworkIdResult, IDropdown_Dto_GetWalletByNetworkResult} from "../../../model/custom_dropdown";
import {useLocation} from "react-router-dom";
import CVerifyTFAModal from "pages/components/modal/CVerifyTFAModal";
import { NetworkApi } from "api/network/NetworkApi";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import { IDropdown_Dto_GetMainnetResult } from "model/custom_dropdown";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK, MESSAGE_INVALID_VALUE} from "constants/value";
import { getCurrencyUnitByTokenName } from "utils/util";


export default function ConnectSettingsPageCreateConnectPair() {
	const location = useLocation();
	const [showTfa,setShowTfa] = useState(false);
	const [loading,setLoading] = useState(false);
	const isEditPage = true;

	// settings 1
	const [selectInMainnet, setSelectInMainnet] = useState<IDropdown>();
	const [selectOutMainnet, setSelectOutMainnet] = useState<IDropdown>();
	const [mainnetDropdownList, setMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [outMainnetDropdownList, setOutMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [selectInWallet, setSelectInWallet] = useState<IDropdown>();
	const [selectOutWallet, setSelectOutWallet] = useState<IDropdown>();
	const [InWalletDropdownList, setInWalletDropdownList] = useState<IDropdown_Dto_GetWalletByNetworkIdResult[]>([]);
	const [OutWalletDropdownList, setOutWalletDropdownList] = useState<IDropdown_Dto_GetWalletByNetworkIdResult[]>([]);
	const [selectInToken, setSelectInToken] = useState<IDropdown>();
	const [selectOutToken, setSelectOutToken] = useState<IDropdown>();
	const [InTokenDropdownList, setInTokenDropdownList] = useState<IDropdown_Dto_GetTokenByNetworkAndWalletResult[]>([]);
	const [OutTokenDropdownList, setOutTokenDropdownList] = useState<IDropdown_Dto_GetTokenByNetworkAndWalletResult[]>([]);
	// settings 2
	const [gasCoverageFee, setGasCoverageFee] = useState("");
	const [conversionFee, setConversionFee] = useState("");
	const [maxLimit, setMaxLimit] = useState("");
	const [minLimit, setMinLimit] = useState("");
	useEffect(() => {
		fetchMainnetList();
	},[])
	async function fetchMainnetList() {
		if (loading) return;
		setLoading(true);

		try {
			const res = await NetworkApi.getMainnet();
			const newMainnetDropdownList: IDropdown_Dto_GetMainnetResult[] = res.data.list.map((item) => ({
				name: item.name,
				value: item.id.toString(),
				data: item,
			}));
			setMainnetDropdownList(newMainnetDropdownList);
			setOutMainnetDropdownList(newMainnetDropdownList);
		} catch (e) {

		} finally {
			setLoading(false);
		}
	}

	async function fetchWalletListByMainnet(mainnetId:number) {
		if (loading) return;
		setLoading(true);
		const res = await NetworkApi.getWalletByMainnet(mainnetId.toString(), {
			externalYn: false
		});
		setLoading(false)
		if(res && res.success) return res.data.list;
		else return []
	}

	async function fetchTokenByMainnetAndWallet(mainnetId:string,walletId:string) {
		if(loading) return;
		setLoading(true);
		const res = await NetworkApi.getTokenByMainnetAndWallet(mainnetId, walletId, {
			nativeYn: false
		});
		setLoading(false)
		if(res && res.success) return res.data.list;
		else return []
	}
	async function createWalletConnection(otpCode:string){
		if(loading) return;

		const gasCoverageFeeNum = parseFloat(gasCoverageFee);
		const conversionFeeNum = parseFloat(conversionFee);
		const maxLimitNum = parseFloat(maxLimit);
		const minLimitNum = parseFloat(minLimit);

		if(minLimitNum > maxLimitNum) {
			alert(MESSAGE_INVALID_VALUE);
			return;
		}
		if(gasCoverageFeeNum > minLimitNum) {
			alert(MESSAGE_INVALID_VALUE);
			return;
		}

		setLoading(true);

		if(!selectInWallet?.value||!selectOutWallet?.value||!selectInToken?.value||!selectOutToken?.value) return
		try{
			await WalletConnectApi.createWalletConnection({
				inWalletId: parseInt(selectInWallet.value),
				outWalletId : parseInt(selectOutWallet.value),
				inTokenId : parseInt(selectInToken.value),
				outTokenId : parseInt(selectOutToken.value),
				gasCoverageFee : gasCoverageFeeNum,
				conversionFee : conversionFeeNum,
				maxLimit : maxLimitNum,
				minLimit : minLimitNum,
				otpCode,
			}).then(res=>{
				if(res && res.success){
					alert(MESSAGE_CHANGES_SAVED)
					window.location.reload();
				}else{
					alert(MESSAGE_CHANGES_FAILED)
				}
			})
		}catch(e){

		}finally{
			setLoading(false);
		}
	}
	const onReset = () => {
		setSelectInMainnet(undefined);
		setSelectOutMainnet(undefined);
		setInTokenDropdownList([]);
		setOutTokenDropdownList([]);
		setInWalletDropdownList([]);
		setOutWalletDropdownList([]);
		setConversionFee("");
		setGasCoverageFee("");
		setMaxLimit("");
		setMinLimit("");
	}
	return <Container>
		<CBreadCrumb
			first={"Connect Settings"} firstUrl={"/wallet-connect/settings"}
			second={"Create Connect Pair"} secondUrl={"/wallet-connect/settings/create"}
		/>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={(otpCode:string)=>{
			createWalletConnection(otpCode);

		}}/>
		<CPageTitle title={"Create Connect Pair"} subtitle={""}/>

		{/* setting 1 */}
		<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
			<Row className={"h4 mb-5"}>Pair Settings 1</Row>

			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div><span style={{fontSize:"1.3rem",fontWeight:"700"}}>IN</span></div>
			</div>
			{/* In */}
			{/* Select Mainnet */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Mainnet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-main-token-dropdown'}
					width={360}
					data={mainnetDropdownList}
					selected={selectInMainnet}
					onSelected={
						(selected:IDropdown)=>{
							if(selected.name!==selectOutMainnet?.name){
								setSelectInMainnet(selected);
							}

							if(selectInMainnet!==selected){
								setSelectInWallet(undefined);
								setSelectInToken(undefined);
								setInTokenDropdownList([]);
								setInWalletDropdownList([]);

							}

							if(!selected.value) return;
							fetchWalletListByMainnet(parseInt(selected.value)).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
										name: item.walletName,
										value: item.walletId.toString(),
										data: item,
									}));
									setInWalletDropdownList(newWalletDropdownList);
								}
							})
						}
					}
				></CDropdown>
			</div>
			{/* Select In Wallet */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select In Wallet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-wallet-dropdown'}
					width={360}
					data={InWalletDropdownList}
					selected={selectInWallet}
					onSelected={(selected:IDropdown)=>{
						setSelectInWallet(selected);
						if(selectInWallet!==selected){
							setSelectInToken(undefined);
						}
						if(selectInMainnet?.value !== undefined && selected.value !== undefined)
							fetchTokenByMainnetAndWallet(selectInMainnet.value,selected.value).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
										name: item.tokenName,
										value: item.tokenId.toString(),
										data: item,
									}));
									setInTokenDropdownList(newWalletDropdownList);
								}
							})
					}}
				></CDropdown>
			</div>
			{/* Select In Token */}
			<div className={"d-flex justify-content-between align-items-center mb-5"}>
				<div>Select In Token</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={InTokenDropdownList}
					selected={selectInToken}
					onSelected={setSelectInToken}
				></CDropdown>
			</div>

			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div><span style={{fontSize:"1.3rem",fontWeight:"700"}}>OUT</span></div>
			</div>
			{/* Out */}
			{/* Select Out Mainnet*/}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Mainnet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={outMainnetDropdownList}
					selected={selectOutMainnet}
					onSelected={
						(selected:IDropdown)=>{
							if(selected.name!==selectInMainnet?.name){
								setSelectOutMainnet(selected);
							}
							if(selectOutMainnet!==selected){
								setSelectOutWallet(undefined);
								setSelectOutToken(undefined);
								setOutTokenDropdownList([]);
								setOutWalletDropdownList([]);
							}

							if(!selected.value) return;
							fetchWalletListByMainnet(parseInt(selected.value)).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
										name: item.walletName,
										value: item.walletId.toString(),
										data: item,
									}));
									setOutWalletDropdownList(newWalletDropdownList);
								}
							})
						}
					}
				></CDropdown>
			</div>
			{/* Select Out Wallet*/}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Out Wallet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={OutWalletDropdownList}
					selected={selectOutWallet}
					onSelected={
						(selected:IDropdown)=>{
							setSelectOutWallet(selected)
							if(selectOutWallet!==selected){
								setSelectOutToken(undefined);
							}
							if(selectOutMainnet?.value !== undefined && selected.value !== undefined)
								fetchTokenByMainnetAndWallet(selectOutMainnet.value,selected.value).then((res)=>{
									if(res){
										const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
											name: item.tokenName,
											value: item.tokenId.toString(),
											data: item,
										}));
										setOutTokenDropdownList(newWalletDropdownList);
									}
								})
						}}
				></CDropdown>
			</div>
			{/* Select Out Token*/}
			<div className={"d-flex justify-content-between align-items-center mb-5"}>
				<div>Select Out Token</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={OutTokenDropdownList}
					selected={selectOutToken}
					onSelected={setSelectOutToken}
				></CDropdown>
			</div>

			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div><span style={{fontSize:"1.3rem",fontWeight:"700"}}>FEE</span></div>
			</div>

			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set Gas Coverage Fee</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-gas-coverage-fee-text'}
				            text={gasCoverageFee} setText={setGasCoverageFee} width={360} unit={selectOutToken?.name?selectOutToken.name:"-"}/>
			</div>
			{/* Set Coverage Fee */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set Conversion Fee</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-conversion-fee-text'} text={conversionFee} setText={setConversionFee} width={360} unit={"%"}/>
			</div>
			{/* Set (Max) Limit */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set (Max) Limit</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-max-limit-text'} text={maxLimit} setText={setMaxLimit} width={360} unit={selectInToken?.name?selectInToken.name:"-"}/>
			</div>
			{/* Set (Min) Limit */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set (Min) Limit</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-min-limit-text'} text={minLimit} setText={setMinLimit} width={360} unit={selectInToken?.name?selectInToken.name:"-"}/>
			</div>
			<div className={"d-flex mt-4 justify-content-end"}>
				<Button size={"lg"} variant={"secondary"} className={"me-2"} onClick={onReset} >Reset</Button>
				<Button size={"lg"} variant={"dark"} onClick={()=>{
					try {
						const gasCoverageFeeNum = parseFloat(gasCoverageFee);
						const conversionFeeNum = parseFloat(conversionFee);
						const maxLimitNum = parseFloat(maxLimit);
						const minLimitNum = parseFloat(minLimit);

						if(minLimitNum > maxLimitNum) {
							alert(MESSAGE_INVALID_VALUE);
							return;
						}
						if(gasCoverageFeeNum > minLimitNum) {
							alert(MESSAGE_INVALID_VALUE);
							return;
						}
					} catch(e) {}

					if(gasCoverageFee !== '' && gasCoverageFee !== undefined &&
						conversionFee !== '' && conversionFee !== undefined &&
						maxLimit !== '' && maxLimit !== undefined &&
						minLimit !== '' && minLimit !== undefined) {
						setShowTfa(true)
					}
					else{
						alert(MESSAGE_FILL_THE_BLANK);
					}
				}}>Save</Button>
			</div>
		</Row>
	</Container>
}