import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_DownloadLogList, Dto_GetLogList, Dto_GetLogListResult} from "./log_dto";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";

export class LogApi {
	private static instance: LogApi
	private constructor() {}

	public static async getLogList(params: Dto_GetLogList):
		Promise<{code: string, msg: string, data: { list: Dto_GetLogListResult[], pagingInfo: PagingInfoResponse }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_LOGS, params);
	}

	public static async downloadLogList(params: Dto_DownloadLogList):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_LOGS_DOWNLOAD, params);
	}
}