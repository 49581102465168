import {Button, Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../components/CPageTitle";
import WalletManagementPageListHeader from "./WalletManagementPageListHeader";
import CListPage from "../../components/CListPage";
import WalletManagementPageListItem from "./WalletManagementPageListItem";
import WalletManagementPageFilter from "./WalletManagementPageFilter";
import CBreadCrumb from "../../components/CBreadCrumb";
import {useNavigate} from "react-router-dom";
import CSimplePagination from "../../components/CSimplePagination";
import {useEffect, useState} from "react";
import {WalletApi} from "../../../api/wallet/WalletApi";
import {FETCH_SIZE} from "../../../constants/value";
import {Dto_GetWalletsManagementResult} from "../../../api/wallet/wallet_dto";
import CTotalCount from "../../components/CTotalCount";
import {IDropdown} from "../../components/CDropdown";
import {Dto_GetMainnetResult} from "../../../api/network/network_dto";
import {NetworkApi} from "../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult} from "../../../model/custom_dropdown";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import {UserApi} from "../../../api/user/UserApi";

export default function WalletManagementPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<Dto_GetWalletsManagementResult[]>([]);
	const [index, setIndex] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [totalCount, setTotalCount] = useState(0);

	const [mainnetDropdownList, setMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [mainnet, setMainnet] = useState<IDropdown_Dto_GetMainnetResult>({
		name: 'All',
		value: '',
	});
	const [signatureType, setSignatureType] = useState<IDropdown>({
		name: 'All',
		value: '',
	});
	const [status, setStatus] = useState<IDropdown>({
		name: 'All',
		value: '',
	});
	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});

	// access
	const [accessCreateWallet, setAccessCreateWallet] = useState(false)
	const [accessUpdateWalletBasic, setAccessUpdateWalletBasic] = useState(false)
	const [accessUpdateWalletAlert, setAccessUpdateWalletAlert] = useState(false)
	const [accessUpdateWalletToken, setAccessUpdateWalletToken] = useState(false)
	const [accessUpdateNetworkGasFee, setAccessUpdateNetworkGasFee] = useState(false)
	const [accessDisableWallet, setAccessDisableWallet] = useState(false)

	const generateItem = (value: Dto_GetWalletsManagementResult) => {
		return <WalletManagementPageListItem key={JSON.stringify(value)} item={value}
		                                     accessUpdateWalletBasic={accessUpdateWalletBasic}
		                                     accessUpdateWalletAlert={accessUpdateWalletAlert}
		                                     accessUpdateWalletToken={accessUpdateWalletToken}
		                                     accessDisableWallet={accessDisableWallet}/>
	}

	useEffect(() => {
		init();
	}, [])

	async function init() {
		if(loading) return;
		setLoading(true);
		const promises = [
			fetchMainnetList(),
			fetchWalletManagementList(1),
			getAccess()
		]
		await Promise.all(promises).finally(() => {
			setLoading(false);
		});
	}

	async function fetchMainnetList() {
		NetworkApi.getMainnet({pageNumber: 1, pageSize: 20,}).then((res) => {
			const newDropdownList: IDropdown_Dto_GetMainnetResult[] = [{
				name: 'All',
				value: undefined,
			},];
			if(res) {
				const mainnetList: Dto_GetMainnetResult[] = res.data.list;
				for(let i=0; i<mainnetList.length; i++) {
					newDropdownList.push({
						name: mainnetList[i].name,
						value: mainnetList[i].id.toString(),
						data: mainnetList[i],
					})
				}
				setMainnetDropdownList(newDropdownList);
			}
		}).catch((error) => {

		}).finally(() => {
		});
	}

	async function fetchWalletManagementList(page: number, search?: string) {
		setLoading(true);
		WalletApi.getWallets_management({
			searchFilter: search,
			networkId: mainnet.data?.id.toString(),
			status: status.value,
			type: signatureType.value,
			pageNumber: page,
			pageSize: listDisplay.value ? parseInt(listDisplay.value) : 25,
		}).then((result) => {
			if(result && result.data.list) {
				try {
					if (listDisplay.value) setPageSize(parseInt(listDisplay.value))
					setList(result.data.list);
					setTotalCount(result.data.pagingInfo.totalCount);
				} catch(e) {
					if (listDisplay.value) setPageSize(parseInt(listDisplay.value))
					setList([]);
					setTotalCount(0);
				}
			}
		}).catch((e) => {
			if (listDisplay.value) setPageSize(parseInt(listDisplay.value))
			setList([]);
			setTotalCount(0);
		}).finally(() => {
			setLoading(false);
		})
	}

	async function getAccess() {
		const promises = [
			UserApi.getUserAccess("CREATE_WALLET"),
			UserApi.getUserAccess("UPDATE_WALLET_BASIC"),
			UserApi.getUserAccess("UPDATE_WALLET_ALERT"),
			UserApi.getUserAccess("UPDATE_WALLET_TOKEN"),
			UserApi.getUserAccess("UPDATE_NETWORK_GAS_FEE"),
			UserApi.getUserAccess("DISABLE_WALLET"),
		];
		const access = await Promise.all(promises);
		setAccessCreateWallet(access[0])
		setAccessUpdateWalletBasic(access[1])
		setAccessUpdateWalletAlert(access[2])
		setAccessUpdateWalletToken(access[3])
		setAccessUpdateNetworkGasFee(access[4])
		setAccessDisableWallet(access[5]);
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Wallet Management"} firstUrl={"/wallet/management"}/>
		<CPageTitle title={"Wallet Management"} subtitle={""}/>
		<Row className="mb-3 d-flex justify-content-between">
			<Col xs={2}>
				<CTotalCount totalCount={totalCount}/>
			</Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<Button className={"me-2"} variant={"dark"} onClick={() => {
					navigate('/wallet/management/fee');
				}} disabled={false}>Set Gas Fee</Button>
				<Button variant={"dark"}
						onClick={() => {
					navigate('/wallet/management/add', {
						state: {
							accessCreateWallet
						},
					})
				}} disabled={!accessCreateWallet}>Add New Wallet</Button>
			</Col>
		</Row>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchWalletManagementList(page)}
			pageSize={pageSize}
			totalCount={totalCount}
		/>
		<WalletManagementPageFilter
			mainnetDropdownList={mainnetDropdownList}
			mainnet={mainnet} setMainnet={setMainnet}
			signatureType={signatureType} setSignatureType={setSignatureType}
			status={status} setStatus={setStatus}
			listDisplay={listDisplay} setListDisplay={setListDisplay}
			onSearch={(search: string) => {
				setIndex(1)
				fetchWalletManagementList(1, search);
			}} />
		<WalletManagementPageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}