import 'styles/sidebar.css';
import 'styles/layout.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import DashboardPageColdWalletList from "./DashboardPageColdWalletList";
import DashboardPageHotWalletList from "./DashboardPageHotWalletList";
import CPageTitle from "../components/CPageTitle";
import {useEffect, useState} from "react";
import {checkLogin} from "../../utils/session";
import {useNavigate} from "react-router-dom";
import {DashboardApi} from "../../api/dashboard/DashboardApi";
import {Dto_GetWalletBalancesResult} from "../../api/dashboard/dashboard_dto";

function DashboardPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [coldLoading, setColdLoading] = useState(false);
    const [hotLoading, setHotLoading] = useState(false);
    const [coldWalletList, setColdWalletList] = useState<Dto_GetWalletBalancesResult[]>([]);
    const [hotWalletList, setHotWalletList] = useState<Dto_GetWalletBalancesResult[]>([]);

    useEffect(() => {
        checkForLoggedIn();
        fetchDashboard()
    }, [])

    async function fetchDashboard() {
        const promisis = [
            fetchWalletList(true),
            fetchWalletList(false)
        ];
        if(coldLoading || hotLoading) return;
        await Promise.all(promisis);
    }

    async function fetchWalletList(external: boolean) {
        if(external) {
            if(coldLoading) return;
            setColdLoading(true);
        } else {
            if(hotLoading) return;
            setHotLoading(true);
        }
        DashboardApi.getWalletBalances({
            external: external
        }).then((result) => {
            if(result && result.data.list) {
                if (external) setColdWalletList(result.data.list);
                else setHotWalletList(result.data.list);
            } else {
                if(external) setColdWalletList([]);
                else setHotWalletList([]);
            }
        }).catch((error) => {
            if(external) setColdWalletList([]);
            else setHotWalletList([]);
        }).finally(() => {
            if(external) setColdLoading(false);
            else setHotLoading(false);
        })
    }

    async function checkForLoggedIn() {
        const result = await checkLogin();
        if(!result) navigate('/login');
    }

    const onDataCenterClicked = () => {
        navigate('/dashboard/datacenter');
    }

  return <Container>
      <Row>
          <Col>
              <CPageTitle title={"Dashboard"} subtitle={""}/>
          </Col>
          <Col className={'d-flex justify-content-end align-items-end'}>
              <Button className={'mb-4'} variant={'dark'} onClick={onDataCenterClicked}>Data Center</Button>
          </Col>
      </Row>
        <div className={"pb-5"}>
            <DashboardPageColdWalletList
                loading={coldLoading} setLoading={setColdLoading}
                offlineWalletList={coldWalletList} setOfflineWalletList={setColdWalletList}/>
            <DashboardPageHotWalletList
                loading={hotLoading} setLoading={setHotLoading}
                onlineWalletList={hotWalletList} setOnlineWalletList={setHotWalletList}/>
        </div>
    </Container>;
}

export default DashboardPage;
