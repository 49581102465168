import {Button, Container, Row} from "react-bootstrap";
import CTextField, {ITextFieldType} from "../../../components/CTextField";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import {Dto_GetWalletAdminResult, Dto_GetWalletsManagementResult} from "../../../../api/wallet/wallet_dto";
import {IDropdown_Dto_GetAdminListResult, IDropdown_Dto_GetWalletAdminResult} from "../../../../model/custom_dropdown";
import {useEffect, useState} from "react";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {useNavigate} from "react-router-dom";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK} from "../../../../constants/value";

export interface IWalletManagementEditPageAlert {
	item: Dto_GetWalletsManagementResult
	loading: boolean, setLoading: Function
	minimumBalanceAlert: string, setMinimumBalanceAlert: Function
	minimumFeeBalanceAlert: string, setMinimumFeeBalanceAlert: Function
	alertGenerated: IDropdown, setAlertGenerated: Function
	selectedAlertAdminList: IDropdown_Dto_GetWalletAdminResult[], setSelectedAlertAdminList: Function
	alertAdminList: IDropdown_Dto_GetWalletAdminResult[], setAlertAdminList: Function
	disabled: boolean
}

export default function WalletManagementEditPageAlert(props: IWalletManagementEditPageAlert) {
	const navigate = useNavigate();
	const [showTfa, setShowTfa] = useState(false);

	useEffect(() => {
		init();
	}, [])

	async function init() {
		const walletList = await getWalletAdmin();
		getAlert(walletList);
	}

	async function getWalletAdmin() {
		const adminRes = await WalletApi.getWalletAdminResult();
		return adminRes.data.list;
	}

	async function getAlert(walletList: Dto_GetWalletAdminResult[]) {
		if(!props.item) return;
		const res = await WalletApi.getApproveSentTo(props.item.walletId);
		props.setAlertGenerated({
			name: res.data.detail.alertYn ? "Yes" : "No",
			value: res.data.detail.alertYn ? "true" : "false",
		});
		props.setMinimumBalanceAlert(res.data.detail.limitAlert);
		props.setMinimumFeeBalanceAlert(res.data.detail.feeLimitAlert);

		const newDropdownList: IDropdown_Dto_GetWalletAdminResult[] = [];
		for(const adminUser of walletList) {
			const dropdown: IDropdown_Dto_GetWalletAdminResult = {
				name: adminUser.userName,
				value: adminUser.userId.toString(),
				data: adminUser,
			}
			newDropdownList.push(dropdown);
		}
		props.setAlertAdminList(newDropdownList);

		const newAdminList: IDropdown_Dto_GetWalletAdminResult[] = [];
		for(const userId of res.data.detail.walletAdminIds) {
			const matchingUser = walletList.find(user => user.userId === userId)
			if(matchingUser) {
				newAdminList.push({
					name: matchingUser.userName,
					value: matchingUser.userId.toString(),
					data: matchingUser
				});
			}
		}
		props.setSelectedAlertAdminList(newAdminList);
	}

	const alertGeneratedData = [
		{
			name: "Yes",
			value: "true",
		},
		{
			name: "No",
			value: "false",
		},
	]

	function alertAdminUserContainer() {
		return <div className={"d-flex flex-row mt-4"}>
			{props.selectedAlertAdminList.map((item: IDropdown_Dto_GetWalletAdminResult) => {
				return alertAdminUserItem(item);
			})}
		</div>
	};

	function alertAdminUserItem(item: IDropdown_Dto_GetWalletAdminResult) {
		return <div key={item.data.userId} style={{ display: "flex", flexDirection: "row", padding: "8px",
			backgroundColor: "#0A99FF", borderRadius: "8px", marginRight: "8px", cursor: "pointer" }}
		            onClick={() => {
			            props.setSelectedAlertAdminList([...props.selectedAlertAdminList.filter((i) =>
				            item.data.userId !== i.data.userId).sort((a, b) => a.data.userId - b.data.userId)])
		            }}
		>
			<div style={{ color: "white", marginRight: "8px" }}>{item.data.userName}</div>
			<div style={{ color: "white" }}>X</div>
		</div>
	}

	function onReset() {
		props.setAlertGenerated({
			name: "Yes",
			value: "true",
		},);
		props.setMinimumBalanceAlert('');
		props.setMinimumFeeBalanceAlert('');
		props.setSelectedAlertAdminList([]);
	}

	async function onSave(otpCode: string) {
		if(props.loading) return;

		if(props.alertGenerated.value === 'true' && (
			props.minimumFeeBalanceAlert === '' ||
			props.minimumBalanceAlert === '' ||
				props.selectedAlertAdminList.length === 0
		)) {
			alert(MESSAGE_FILL_THE_BLANK);
			return;
		}
		props.setLoading(true);
		const newAdminIdList: number[] = [];
		for(const admin of props.selectedAlertAdminList) {
			newAdminIdList.push(admin.data.userId);
		}
		WalletApi.setApproveSentTo(props.item.walletId, {
			alertYn: props.alertGenerated.value === 'true',
			limitAlert: props.minimumBalanceAlert === '' ? undefined : parseFloat(props.minimumBalanceAlert),
			feeLimitAlert: props.minimumFeeBalanceAlert === '' ? undefined : parseFloat(props.minimumFeeBalanceAlert),
			walletAdminIds: newAdminIdList,
			otpCode: otpCode,
		}).then((res) => {
			if(res) {
				alert(MESSAGE_CHANGES_SAVED);
				navigate(-1);
			}
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		})
	}

	return <Container>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={(otpCode: string) => {
			onSave(otpCode);
		}}/>
		<Row className={"d-flex justify-content-center mb-5"}>
		<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
			<Row className={"h4 mb-5"}>Alerts</Row>
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Minimum Balance Alert</div>
				<CTextField
					id={'wallet-management-edit-alert-minimum-balance-alert'}
					type={ITextFieldType.number}
					text={props.minimumBalanceAlert.toString()} setText={props.setMinimumBalanceAlert}
					width={360}/>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Minimum Fee Balance Alert</div>
				<CTextField
					id={'wallet-management-edit-alert-minimum-fee-balance-alert'}
					text={props.minimumFeeBalanceAlert.toString()} setText={props.setMinimumFeeBalanceAlert}
					width={360}/>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Alert Generated</div>
				<CDropdown
					id={'wallet-management-edit-alert-alert-generated'}
					width={360}
					data={alertGeneratedData}
					selected={props.alertGenerated}
					onSelected={(item: any) => {
						props.setAlertGenerated(item);
					}}
				></CDropdown>
			</div>
			<div className={"d-flex justify-content-between align-items-center"}>
				<div>Alert Sent to</div>
				<CDropdown
					id={'wallet-management-edit-alert-alert-sent-to'}
					width={360}
					data={props.alertAdminList}
					onSelected={(item: IDropdown_Dto_GetAdminListResult) => {
						if(props.selectedAlertAdminList.filter((i) => item.data.userId === i.data.userId).length > 0) return;
						props.setSelectedAlertAdminList([...props.selectedAlertAdminList, item].sort((a, b) => a.data.userId - b.data.userId));
					}}
				></CDropdown>
			</div>
			{alertAdminUserContainer()}
			<div className={"d-flex justify-content-end mt-3"}>
				<Button size={"lg"} variant={"dark"} onClick={() => onReset()} className={"me-2"}>Reset</Button>
				<Button size={"lg"} variant={"dark"} disabled={props.disabled} onClick={() => {
					setShowTfa(true);
				}}>Save</Button>
			</div>
		</Row>
		</Row>
	</Container>
}