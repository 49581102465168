import {Button, Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import CTextField from "../../../components/CTextField";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult} from "../../../../model/custom_dropdown";
import {timestampToDate} from "../../../../utils/util";

export interface IWalletManagementSetGasFeePageDynamicNetworkFee {
	selectedMainnet?: IDropdown_Dto_GetMainnetResult
	proposedDynamicNetworkFee: string, setProposedDynamicNetworkFee: Function
	gasPrice: string, setGasPrice: Function
}

export default function WalletManagementSetGasFeePageDynamicNetworkFee (
	props: IWalletManagementSetGasFeePageDynamicNetworkFee
) {
	const [date, setDate] = useState('');
	useEffect(() => {
		init();
	}, [props.selectedMainnet])

	async function init() {
		getNetworkGasPrice();
	}

	async function getNetworkGasPrice() {
		if(!props.selectedMainnet || !props.selectedMainnet.data) return;
		NetworkApi.getNetworkGasPrice(props.selectedMainnet.data?.id).then((res) => {
			props.setProposedDynamicNetworkFee(res.data.proposeGasPrice.toString());
			props.setGasPrice(res.data.proposeGasPrice.toString());
			setDate(timestampToDate((Math.floor(Date.now()/1000)).toString()).toLocaleString());
		})
	}

	return <Container>
		<Row className={"d-flex justify-content-center mb-5"}>
			<Row style={{ width: "768px" }} className={"p-5 bg-white rounded"}>
				<Row className={"h5 mb-5"}>
					<Col>Dynamic Network Fee</Col>
					<Col className={"d-flex flex-row justify-content-end"}>{props.proposedDynamicNetworkFee ? `Last updated at ${date}` : ''}</Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Propose</Col>
					<Col><CTextField id={'wallet-management-create-step1-wallet-name'} text={props.proposedDynamicNetworkFee}
						setText={() => {}} unit={"Gwei"} disabled={true}
					/></Col>
				</Row>
				<Row className={"mb-3"}>
					<Col className={"d-flex justify-content-end"}>
						<Button className={"me-2"} variant={"dark"} onClick={() => {
							window.open("https://etherscan.io/gastracker", '_blank', 'noopener, noreferrer');
						}} disabled={false}>View Feed</Button>
						<Button className={""} variant={"dark"} onClick={() => {
							init();
						}} disabled={false}>Update</Button>
					</Col>
				</Row>
			</Row>
		</Row>
	</Container>
}