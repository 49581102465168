import CPageTitle from "../../components/CPageTitle";
import {Button, Col, Container, Row} from "react-bootstrap";
import CBreadCrumb from "../../components/CBreadCrumb";
import CDropdown from "../../components/CDropdown";
import {SmartContractApi} from "../../../api/smart_contract/SmartContractApi";
import {useEffect, useState} from "react";
import {getFormattedNumber} from "../../../utils/util";

export interface IGPDMintPageStatusCard {
	loading: boolean
	setLoading: Function
}

export default function GPDMintPageStatusCard(props: IGPDMintPageStatusCard) {
	const [total, setTotal] = useState('');
	const [totalMinting, setTotalMinting] = useState('');
	const [totalBurning, setTotalBurning] = useState('');

	useEffect(() => {
		fetchGPDStatus();
	}, [])

	async function fetchGPDStatus() {
		if(props.loading) return;
		props.setLoading(true);
		SmartContractApi.getGPDStatus().then((res) => {
			setTotal(getFormattedNumber(res.data.total));
			setTotalMinting(getFormattedNumber(res.data.totalMinting));
			setTotalBurning(getFormattedNumber(res.data.totalBurning));
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		});
	}

	return <Container>
		<Row style={{ width: "768px" }} className={"p-5 bg-white mb-5 rounded"}>
			<Row style={{ width: "768px" }} className={"h4 mb-4"}>GPD Status</Row>
			<Col>
				<Row className={"d-flex justify-content-between p-4 border-top border-1 bg-white"}>
					<Col className={"d-flex justify-content-between"}>
						<h5>Current Total GPD</h5>
						<div>{`${total} GPD`}</div>
					</Col>
				</Row>
				<Row className={"p-4 mt-5 border-top border-1 bg-white"}>
					<Col><h5>Total GPD Minting</h5></Col>
					<Col><div className={"d-flex justify-content-end"}>{`${totalMinting} GPD`}</div></Col>
				</Row>
				<Row className={"p-4 bg-white"}>
					<Col><h5>Total GPD Burning</h5></Col>
					<Col><div className={"d-flex justify-content-end"}>{`${totalBurning} GPD`}</div></Col>
				</Row>
			</Col>
		</Row>
	</Container>
}