import {Button, Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {Dto_GetTransactionApproveResult} from "../../../api/wallet_connect/wallet_connect_dto";
import {weiToGwei} from "../../../utils/util";
import 'styles/listItem.css';
import CLocaleTimestamp from "../../components/CLocaleTimestamp";

export default function TransactionApprovePageListItem(props: Dto_GetTransactionApproveResult) {
	const navigate = useNavigate();
	const onItemClicked = () => {
		navigate(`/wallet-connect/approve/detail?pairId=${props.txPairId}`,{
			state : {
				 txPairId : props.txPairId,
				 status : props.status
			}
		})
	}
	return <Row 
		style={{ cursor: "pointer" } }
		className="p-3 bg-white mb-2 moveItem"
		onClick={onItemClicked}
	>
		<Col >{props.txPairId?.toString()}</Col>
		<Col>{props.depositDate? <CLocaleTimestamp timestamp={props.depositDate}/> : "-"}</Col>
		<Col>
			<Row>{`${props.depositAmount} ${props.depositTokenName}`}</Row>
			<Row>{`(${props.depositNetworkName})`}</Row>
		</Col>
		<Col>{'>>'}</Col>
		<Col>
			{props?.withdrawalDate? <CLocaleTimestamp timestamp={props.withdrawalDate}/> :"-"}
		</Col>
		<Col>
			<Row>{`${props.withdrawalAmount??"-"} ${props.withdrawalTokenName??"-"}`}</Row>
			<Row>{`(${props.withdrawalNetworkName??"-"})`}</Row>
		</Col>
		<Col>
			<Row>{`${props?.userNetworkFee?props.userNetworkFee:"-"} ${props?.withdrawalTokenName?props.withdrawalTokenName:"-"}`}</Row>
			<Row>{`${props?.userConversionFee?props.userConversionFee:"-"} ${props?.withdrawalTokenName?props.withdrawalTokenName:"-"}`}</Row>
			<Row>{`(${props?.withdrawalNetworkName?props.withdrawalNetworkName:"-"})`}</Row>
		</Col>
		<Col>
			<Row>
				{
					props.status==="UNRECOGNIZED"?
						<Col className={"mb-2"}><Button style={{ cursor: "default" }} disabled   variant="secondary">Unrecognized</Button></Col>
						: <Col className={"mb-2"}><Button style={{ cursor: "default" }} disabled   variant="secondary">Failed</Button></Col>
				}
			</Row>
		</Col>
	</Row>;
}