import {Button, Container, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CTextField from "../../../components/CTextField";
import {MESSAGE_FILL_THE_BLANK} from "../../../../constants/value";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {UserApi} from "../../../../api/user/UserApi";
import {Dto_GetAccountInfoResult} from "../../../../api/user/user_dto";

export interface IAccountSettingsEditAccountInfoModalComponent {
    show: boolean
    setShow: any
    onConfirm: any
    name: string, setName: Function
    mobile: string, setMobile: Function
    chatId: string, setChatId: Function
}

export default function AccountSettingsEditAccountInfoModal(props: IAccountSettingsEditAccountInfoModalComponent) {
    const [tfa, setTfa] = useState(false);

    return (
        <>
            <CVerifyTFAModal show={tfa} setShow={setTfa} onOk={(code: string) => {
                if(!props.name) return;
                UserApi.setAccountInfo({
                    mobile: props.mobile,
                    userName: props.name,
                    chatId: props.chatId,
                    otpCode: code,
                }).then((result) => {
                    if(result.success) {
                        if(props.onConfirm) props.onConfirm(true);
                    }
                    else {
                        alert(result.msg);
                        return;
                    }
                }).catch((e) => {
                    alert(e);
                    return;
                })
            }}/>
            <Modal
                show={props.show}
                onHide={()=>props.setShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton style={{ border: "none" }}>
                    <Modal.Title>Account Information Change</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ border: "none" }}>
                    <Container>
                        <form>
                            <CTextField id={'account-setting-edit-account-info-modal-name-tf'} text={props.name} setText={props.setName} title={"User Name"}/>
                            <div style={{ marginBottom: "16px" }}></div>
                            <CTextField id={'account-setting-edit-account-info-modal-mobile-tf'} text={props.mobile} setText={props.setMobile} title={"Mobile"}/>
                            <div style={{ marginBottom: "16px" }}></div>
                            <CTextField id={'account-setting-edit-account-info-modal-chat-id-tf'} text={props.chatId} setText={props.setChatId} title={"Chat ID (Telegram)"}/>
                        </form>
                    </Container>
                </Modal.Body>
                <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                    <Button
                        style={{ width: "360px"}}
                        variant="dark"
                        size={"lg"}
                        onClick={() => {
                            if(!props.name) {
                                alert(MESSAGE_FILL_THE_BLANK);
                                return;
                            }
                            if(props.setShow) props.setShow(false);
                            setTfa(true);
                        }}>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};