import {Col, Container, Row} from "react-bootstrap";
import WalletManagementEditPageAddTokenModal from "./WalletManagementEditPageAddTokenModal";
import {useEffect, useState} from "react";
import {Dto_GetTokensByWalletResult, Dto_GetWalletsManagementResult} from "../../../../api/wallet/wallet_dto";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {IDropdown_Dto_GetTokensByNetworkResult} from "../../../../model/custom_dropdown";
import {Dto_GetTokensByNetworkResult} from "../../../../api/network/network_dto";

export interface IWalletManagementEditPageAddToken {
	item: Dto_GetWalletsManagementResult
	loading: boolean, setLoading: Function
	addTokenList: Dto_GetTokensByWalletResult[], setAddTokenList: Function
	networkTokenList: IDropdown_Dto_GetTokensByNetworkResult[], setNetworkTokenList: Function
	selectedToken?: IDropdown_Dto_GetTokensByNetworkResult, setSelectedToken: Function
	disabled: boolean
}

export default function WalletManagementEditPageAddToken(
	props: IWalletManagementEditPageAddToken
) {
	const [showAddTokenModal, setShowAddTokenModal] = useState(false);
	const [showTFAModal, setShowTFAModal] = useState(false);

	useEffect(() => {
		init();
	}, [])

	async function init() {
		if(!props.item) return;
		getTokensByWallet(props.item);
		getTokensByNetwork();
	}

	async function getTokensByWallet(wallet: Dto_GetWalletsManagementResult) {
		if (!wallet?.walletId) return;
		const promises = [
			WalletApi.getTokensByWallet(wallet?.walletId, {
				nativeYn: false,
			}),
			WalletApi.getTokensByWallet(wallet?.walletId, {
				nativeYn: true
			})
		];
		const res = await Promise.all(promises);
		const newTokenList: Dto_GetTokensByWalletResult[] = [...res[0].data.list, ...res[1].data.list].sort((a, b) => a.tokenId - b.tokenId );
		props.setAddTokenList(newTokenList);
		// props.setAddTokenList([...newTokenList, ...newTokenList, ...newTokenList, ...newTokenList, ...newTokenList, ...newTokenList]);
	}

	async function getTokensByNetwork() {
		if(!props.item.networkId) return;
		NetworkApi.getTokensByNetwork(props.item.networkId).then((res) => {
			// props.setNetworkTokenList(res.data.list);
			props.setNetworkTokenList(res.data.list);
		}).catch((error) => {

		}).finally(() => {

		})
	}

	const tokenListContainer = () => {
		return <Row className={"d-flex justify-content-start align-items-start mb-4"}>
			{props.addTokenList.map((item) => tokenListItem(item))}
			<Col xs={4}>
				<button style={{
					width: "120px", height: "80px", marginRight: "8px",
					border: "1px solid black", borderRadius: "8px",
					display: "flex", justifyContent: "center", alignItems: "center",
					color: "white", backgroundColor: "black",
					cursor: "pointer"
				}}
				        onClick={onAddTokenClicked}
				        disabled={props.disabled}>+ Add Token</button>
			</Col>
		</Row>
	}

	const tokenListItem = (item: Dto_GetTokensByWalletResult) => {
		return <Col xs={3} key={item.tokenId}>
			<div style={{
			width: "120px", height: "80px", marginBottom: "16px",
			border: "1px solid #cccccc", borderRadius: "8px",
			display: "flex", justifyContent: "center", alignItems: "center",
		}}>{item.tokenName}</div>
		</Col>
	}

	const onAddTokenClicked = () => {
		setShowAddTokenModal(true)
	}

	return <Container>
		<WalletManagementEditPageAddTokenModal
			show={showAddTokenModal} setShow={setShowAddTokenModal}
			wallet={props.item}
			onConfirm={(selectedToken: Dto_GetTokensByNetworkResult) => {
				if(selectedToken) {
					init();
				}
			}}
		/>
		<Row className={"d-flex justify-content-center mb-5"}>
		<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
			<Row className={"h4 mb-4"}>Add Token</Row>
			<Row>
				{tokenListContainer()}
			</Row>
		</Row>
		</Row>
	</Container>
}