import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import {Button, Container, Tab, Tabs} from "react-bootstrap";
import TransactionPendingPagePendingSummary from "./pending_summary/TransactionPendingPagePendingSummary";
import TransactionPendingPageWalletConnectIn from "./wallet_connect_in/TransactionPendingPageWalletConnectIn";
import TransactionPendingPageWalletConnectOut from "./wallet_connect_out/TransactionPendingPageWalletConnectOut";
import TransactionPendingPageOther from "./other/TransactionPendingPageOther";
import '../../../styles/tabs.css'
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function TransactionPendingPage() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	return <Container>
		<CBreadCrumb first={"Transaction Pending"} firstUrl={"/wallet-connect/pending"}/>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CPageTitle title={"Transaction Pending"} subtitle={""}/>
		<Tabs
			defaultActiveKey="pending_summary"
			id="transaction_pending_tabs"
			className="mb-3 tabs"
		>
			<Tab eventKey="pending_summary" title="Pending Summary">
				<TransactionPendingPagePendingSummary 
				loading={loading}
				setLoading={setLoading}
													  />
			</Tab>
			<Tab eventKey="wallet_connect_in" title="Wallet Connect (In)">
				<TransactionPendingPageWalletConnectIn 
					loading={loading} setLoading={setLoading} navigate={navigate}
				/>
			</Tab>
			<Tab eventKey="wallet_connect_out" title="Wallet Connect (Out)" >
				<TransactionPendingPageWalletConnectOut
					loading={loading} setLoading={setLoading} navigate={navigate}
				/>
			</Tab>
			<Tab eventKey="other" title="Other..">
				<TransactionPendingPageOther
					loading={loading} setLoading={setLoading} navigate={navigate}
				/>
			</Tab>
		</Tabs>
	</Container>
}