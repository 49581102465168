import {Dto_SetOtpSettingInfo, Dto_GetOtpSettingInfo, Dto_GetOtpSettingInfoResult, Dto_SetPassword, Dto_DeleteOtpStatus} from "./user_security_dto";
import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";

export class UserSecurityApi {
	private static instance: UserSecurityApi
	private constructor() {}

	public static async getOtpSettingInfo(params: Dto_GetOtpSettingInfo):
		Promise<{code: string, msg: string, data: Dto_GetOtpSettingInfoResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PUBLIC_USER_SECURITY_OTP, params);
	}

	public static async setOtpSettingInfo(data: Dto_SetOtpSettingInfo):
		Promise<{code: string, msg: string, data: any, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_PUBLIC_USER_SECURITY_OTP, data);
	}

	public static async setPassword(data: Dto_SetPassword):
		Promise<{code: string, msg: string, data: any, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_USER_SECURITY_PASSWORD, data);
	}

	public static async setOtpStatus(data: Dto_SetOtpSettingInfo):
		Promise<{code: string, msg: string, data: any, success: boolean}> {
		return BaseHttp.post(ApiUrl.API_PRIVATE_USER_SECURITY_OTP, data);
	}

	public static async deleteOtpStatus(data: Dto_DeleteOtpStatus):
		Promise<{code: string, msg: string, data: any, success: boolean}> {
		return BaseHttp.delete(ApiUrl.API_PRIVATE_USER_SECURITY_OTP, data);
	}
}