import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import settings from 'assets/icons/settings.png';
import {useNavigate} from "react-router-dom";
import {Dto_GetWalletsManagementResult} from "../../../api/wallet/wallet_dto";
import CSettingButton from "../../components/CSettingButton";
import CLinkExplorer from "../../components/CLinkExplorer";

export interface IWalletManagementPageListItem {
	item: Dto_GetWalletsManagementResult
	accessUpdateWalletBasic: boolean
	accessUpdateWalletAlert: boolean
	accessUpdateWalletToken: boolean
	accessDisableWallet: boolean
}
export default function WalletManagementPageListItem(props: IWalletManagementPageListItem) {
	const navigate = useNavigate();
	const onSettingClick = () => {
		navigate("/wallet/management/edit", {
			state: {
				accessUpdateWalletBasic: props.accessUpdateWalletBasic,
				accessUpdateWalletAlert: props.accessUpdateWalletAlert,
				accessUpdateWalletToken: props.accessUpdateWalletToken,
				accessDisableWallet: props.accessDisableWallet,
				item: props.item,
			}
		});
	}

	function getStatusColor(status: string): string {
		if(status === 'ENABLED') {
			return "green";
		}
		else if(status === 'DISABLED') {
			return "red";
		}
		else
			return "black";
	}

	return <Row className="p-3 bg-white mb-2 moveItem">
		<Col>{props.item.walletId}</Col>
		<Col>{props.item.walletName}</Col>
		<Col>{props.item.networkName}</Col>
		<Col xs={4}><CLinkExplorer target={props.item.networkName}
		                           type={"address"} address={props.item.address}
		                           text={props.item.address} copyText={props.item.address}/>
		</Col>
		<Col>{props.item.typeName}</Col>
		<Col style={{ color: getStatusColor(props.item.status) }}>{props.item.status}</Col>
		<Col
			style={{ cursor: "pointer" }}
			className={"d-flex rounded"}>
			<CSettingButton onClick={onSettingClick} disabled={
				(!props.accessUpdateWalletBasic &&
				!props.accessUpdateWalletAlert &&
				!props.accessUpdateWalletToken) ||
				props.item.status === "DISABLED"
			}/>
		</Col>
	</Row>;
}