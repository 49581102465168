import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_DeleteAdmin, Dto_DeleteOtp, Dto_GetAdminList, Dto_GetAdminListResult, Dto_SetAdminRole, Dto_SetAdminStatus} from "./admin_dto";
import {DataResponse} from "../../model/response_model";

export class AdminApi {
	private static instance: AdminApi
	private constructor() {}

	public static async getAdminList(params: Dto_GetAdminList):
		Promise<{code: string, msg: string, data: { list: Dto_GetAdminListResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_USERS, params);
	}

	public static async deleteOtp(userId: number, data: Dto_DeleteOtp):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.delete(ApiUrl.API_PRIVATE_USERS_ID_OTP(userId.toString()), data);
	}

	public static async setAdminStatus(userId: number, data: Dto_SetAdminStatus):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_USERS_ID_STATUS(userId.toString()), data);
	}

	public static async setAdminRole(userId: number, data: Dto_SetAdminRole):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_USERS_ID_ROLE(userId.toString()), data);
	}

	public static async deleteAdmin(userId: number, data: Dto_DeleteAdmin):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.delete(ApiUrl.API_PRIVATE_USERS_ID(userId.toString()), data);
	}
}