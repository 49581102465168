import {Button, Container, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Dto_GetAdminListResult} from "../../../../api/admin/admin_dto";
import danger from "../../../../assets/icons/danger.png";
import CTextField from "../../../components/CTextField";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {AdminApi} from "../../../../api/admin/AdminApi";
import {UserApi} from "../../../../api/user/UserApi";

export interface ITransactionDetailResendModal {
    show: boolean
    setShow: any
    onCancel?: any
    onOk : Function
}

export default function TransactionDetailResendModal(props:ITransactionDetailResendModal){
    const [otpCode, setOtpCode] = useState('');


    return(
        <>


            <Modal
                show={props.show}
                onHide={()=>{
                    if(props.onCancel) props.onCancel();
                    props.setShow(false)
                }}
                backdrop="static"
                keyboard={false}
            >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Resend Transaction</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ border: "none" }}>
                        <Container className={"d-flex flex-column align-items-center"}>
                            <div className={"text-secondary text-center mb-5"}>Would you like to <strong>Resend</strong> the selected transaction?</div>
                            <div className={"ps-3 pe-3 mb-4"}>
                                <CTextField id={'modal-two-factor-authentication-code'} text={otpCode} setText={setOtpCode} placeholder={"Enter Google authentication code"} width={340}/>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                        <Button
                            style={{ width: "360px"}}
                            variant="success"
                            size={"lg"}
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.onOk(otpCode);
                                props.setShow(false);
                            }}>
                            Resend
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )

}