import { Button, Card, Col, Container, Row, Modal } from "react-bootstrap";

export interface IModalComponent {
    show: boolean,
    setShow: any,
    title?: string,
    body?: string,
    cancelText?: string,
    okText?: string,
    okColor?: string,
    onHide?: any,
    onCancel?: any,
    onOk: any,
}

export default function CModal(props: IModalComponent) {
    return (
        <>
            <Modal
            show={props.show}
            onHide={props.onHide ? props.onHide : ()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
            <Modal.Title>{props.title ? props.title : "Notice"}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none" }}>
                {props.body}
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
            <Button 
                variant="secondary" 
                onClick={
                props.onCancel ?
                    () => {
                        if(props.onCancel) props.onCancel();
                        props.setShow(false);
                    } :
                    () => {
                        props.setShow(false);
                    }}>
                    {props.cancelText ? props.cancelText : "Close"}
            </Button>
            <Button 
                variant={props.okColor ? props.okColor : "dark"}
                onClick={() => {
                    if(props.onOk) props.onOk();
                    props.setShow(false);
                }}>
                    {props.okText ? props.okText : "OK"}
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};