import {Col, Row} from "react-bootstrap";
import {Dto_GetWalletDistributeHistoriesResult} from "../../../api/wallet_distribute/wallet_distribute_dto";
import {getFloatingPointNumber, getGasUnit, shrinkAddress, weiToGwei} from "../../../utils/util";
import {Color} from "react-bootstrap/types";
import CLocaleTimestamp from "../../components/CLocaleTimestamp";
import CLinkExplorer from "../../components/CLinkExplorer";

export default function WalletDistributeHistoryPageListItem(props: Dto_GetWalletDistributeHistoriesResult) {
	function getSystemStatusColor(systemStatus: string): string {
		if(systemStatus === 'APPROVED') {
			return "green";
		}
		else if(systemStatus === 'UNRECOGNIZED') {
			return "GRAY";
		}
		else if(systemStatus === 'REFUND') {
			return "orange";
		}
		else if(systemStatus === 'FAILED') {
			return "red";
		}
		else
			return "black";
	}

	function getBlockchainStatusColor(blockchainStatus: string): string {
		if(blockchainStatus === 'COMPLETE') {
			return "green";
		}
		else if(blockchainStatus === 'PENDING') {
			return "orange";
		}
		else if(blockchainStatus === 'SENDING') {
			return "blue";
		}
		else if(blockchainStatus === 'READY') {
			return "black";
		}
		else
			return "black";
	}

	return <Row className="p-3 bg-white mb-2 moveItem">
			<Col xs={1} style={{ color: getSystemStatusColor(props.systemStatus)}}>{props.systemStatus}</Col>
			<Col xs={1}>{props.tokenName}</Col>
			<Col xs={1}>{`${props.amount} ${props.tokenName}`}</Col>
			<Col xs={2}>{`${getFloatingPointNumber(props.fee, 18)} ${getGasUnit(props.tokenName)}`}</Col>
			<Col xs={2}>
				{props.fromWalletName}
				{/*<CLinkExplorer type={"address"} target={props.tokenName} address={props.}*/}
				{/*               text={`(${shrinkAddress(props.fromWalletName)})`} copyText={props.fromWalletId}></CLinkExplorer>*/}
			</Col>
			<Col xs={2}>{props.toWalletName}</Col>
			<Col xs={2}>
				<CLocaleTimestamp timestamp={props.lastUpdateDate}/>
			</Col>
			<Col xs={1} style={{ color: getBlockchainStatusColor(props.blockchainStatus)}}>{props.blockchainStatus}</Col>
		</Row>
}