import {Button, Col, Container, Row} from "react-bootstrap";
import WalletDistributeHistoryPageListItem from "./WalletDistributeHistoryPageListItem";
import WalletDistributeHistoryPageListHeader from "./WalletDistributeHistoryPageListHeader";
import {useEffect, useState} from "react";
import WalletDistributeHistoryPageFilter from "./WalletDistributeHistoryPageFilter";
import {useNavigate} from "react-router-dom";
import {IDropdown} from "../../components/CDropdown";
import CLoadingDimmer from "../../components/CLoadingDimmer";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import CTotalCount from "../../components/CTotalCount";
import CSimplePagination from "../../components/CSimplePagination";
import CListPage from "../../components/CListPage";
import {WalletDistributeApi} from "../../../api/wallet_distribute/WalletDistributeApi";
import {Dto_GetWalletDistributeHistoriesResult} from "../../../api/wallet_distribute/wallet_distribute_dto";

export default function WalletDistributeHistoryPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [index, setIndex] = useState(1);
	const [list, setList] = useState<Dto_GetWalletDistributeHistoriesResult[]>([])
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [systemStatus, setSystemStatus] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);
	const [blockchainStatus, setBlockchainStatus] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);
	const [token, setToken] = useState<IDropdown>({
		name: 'All',
		value: '',
	},);
	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});

	const generateItem = (value: Dto_GetWalletDistributeHistoriesResult) => {
		return <WalletDistributeHistoryPageListItem key={JSON.stringify(value)} {...value} />
	}

	useEffect(() => {
		fetchHistoryList(1)
	}, [])

	async function fetchHistoryList(page: number, search?: string) {
		if(loading) return;
		setLoading(true);
		const result = await WalletDistributeApi.getWalletDistributeHistories({
			pageNumber: index,
			pageSize: pageSize,
			blockchainStatus: blockchainStatus.value,
			systemStatus: systemStatus.value,
			searchFilter: search,
			tokenId: token.value ? parseInt(token.value) : undefined,
		}).then((res) => {
			if(res.data) {
				setList(res.data.list)
				setTotalCount(res.data.pagingInfo.totalCount);
				setPageSize(res.data.pagingInfo.pageSize);
			}
		}).catch((error) => {

		}).finally(() => {
			setLoading(false);
		})
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Wallet Distribute History"} firstUrl={"/wallet/distribute-history"}/>
		<CPageTitle title={"Wallet Distribute History"} subtitle={""}/>

		{totalCount !== 0 ? <CTotalCount totalCount={totalCount}/> : <div/> }
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchHistoryList(page)}
			totalCount={totalCount}
			pageSize={pageSize}
		/>
		<WalletDistributeHistoryPageFilter
			listDisplay={listDisplay} setListDisplay={setListDisplay}
			blockchainStatus={blockchainStatus} setBlockchainStatus={setBlockchainStatus}
			token={token} setToken={setToken}
			systemStatus={systemStatus} setSystemStatus={setSystemStatus}
			onSearch={(search: string) => {
				setIndex(1)
				fetchHistoryList(1, search)
			}}
		/>
		<WalletDistributeHistoryPageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}