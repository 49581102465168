export interface ISidebarMenu {
    title: string,
    items: Array<ISidebarMenuItem>,
}

export interface ISidebarMenuItem {
    title: string,
    url: string,
    grade: number,
    enable: boolean,
}

export const SIDEBAR_MENU_ITEMS: Array<ISidebarMenu> = [
    {
        title: "Wallet",
        items: [
            {
                title: "Wallet Distribute",
                url: "/wallet/distribute",
                grade: 0,
                enable: true,
            },
            {
                title: "Wallet Management",
                url: "/wallet/management",
                grade: 0,
                enable: true,
            },
            {
                title: "Wallet Distribute History",
                url: "/wallet/dist-history",
                grade: 0,
                enable: true,
            },
        ]
    },
    {
        title: "Wallet Connect",
        items: [
            {
                title: "Connect Settings",
                url: "/wallet-connect/settings",
                grade: 0,
                enable: true,
            },
            {
                title: "Transaction Approve",
                url: "/wallet-connect/approve",
                grade: 0,
                enable: true,
            },
            {
                title: "Transaction Record",
                url: "/wallet-connect/record",
                grade: 0,
                enable: true,
            },
            {
                title: "Commission Record",
                url: "/wallet-connect/realize",
                grade: 0,
                enable: true,
            },
            {
                title: "Transaction Pending",
                url: "/wallet-connect/pending",
                grade: 0,
                enable: true,
            },
        ]
    },
    {
        title: "Settings",
        items: [
            {
                title: "Admin Settings",
                url: "/settings/admin",
                grade: 0,
                enable: true,
            },
            {
                title: "Account Settings",
                url: "/settings/account",
                grade: 0,
                enable: true,
            },
        ]
    },
    {
        title: "Smart Contract",
        items: [
            {
                title: "GPD Mint/Burn",
                url: "/contract/mint",
                grade: 0,
                enable: true,
            },
            {
                title: "GPD Histories",
                url: "/contract/history",
                grade: 0,
                enable: true,
            }
        ]
    },
    {
        title: "Log Data",
        items: [
            {
                title: "Activity Log",
                url: "/log/activity",
                grade: 0,
                enable: true,
            },
        ]
    },
];