import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import CSettingButton from "../../components/CSettingButton";
import CDeleteButton from "../../components/CDeleteButton";
import {Dto_GetAdminListResult} from "../../../api/admin/admin_dto";
import CLocaleTimestamp from "../../components/CLocaleTimestamp";

export interface IAdminSettingsPageListItem {
	user: Dto_GetAdminListResult
	onRemoveOtpClicked: Function
	onLoginLockClicked: Function
	onLoginUnlockClicked: Function
	onSettingClicked: Function
	onDeleteClicked: Function
	accessDeleteUserOTP: boolean
	accessUpdateUserStatus: boolean
	accessUpdateUserRole: boolean
	accessDeleteUser: boolean
}

export default function AdminSettingsPageListItem(props: IAdminSettingsPageListItem ) {
	return <Row className="p-3 bg-white mb-2 moveItem">
			<Col>{props.user.roleId}</Col>
			<Col>{props.user.userName}</Col>
			<Col>{props.user.roleId}</Col>
			<Col>
				{props.user.createDate?<CLocaleTimestamp timestamp={props.user.createDate}/>:"-"}
			</Col>
			<Col>
				{props.user.lastLoginDate?<CLocaleTimestamp timestamp={props.user.lastLoginDate}/>:"-"}
			</Col>
			<Col><Button variant={'dark'} onClick={() => props.onRemoveOtpClicked()} disabled={!props.accessDeleteUserOTP}>Remove OTP</Button>
			</Col>
				<Col>{
					props.user.lockedYn
						? <Button variant={'danger'} onClick={() => props.onLoginUnlockClicked()} disabled={!props.accessUpdateUserStatus}>Login Unlock</Button>
						: <Button variant={'dark'} onClick={() => props.onLoginLockClicked()} disabled={!props.accessUpdateUserStatus}>Login Lock</Button>
				}</Col>
			<Col>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<CSettingButton width={60} onClick={() => props.onSettingClicked()} disabled={!props.accessUpdateUserRole}/>
					<div style={{ width: "8px" }}></div>
					<CDeleteButton width={60} onClick={() => props.onDeleteClicked()} disabled={!props.accessDeleteUser}/>
				</div>
			</Col>
		</Row>
}