import {Col, Row} from "react-bootstrap";
import CTitleDropdown from "../../components/CTitleDropdown";
import {IDropdown} from "../../components/CDropdown";
import CTextField, {ITextFieldType} from "../../components/CTextField";
import {Dto_GetTokensBalanceByWalletResult, Dto_GetTokensByWalletResult, Dto_GetWalletsDistributeResult} from "../../../api/wallet/wallet_dto";
import {IDropdown_Dto_GetTokensByWalletResult, IDropdown_Dto_GetWalletsDistributeResult} from "../../../model/custom_dropdown";

export interface IWalletDistributeSendingPage {
	walletOffline: Dto_GetWalletsDistributeResult[]
	walletOnline: Dto_GetWalletsDistributeResult[]
	walletDropdown: IDropdown_Dto_GetWalletsDistributeResult[]
	walletDropdownSelected?: IDropdown_Dto_GetWalletsDistributeResult
	setWalletDropdownSelected: Function
	onWalletSelected: Function

	walletTokens: Dto_GetTokensByWalletResult[]
	walletTokensDropdown: IDropdown_Dto_GetTokensByWalletResult[]
	walletTokenDropdownSelected?: IDropdown_Dto_GetTokensByWalletResult
	setWalletTokenDropdownSelected: Function
	onTokenSelected: Function

	walletAddress: string
	setWalletAddress: Function
	walletBalance: string
	setWalletBalance: Function
	transferAmount: string
	setTransferAmount: Function
	transactionFee: string
	setTransactionFee: Function
	transactionFeeBalance: string
	onTransactionAmountChanged: Function
}

export default function WalletDistributePageSendingCard( props: IWalletDistributeSendingPage) {
	return <Col className="p-4 bg-white">
		<Row className={"h5 ms-1 mb-4"}>Choose a sending wallet</Row>
		<Row className="mb-4">
			<CTitleDropdown id={"wallet-distribute-sending-Select Wallet Name"} title={"Select Wallet Name"} data={props.walletDropdown}
                selected={props.walletDropdownSelected}
                onSelected={(selected: IDropdown) => {
	                props.onWalletSelected(selected)
				}}/>
		</Row>
		<Row className="mb-4">
			<CTitleDropdown id={"wallet-distribute-sending-Select Coin/Token"} title={"Select Coin/Token"} data={props.walletTokensDropdown}
                selected={props.walletTokenDropdownSelected}
                onSelected={(selected: IDropdown) => {
	                props.onTokenSelected(selected)
                }}/>
		</Row>
		<Row className="mb-4">
			<CTextField id={"wallet-distribute-sending-Wallet Address"} title={"Wallet Address"} placeholder={"-"}
			            text={props.walletAddress} setText={props.setWalletAddress} type={ITextFieldType.text}
			            disabled={true}
			/>
		</Row>
		<Row className="mb-4">
			<CTextField id={"wallet-distribute-sending-Wallet Balance"} title={"Wallet Balance"} placeholder={"-"}
			            text={props.walletBalance} setText={props.setWalletBalance} hint={""} type={ITextFieldType.text}
						disabled={true}
			/>
		</Row>
		<Row>
			<CTextField id={"wallet-distribute-sending-Transfer Amount"} title={"Transfer Amount"} placeholder={"-"}
			            text={props.transferAmount} setText={props.setTransferAmount} hint={""} type={ITextFieldType.number}
			            onChange={props.onTransactionAmountChanged}
			/>
		</Row>
		<Row>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",
				marginTop: "16px", paddingRight: "16px",
				color: "#666666", fontSize: "10px" }}>
				<div>Max Transaction Fee</div>
				<div>{`${props.transactionFee}`}</div>
			</div>
		</Row>
		<Row>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",
				marginTop: "8px", paddingRight: "16px",
				color: "#666666", fontSize: "10px" }}>
				<div>Available Transaction Fee Balance</div>
				<div>{`${props.transactionFeeBalance}`}</div>
			</div>
		</Row>
	</Col>
}