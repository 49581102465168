import Axios from 'axios';
import {ApiResponse, DataResponse} from "../model/response_model";
import {TokenManager} from "../states/token";
import {checkValidResult, logout} from "../utils/session";

export class BaseHttp {
	private static instance: BaseHttp
	private constructor() {}

	public static async get (url: string, params?: object): Promise<DataResponse> {
		console.log(`## GET: ${url},\nparams: ${JSON.stringify(params)}`);
		const accessToken = TokenManager.getAccessToken();
		const refreshToken = TokenManager.getRefreshToken();
		if(!accessToken || !refreshToken) logout();
		try {
			const result: ApiResponse = await Axios(url, {
				method: "get",
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${accessToken}`
				},
				params: params
			});
			const resultData = result.data
			console.log(`## result: ${JSON.stringify(resultData)}`);
			await checkValidResult(resultData);
			return resultData;
		} catch(e) {
			return {
				msg: "internal exception",
				code: "9999",
				data: null,
				success: false,
			}
		}
	}

	public static async post(url: string, data?: object): Promise<DataResponse> {
		console.log(`## POST: ${url},\ndata: ${JSON.stringify(data)}`);
		const accessToken = TokenManager.getAccessToken();
		const refreshToken = TokenManager.getRefreshToken();
		if(!accessToken || !refreshToken) logout();
		try {
			const result: ApiResponse = await Axios(url, {
				method: "post",
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${accessToken}`
				},
				data: data
			});
			const resultData = result.data
			console.log(`## result: ${JSON.stringify(resultData)}`);
			await checkValidResult(resultData);
			return resultData;
		} catch(e) {
			return {
				msg: "internal exception",
				code: "9999",
				data: null,
				success: false,
			}
		}
	}

	public static async delete (url: string, data?: object): Promise<DataResponse> {
		console.log(`## DELETE: ${url},\ndata: ${JSON.stringify(data)}`);
		const accessToken = TokenManager.getAccessToken();
		const refreshToken = TokenManager.getRefreshToken();
		if(!accessToken || !refreshToken) logout();
		try {
			const result: ApiResponse = await Axios(url, {
				method: "delete",
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${accessToken}`
				},
				data: data
			});
			const resultData = result.data
			console.log(`## result: ${JSON.stringify(resultData)}`);
			await checkValidResult(resultData);
			return resultData;
		} catch(e) {
			return {
				msg: "internal exception",
				code: "9999",
				data: null,
				success: false,
			}
		}
	}

	public static async put(url: string, data?: object): Promise<DataResponse> {
		console.log(`## PUT: ${url},\ndata: ${JSON.stringify(data)}`);
		const accessToken = TokenManager.getAccessToken();
		const refreshToken = TokenManager.getRefreshToken();
		if(!accessToken || !refreshToken) logout();
		try {
			const result: ApiResponse = await Axios(url, {
				method: "put",
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${accessToken}`
				},
				data: data
			});
			const resultData = result.data
			console.log(`## result: ${JSON.stringify(resultData)}`);
			await checkValidResult(resultData);
			return resultData;
		} catch(e) {
			return {
				msg: "internal exception",
				code: "9999",
				data: null,
				success: false
			}
		}
	}
}