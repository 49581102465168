import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSearchTextField from "../../components/CSearchTextField";

export interface IWalletManagementPageFilter {
	mainnetDropdownList: IDropdown[]
	mainnet: IDropdown
	setMainnet: Function
	signatureType: IDropdown
	setSignatureType: Function
	status: IDropdown
	setStatus: Function
	listDisplay: IDropdown
	setListDisplay: Function
	onSearch: Function
}

const signatureTypeData = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'SINGLE',
		value: 'SINGLE',
	},
	{
		name: 'MULTI',
		value: 'MULTI',
	},
]

const statusData = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'DISABLE',
		value: 'DISABLE',
	},
	{
		name: 'ENABLED',
		value: 'ENABLED',
	},
]

const listDisplayData = [
	{
		name: "25",
		value: "25",
	},
	{
		name: "50",
		value: "50",
	},
	{
		name: "100",
		value: "100",
	}
]

export default function WalletManagementPageFilter(props: IWalletManagementPageFilter) {
	return <Row className="mb-3 align-items-end">
			<Col>
				<CDropdown id={"wallet-management-dropdown-mainnet"} hint={"Mainnet"} data={props.mainnetDropdownList} selected={props.mainnet} onSelected={props.setMainnet}></CDropdown>
			</Col>
			<Col>
				<CDropdown id={"wallet-management-dropdown-signature-type"} hint={"Signature Type"} data={signatureTypeData} selected={props.signatureType} onSelected={props.setSignatureType}></CDropdown>
			</Col>
			<Col>
				<CDropdown id={"wallet-management-dropdown-status"} hint={"Status"} data={statusData} selected={props.status} onSelected={props.setStatus}></CDropdown>
			</Col>
			<Col>
				<CDropdown id={"wallet-management-dropdown-list-display"} hint={"List Display"} data={listDisplayData} selected={props.listDisplay} onSelected={props.setListDisplay}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"wallet-management-search"} placeholder={"Wallet Name / Wallet Address"} onSearch={props.onSearch}/>
			</Col>
		</Row>;
}