import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {localeSettingState} from "../../states/locale_atom";

export interface ILocaleTimestampComponent {
	timestamp: string
}

export default function CLocaleTimestamp(props: ILocaleTimestampComponent) {
	const [localeState, setLocaleState] = useRecoilState(localeSettingState);
	const [time, setTime] = useState('-');

	useEffect(() => {
		if (localeState) {
			const date = new Date(props.timestamp);
			const localOffsetInMinutes = date.getTimezoneOffset();
			date.setMinutes(date.getMinutes() - localOffsetInMinutes);

			setTime(date.toLocaleString());
		}
	}, [])

	return <div >
		<div style={{
			fontSize: "small"
		}}>{time}</div>
	</div>
}