import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import RecoilNexus from 'recoil-nexus';
import { RecoilRoot } from 'recoil';
import App from 'App';
import { initDB } from "@slnsw/react-indexed-db";
import { dBConfig } from './indexed_db_config';

initDB(dBConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<>
  <head>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
      integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
      crossOrigin="anonymous"
    />
  </head>
    <RecoilRoot>
      <RecoilNexus/>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </RecoilRoot>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
