import {Button, Container, Row} from "react-bootstrap";
import CTextField, {ITextFieldType} from "../../components/CTextField";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CBreadCrumb from "../../components/CBreadCrumb";
import CPageTitle from "../../components/CPageTitle";
import {useEffect, useState} from "react";
import {IDropdown_Dto_GetMainnetResult, IDropdown_Dto_GetTokenByNetworkAndWalletResult, IDropdown_Dto_GetWalletByNetworkIdResult} from "../../../model/custom_dropdown";
import {useLocation, useNavigate} from "react-router-dom";
import {NetworkApi} from "api/network/NetworkApi";
import {WalletConnectApi} from "api/wallet_connect/WalletConnectApi";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import CVerifyTFAModal from "pages/components/modal/CVerifyTFAModal";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK, MESSAGE_INVALID_VALUE} from "constants/value";

export default function ConnectSettingsPageEditConnectPair() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [showTfa,setShowTfa] = useState(false);
	const [showTfaPairOne,setShowTfaPairOne] = useState(false);
	const [showTfaPairTwo,setShowTfaPairTwo] = useState(false);

	const isEditPage = true;
	const [pairId, setPairId] = useState("");

	const [selectInMainnet, setSelectInMainnet] = useState<IDropdown>();
	const [selectOutMainnet, setSelectOutMainnet] = useState<IDropdown>();
	const [mainnetDropdownList, setMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [outMainnetDropdownList, setOutMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);

	const [selectInWallet, setSelectInWallet] = useState<IDropdown>();
	const [selectOutWallet, setSelectOutWallet] = useState<IDropdown>();
	const [InWalletDropdownList, setInWalletDropdownList] = useState<IDropdown_Dto_GetWalletByNetworkIdResult[]>([]);
	const [OutWalletDropdownList, setOutWalletDropdownList] = useState<IDropdown_Dto_GetWalletByNetworkIdResult[]>([]);

	const [selectInToken, setSelectInToken] = useState<IDropdown>();
	const [selectOutToken, setSelectOutToken] = useState<IDropdown>();
	const [InTokenDropdownList, setInTokenDropdownList] = useState<IDropdown_Dto_GetTokenByNetworkAndWalletResult[]>([]);
	const [OutTokenDropdownList, setOutTokenDropdownList] = useState<IDropdown_Dto_GetTokenByNetworkAndWalletResult[]>([]);

	// settings 2
	const [gasCoverageFee, setGasCoverageFee] = useState('');
	const [conversionFee, setConversionFee] = useState('');
	const [maxLimit, setMaxLimit] = useState('');
	const [minLimit, setMinLimit] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			await fetchMainnetList();
			if(location.state===undefined||location.state===null){alert("Refresh Page");navigate(-2);}
			const walletConnect = location.state.walletConnect;
			setPairId(walletConnect.pairId.toString());
			setSelectInMainnet(walletConnect.inNetworkName ? { name: walletConnect.inNetworkName, value: walletConnect.inNetworkId.toString() } : undefined);
			setSelectInWallet(walletConnect.inWalletName ? { name: walletConnect.inWalletName, value: walletConnect.inWalletId.toString() } : undefined);
			setSelectInToken(walletConnect.inTokenName ? { name: walletConnect.inTokenName, value: walletConnect.inTokenId.toString() } : undefined);
			setSelectOutMainnet(walletConnect.outNetworkName ? { name: walletConnect.outNetworkName, value: walletConnect.outNetworkId.toString() } : undefined);
			setSelectOutWallet(walletConnect.outWalletName ? { name: walletConnect.outWalletName.toString(), value: walletConnect.outWalletId.toString() } : undefined);
			setSelectOutToken(walletConnect.outTokenName ? { name: walletConnect.outTokenName, value: walletConnect.outTokenId.toString() } : undefined);
			setGasCoverageFee(walletConnect.gasCoverageFee);
			setConversionFee(walletConnect.conversionFee);
			setMaxLimit(walletConnect.maxLimit);
			setMinLimit(walletConnect.minLimit);
			fetchWalletListByMainnet(walletConnect.inNetworkId).then((res)=>{
				if(res){
					const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
						name: item.walletName,
						value: item.walletId.toString(),
						data: item,
					}));

					setInWalletDropdownList(newWalletDropdownList);
				}
			})

			fetchWalletListByMainnet(walletConnect.outNetworkId).then((res)=>{
				if(res){
					const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
						name: item.walletName,
						value: item.walletId.toString(),
						data: item,
					}));

					setOutWalletDropdownList(newWalletDropdownList);
				}
			})

			fetchTokenByMainnetAndWallet(walletConnect.inNetworkId,walletConnect.inWalletId).then((res)=>{
				if(res){
					const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
						name: item.tokenName,
						value: item.tokenId.toString(),
						data: item,
					}));
					setInTokenDropdownList(newWalletDropdownList);
				}
			})

			fetchTokenByMainnetAndWallet(walletConnect.outNetworkId,walletConnect.outWalletId).then((res)=>{
				if(res){
					const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
						name: item.tokenName,
						value: item.tokenId.toString(),
						data: item,
					}));
					setOutTokenDropdownList(newWalletDropdownList);
				}
			})
		}
		fetchData();
	}, [])

	// useEffect(()=>{
	// const walletConnect: Dto_GetWalletConnectionResult = location.state.walletConnect;
	// setPairId(walletConnect.pairId.toString());
	// },[])

	async function fetchMainnetList() {
		if (loading) return;
		setLoading(true);

		try {
			const res = await NetworkApi.getMainnet();
			const newMainnetDropdownList: IDropdown_Dto_GetMainnetResult[] = res.data.list.map((item) => ({
				name: item.name,
				value: item.id.toString(),
				data: item,
			}));
			setMainnetDropdownList(newMainnetDropdownList);
			setOutMainnetDropdownList(newMainnetDropdownList);
		} catch (e) {

		} finally {
			setLoading(false);
		}
	}
	async function fetchWalletListByMainnet(mainnetId:number) {
		if (loading) return;
		setLoading(true);
		const res = await NetworkApi.getWalletByMainnet(mainnetId.toString(), {
			externalYn: false
		});
		setLoading(false)
		if(res && res.success) return res.data.list;
		else return []
	}

	async function fetchTokenByMainnetAndWallet(mainnetId:string,walletId:string) {
		if(loading) return;
		setLoading(true);
		const res = await NetworkApi.getTokenByMainnetAndWallet(mainnetId, walletId, {
			nativeYn: false
		});
		setLoading(false)
		if(res && res.success) return res.data.list;
		else return []
	}
	async function setWalletConnectionBasic(otpCode:string){
		if(loading) return;
		setLoading(true);

		if(selectInToken?.value!==undefined&&selectOutToken?.value!==undefined&&selectInWallet?.value!==undefined&&selectOutWallet?.value!==undefined){
			try{
				await WalletConnectApi.setWalletConnectionBasic(pairId,{
					inTokenId : parseInt(selectInToken.value),
					outTokenId : parseInt(selectOutToken.value),
					inWalletId : parseInt(selectInWallet.value),
					outWalletId :parseInt(selectOutWallet.value),
					otpCode : otpCode
				}).then(res=>{
					if(res && res.success){
						alert(MESSAGE_CHANGES_SAVED)
						window.location.reload();
					}else{
						alert(MESSAGE_CHANGES_FAILED)
					}

				})
			}catch(e){

			}finally{
				setLoading(false);
			}
		}
	}

	async function setWalletConnectionFee(otpCode : string){
		if(loading) return;

		const gasCoverageFeeNum = parseFloat(gasCoverageFee);
		const conversionFeeNum = parseFloat(conversionFee);
		const maxLimitNum = parseFloat(maxLimit);
		const minLimitNum = parseFloat(minLimit);

		if(minLimitNum > maxLimitNum) {
			alert(MESSAGE_INVALID_VALUE);
			return;
		}
		if(gasCoverageFeeNum > minLimitNum) {
			alert(MESSAGE_INVALID_VALUE);
			return;
		}

		setLoading(true);
		try{
			await WalletConnectApi.setWalletConnectionFee(pairId,{
				gasCoverageFee : gasCoverageFeeNum,
				conversionFee : conversionFeeNum,
				maxLimit : maxLimitNum,
				minLimit : minLimitNum,
				otpCode : otpCode
			}).then(res=>{
				if(res && res.success){
					alert(MESSAGE_CHANGES_SAVED)
					navigate(-1)
				}else{
					alert(MESSAGE_CHANGES_FAILED)
				}

			})
		}catch(e){

		}finally{
			setLoading(false);
		}
	}

	const onPairOneReset = () => {
		setSelectInMainnet(undefined);
		setSelectOutMainnet(undefined);
		setSelectInWallet(undefined);
		setSelectOutWallet(undefined);
		setSelectInToken(undefined);
		setSelectOutToken(undefined);

		setInTokenDropdownList([]);
		setOutTokenDropdownList([]);
		setInWalletDropdownList([]);
		setOutWalletDropdownList([]);
	}
	const onPairTwoReset = () => {
		setGasCoverageFee('');
		setConversionFee('');
		setMaxLimit('');
		setMinLimit('');
	}

	return <Container>
		<CBreadCrumb
			first={"Connect Settings"} firstUrl={"/wallet-connect/settings"}
			second={"Edit Connect Pair"} secondUrl={"/wallet-connect/settings/edit"}
		/>
		<CPageTitle title={"Edit Connect Pair"} subtitle={""}/>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal show={showTfa} setShow={setShowTfa} onOk={(otpCode:string)=>{

			if(showTfaPairOne){
				setWalletConnectionBasic(otpCode);
				setShowTfaPairOne(false);
			}else if(showTfaPairTwo){
				setWalletConnectionFee(otpCode);
				setShowTfaPairTwo(false);
			}

		}}/>
		{/* setting 1 */}
		<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
			<Row className={"h4 mb-5"}>Pair Settings 1</Row>

			{/* Pair ID*/}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Pair ID</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-pair-id-text'}
				            text={pairId} setText={setPairId} width={360} disabled={isEditPage}></CTextField>
			</div>
			{/* Select Mainnet */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Mainnet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-main-token-dropdown'}
					width={360}
					data={mainnetDropdownList}
					selected={selectInMainnet}
					onSelected={
						(selected:IDropdown)=>{
							if(selected.name!==selectOutMainnet?.name){
								setSelectInMainnet(selected);
							}else{
								setSelectInMainnet(selected);
								setSelectOutMainnet(undefined);
								setSelectOutWallet(undefined);
								setSelectOutToken(undefined);
							}
							if(selectInMainnet!==selected){
								setSelectInWallet(undefined);
								setSelectInToken(undefined);
								setInTokenDropdownList([]);
								setInWalletDropdownList([]);
							}
							if(!selected.value) return;
							fetchWalletListByMainnet(parseInt(selected.value)).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
										name: item.walletName,
										value: item.walletId.toString(),
										data: item,
									}));

									setInWalletDropdownList(newWalletDropdownList);
								}
							})
						}
					}></CDropdown>
			</div>
			{/* Select In Wallet */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select In Wallet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-wallet-dropdown'}
					width={360}
					data={InWalletDropdownList}
					selected={selectInWallet}
					onSelected={(selected:IDropdown)=>{
						setSelectInWallet(selected);
						if(selectInWallet!==selected){
							setSelectInToken(undefined);
						}

						if(selectInMainnet?.value !== undefined && selected.value !== undefined)
							fetchTokenByMainnetAndWallet(selectInMainnet.value,selected.value).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
										name: item.tokenName,
										value: item.tokenId.toString(),
										data: item,
									}));
									setInTokenDropdownList(newWalletDropdownList);
								}
							})
					}}
				></CDropdown>
			</div>
			{/* Select In Token */}
			<div className={"d-flex justify-content-between align-items-center mb-5"}>
				<div>Select In Token</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={InTokenDropdownList}
					selected={selectInToken}
					onSelected={setSelectInToken}
				></CDropdown>
			</div>
			{/* Select Out Mainnet*/}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Mainnet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={outMainnetDropdownList}
					selected={selectOutMainnet}
					onSelected={
						(selected:IDropdown)=>{
							if(selected.name!==selectInMainnet?.name){
								setSelectOutMainnet(selected);
							}else{
								setSelectOutMainnet(selected);
								setSelectInMainnet(undefined);
								setSelectInWallet(undefined);
								setSelectInToken(undefined);
							}

							if(selectOutMainnet!==selected){
								setSelectOutWallet(undefined);
								setSelectOutToken(undefined);
								setOutTokenDropdownList([]);
								setOutWalletDropdownList([]);
							}
							if(!selected.value) return;
							fetchWalletListByMainnet(parseInt(selected.value)).then((res)=>{
								if(res){
									const newWalletDropdownList: IDropdown_Dto_GetWalletByNetworkIdResult[] = res.map((item) => ({
										name: item.walletName,
										value: item.walletId.toString(),
										data: item,
									}));

									setOutWalletDropdownList(newWalletDropdownList);
								}
							})
						}
					}
				></CDropdown>
			</div>
			{/* Select Out Wallet*/}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Select Out Wallet</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={OutWalletDropdownList}
					selected={selectOutWallet}
					onSelected={
						(selected:IDropdown)=>{
							setSelectOutWallet(selected)

							if(selectOutWallet!==selected){
								setSelectOutToken(undefined);
							}
							if(selectOutMainnet?.value !== undefined && selected.value !== undefined)
								fetchTokenByMainnetAndWallet(selectOutMainnet.value,selected.value).then((res)=>{
									if(res){
										const newWalletDropdownList: IDropdown_Dto_GetTokenByNetworkAndWalletResult[] = res.map((item) => ({
											name: item.tokenName,
											value: item.tokenId.toString(),
											data: item,
										}));
										setOutTokenDropdownList(newWalletDropdownList);
									}
								})
						}}
				></CDropdown>
			</div>
			{/* Select Out Token*/}
			<div className={"d-flex justify-content-between align-items-center mb-5"}>
				<div>Select Out Token</div>
				<CDropdown
					id={'connect-setting-page-edit-connect-pair-select-in-token-dropdown'}
					width={360}
					data={OutTokenDropdownList}
					selected={selectOutToken}
					onSelected={setSelectOutToken}
				></CDropdown>
			</div>

			<div className={"d-flex justify-content-end"}>
				<Button size={"lg"} variant={"secondary"} className={"me-2"} onClick={onPairOneReset}>Reset</Button>
				<Button size={"lg"} variant={"dark"} onClick={()=>{
					if(selectInMainnet?.value && selectInWallet?.value && selectInToken?.value && selectOutMainnet?.value && selectOutWallet?.value && selectOutToken?.value){
						setShowTfa(true)
						setShowTfaPairOne(true);
					}else{
						alert(MESSAGE_FILL_THE_BLANK);
					}

				}}>Save</Button>
			</div>
		</Row>

		{/* setting 2 */}
		<Row style={{ width: "768px" }} className={"p-5 mb-5 bg-white rounded"}>
			<Row className={"h4 mb-5"}>Pair Settings 2</Row>
			{/* Set Gas Coverage Fee */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set Gas Coverage Fee</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-gas-coverage-fee-text'}
				            text={gasCoverageFee} setText={setGasCoverageFee} width={360} unit={selectOutToken?.name?selectOutToken.name:"-"}
				            type={ITextFieldType.number}/>
			</div>
			{/* Set Coverage Fee */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set Conversion Fee</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-conversion-fee-text'}
				            text={conversionFee} setText={setConversionFee} width={360} unit={"%"}
				            type={ITextFieldType.number}/>
			</div>
			{/* Set (Max) Limit */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set (Max) Limit</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-max-limit-text'}
				            text={maxLimit} setText={setMaxLimit} width={360} unit={selectInToken?.name?selectInToken.name:"-"}
				            type={ITextFieldType.number}/>
			</div>
			{/* Set (Min) Limit */}
			<div className={"d-flex justify-content-between align-items-center mb-4"}>
				<div>Set (Min) Limit</div>
				<CTextField id={'connect-setting-page-edit-connect-pair-set-min-limit-text'}
				            text={minLimit} setText={setMinLimit} width={360} unit={selectInToken?.name?selectInToken.name:"-"}
				            type={ITextFieldType.number}/>
			</div>
			<div className={"d-flex mt-4 justify-content-end"}>
				<Button size={"lg"} variant={"secondary"} className={"me-2"} onClick={onPairTwoReset}>Reset</Button>
				<Button size={"lg"} variant={"dark"} onClick={()=>{
					try {
						const gasCoverageFeeNum = parseFloat(gasCoverageFee);
						const conversionFeeNum = parseFloat(conversionFee);
						const maxLimitNum = parseFloat(maxLimit);
						const minLimitNum = parseFloat(minLimit);

						if(minLimitNum > maxLimitNum) {
							alert(MESSAGE_INVALID_VALUE);
							return;
						}
						if(gasCoverageFeeNum > minLimitNum) {
							alert(MESSAGE_INVALID_VALUE);
							return;
						}
					} catch(e) {}
					if(gasCoverageFee !== '' && gasCoverageFee !== undefined &&
						conversionFee !== '' && conversionFee !== undefined &&
						maxLimit !== '' && maxLimit !== undefined &&
						minLimit !== '' && minLimit !== undefined) {
						setShowTfa(true)
						setShowTfaPairTwo(true)
					}else{
						alert(MESSAGE_FILL_THE_BLANK);
					}
				}}>Save</Button>
			</div>
		</Row>
	</Container>
}