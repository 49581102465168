import {Button, Col, Row} from "react-bootstrap";
import {Dto_GetGPDHistoryResult} from "../../../../api/smart_contract/smart_contract_dto";
import {getFormattedNumber, shrinkAddress, weiToGwei} from "../../../../utils/util";
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";
import CLinkExplorer from "../../../components/CLinkExplorer";

export default function GPDHistoryPageMintListItem(props: Dto_GetGPDHistoryResult) {
	function getBlockchainStatusColor(blockchainStatus: string): string {
		if(blockchainStatus === 'COMPLETE') {
			return "green";
		}
		else if(blockchainStatus === 'PENDING') {
			return "orange";
		}
		else if(blockchainStatus === 'SENDING') {
			return "blue";
		}
		else if(blockchainStatus === 'READY') {
			return "black";
		}
		else
			return "black";
	}
	return <Row className="p-3 bg-white mb-2 moveItem">
		<Col>{props.createDate?<CLocaleTimestamp timestamp={props.createDate}/> : "-"}</Col>
		<Col>{props.id}</Col>
		<Col>{props.username}</Col>
		<Col style={{ color: getBlockchainStatusColor(props.blockStatus)}}>{props.blockStatus}</Col>
		<Col>
			{props.toWalletName}
			<CLinkExplorer type={"address"} target={props.networkName} address={props.toAddress}
			               text={`(${shrinkAddress(props.toAddress,5)})`} copyText={props.toAddress}></CLinkExplorer>
		</Col>
		<Col>{`${props.amount} GPD`}</Col>
		<Col>{`${props.gasFee} GPX`}</Col>
	</Row>
}