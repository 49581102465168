import {Container} from "react-bootstrap";
import CListPage from "../../../components/CListPage";
import GPDHistoryPageBurnFilter from "./GPDHistoryPageBurnFilter";
import GPDHistoryPageBurnListHeader from "./GPDHistoryPageBurnListHeader";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {IDropdown} from "../../../components/CDropdown";
import CSimplePagination from "../../../components/CSimplePagination";
import {SmartContractApi} from "../../../../api/smart_contract/SmartContractApi";
import {checkValidResult} from "../../../../utils/session";
import {Dto_GetGPDHistoryResult} from "../../../../api/smart_contract/smart_contract_dto";
import CTotalCount from "../../../components/CTotalCount";
import GPDHistoryPageBurnListItem from "./GPDHistoryPageBurnListItem";

export default function GPDHistoryPageBurn({loading, setLoading, navigate}: {loading: boolean, setLoading: Function, navigate: NavigateFunction}) {
	const [index, setIndex] = useState(1);
	const [list, setList] = useState<Dto_GetGPDHistoryResult[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [listDisplay, setListDisplay] = useState<IDropdown>({
		name: "25",
		value: "25",
	});
	const generateItem = (value: Dto_GetGPDHistoryResult) => {
		return <GPDHistoryPageBurnListItem key={value.id} {...value} />
	}

	useEffect(() => {
		fetchGPDBurnList(1)
	}, [])

	async function fetchGPDBurnList(page: number, search?: string) {
		if(loading) return;
		setLoading(true);
		const result = await SmartContractApi.getGPDHistory( {
			pageNumber: page,
			pageSize: listDisplay.value ? parseInt(listDisplay.value) : 25,
			type: 'BURN',
			search: search,
		});
		if(result && result.data.pagingInfo.totalCount && result.data.list) {
			try {
				setTotalCount(result.data.pagingInfo.totalCount)
				if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
				setList(result.data.list);
				setLoading(false);
			} catch(e) {
				setTotalCount(0)
				if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
				setList([]);
				setLoading(false)
			}
		}
		else {
			setTotalCount(0)
			if(listDisplay.value) setPageSize(parseInt(listDisplay.value))
			setList([]);
			setLoading(false)
		}
	}

	return <Container>
		<CTotalCount totalCount={totalCount}/>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchGPDBurnList(page)}
			totalCount={totalCount}
			pageSize={pageSize}
		/>
		<GPDHistoryPageBurnFilter
			selectedListDisplay={listDisplay}
			setSelectedListDisplay={setListDisplay}
			onSearch={(search: string) => {
				setIndex(1);
				fetchGPDBurnList(1, search);
			}}
		/>
		<GPDHistoryPageBurnListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}