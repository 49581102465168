import { NavigateFunction } from "react-router";

let navigate: NavigateFunction | null = null;

export function setNavigateFunction(navigateFunction: NavigateFunction) {
	navigate = navigateFunction;
}

export function navigateTo(path: string): void {
	if (navigate) {
		navigate(path);
	} else {

	}
}
