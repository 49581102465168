import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../components/CDropdown";
import CSearchTextField from "../components/CSearchTextField";

export interface ILogDataPageFilter {
	listDisplay: IDropdown
	setListDisplay: Function
	category: IDropdown
	setCategory: Function
	type: IDropdown
	setType: Function
	status: IDropdown
	setStatus: Function
	onSearch: Function
}

const listDisplayData = [
	{
		name: "25",
		value: "25",
	},
	{
		name: "50",
		value: "50",
	},
	{
		name: "100",
		value: "100",
	}
]

const category = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'WALLET',
		value: 'WALLET',
	},
	{
		name: 'WALLET_CONNECT',
		value: 'WALLET_CONNECT',
	},
	{
		name: 'SETTING',
		value: 'SETTING',
	},
	{
		name: 'SMART_CONTRACT',
		value: 'SMART_CONTRACT',
	},
	{
		name: 'SYSTEM',
		value: 'SYSTEM',
	},
]

const type = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'WALLET_DISTRIBUTE',
		value: 'WALLET_DISTRIBUTE',
	},
	{
		name: 'WALLET_MANAGEMENT',
		value: 'WALLET_MANAGEMENT',
	},
	{
		name: 'TRANSACTION_APPROVE',
		value: 'TRANSACTION_APPROVE',
	},
	{
		name: 'FEE_REALIZE',
		value: 'FEE_REALIZE',
	},
	{
		name: 'TRANSACTION_PENDING',
		value: 'TRANSACTION_PENDING',
	},
	{
		name: 'ADMIN_SETTING',
		value: 'ADMIN_SETTING',
	},
	{
		name: 'ROLE_SETTING',
		value: 'ROLE_SETTING',
	},
	{
		name: 'ACCOUNT_SETTING',
		value: 'ACCOUNT_SETTING',
	},
	{
		name: 'MINT_BURN',
		value: 'MINT_BURN',
	},
	{
		name: 'LOGIN',
		value: 'LOGIN',
	},
]
const status = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'FAILED',
		value: 'FAILED',
	},
	{
		name: 'COMPLETED',
		value: 'COMPLETED',
	},
]

export default function LogDataPageFilter(props: ILogDataPageFilter) {
	return <Row className="mb-3 align-items-end">
		<Col xs={6}>
			<div className={"d-flex justify-content-between"}>
				<CDropdown id={"activity-logs-dropdown-list-display"} hint={"List Display"} width={150} data={listDisplayData} selected={props.listDisplay} onSelected={props.setListDisplay}></CDropdown>
				<CDropdown id={"activity-logs-dropdown-category"} hint={"Category"} width={150} data={category} selected={props.category} onSelected={props.setCategory}></CDropdown>
				<CDropdown id={"activity-logs-dropdown-type"} hint={"Type"} width={150} data={type} selected={props.type} onSelected={props.setType}></CDropdown>
				<CDropdown id={"activity-logs-dropdown-status"} hint={"Status"} width={150} data={status} selected={props.status} onSelected={props.setStatus}></CDropdown>
			</div>
		</Col>
		<Col xs={6} className={"d-flex justify-content-end"}>
			<CSearchTextField id={"activity-logs-search"} placeholder={"Search by ID / Access IP"} onSearch={props.onSearch}/>
		</Col>
	</Row>;
}