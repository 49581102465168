import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {
	Dto_GetFeeTransactionList, Dto_GetFeeTransactionListResult,
	Dto_GetRealizedFee,
	Dto_GetRealizedFeeResult,
	Dto_GetTokensResult, Dto_GetUnrealizedFee, Dto_GetUnrealizedFeeResult,
	Dto_GetWalletsByToken,
	Dto_GetWalletsByTokenResult
} from "./token_dto";
import {PagingInfoResponse} from "../../model/response_model";

export class TokenApi {
	private static instance: TokenApi
	private constructor() {}

	public static async getUnrealizedFee(params:Dto_GetUnrealizedFee):
		Promise<{code: string, msg: string, data: { paging: PagingInfoResponse,  list: Dto_GetUnrealizedFeeResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TRANSACTIONS_FEES,params)
	}

	public static async getTokens():
		Promise<{code: string, msg: string, data: { list: Dto_GetTokensResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TOKENS);
	}

	public static async getWalletsByToken(tokenId: number, params?: Dto_GetWalletsByToken):
		Promise<{code: string, msg: string, data: { list: Dto_GetWalletsByTokenResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TOKENS_ID_WALLETS(tokenId.toString()), params);
	}

	public static async getFeesSummary(data:Dto_GetRealizedFee):
		Promise<{code: string, msg: string, data:Dto_GetRealizedFeeResult , success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_SUMMARY,data);
	}

	public static async getFeeTransactionList(params:Dto_GetFeeTransactionList):
	Promise<{code: string, msg: string, data : { pagingInfo : PagingInfoResponse, list : Dto_GetFeeTransactionListResult[]}, success: boolean} > {
		return BaseHttp.get(ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_HISTORIES,params);
	}
}