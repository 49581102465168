import {Button, Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import {useEffect, useState} from "react";
import CBreadCrumb from "../../../components/CBreadCrumb";
import CTextField from "../../../components/CTextField";
import CDropdown from "../../../components/CDropdown";
import {useNavigate} from "react-router-dom";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult, IDropdown_Dto_GetTokenByNetworkResult} from "../../../../model/custom_dropdown";
import {Dto_GetMainnetResult, Dto_GetTokensByNetworkResult} from "../../../../api/network/network_dto";
import {Dto_CreateNewWallet} from "../../../../api/wallet/wallet_dto";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";
import CLoadingDimmer from "../../../components/CLoadingDimmer";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";

export default function WalletManagementCreateAddHotWalletPage() {

	const [loading, setLoading] = useState(false);
	const [showTfa, setShowTfa] = useState(false);
	const [walletName, setWalletName] = useState('');
	const [mainnet, setMainnet] = useState<IDropdown_Dto_GetMainnetResult>();
	const [mainnetDropdownList, setMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [token, setToken] = useState<IDropdown_Dto_GetTokenByNetworkResult>();
	const [tokenDropdownList, setTokenDropdownList] = useState<IDropdown_Dto_GetTokenByNetworkResult[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		fetchMainnetList().then((res) => {
			const newDropdownList: IDropdown_Dto_GetMainnetResult[] = [];
			if(res.length > 0) {
				for(let i=0; i<res.length; i++) {
					const item = res[i];
					newDropdownList.push({
						name: `${item.name} (${item.id})`,
						value: item.id.toString(),
						data: item
					})
				}
				setMainnetDropdownList(newDropdownList);
			}
		}).catch((error) => {

		});
	}, [])

	async function fetchMainnetList(): Promise<Dto_GetMainnetResult[]> {
		setLoading(true);
		const result = await NetworkApi.getMainnet({pageNumber: 1, pageSize: 20,});
		setLoading(false);
		if(result && result.data.list) {
			return result.data.list;
		} else {
			return [];
		}
	}

	async function fetchTokens(networkId: number): Promise<Dto_GetTokensByNetworkResult[]> {
		if(loading) return [];
		setLoading(true)
		if(!networkId) {
			setLoading(false)
			return [];
		}
		const res = await NetworkApi.getTokensByNetwork(networkId);
		setLoading(false)
		if(res && res.success) return res.data.list;
		else return []
	}

	const onResetClicked = () => {
		setWalletName('');
		setMainnet(undefined);
	}

	const onNextClicked = () => {
		navigate('/wallet/management/add/create-step2', {
			state: {
				name: walletName,
				mainnet: mainnet?.data?.id,
				token: token?.data.tokenId,
			}
		});
	}

	async function onCreateClicked(otpCode: string) {
		if(loading) return;
		if(!mainnet || !mainnet.data) return;
		setLoading(true);
		const params: Dto_CreateNewWallet = {
			externalYn: false,
			networkId: mainnet?.data?.id,
			walletName: walletName,
			otpCode: otpCode,
		};
		const result = await WalletApi.createNewWallet(params);
		if(result.success) {
			alert(MESSAGE_CHANGES_SAVED);
			navigate('/wallet/management');
		} else {
			alert(MESSAGE_CHANGES_FAILED);
		}
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal setShow={setShowTfa} show={showTfa} onOk={onCreateClicked}/>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Add New Wallet"} secondUrl={"/wallet/management/add"}
			third={"Create Hot Wallet"} thirdUrl={"/wallet/management/add/hot"}
		/>
		<CPageTitle title={"Create New Wallet"} subtitle={""}/>
		<Row className={"d-flex justify-content-center"}>
			<Row style={{ width: "768px"}} className={"bg-white rounded p-5"}>
				<Row className={"h5 mb-5"}>Wallet Information</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Wallet Name</Col>
					<Col><CTextField id={'wallet-management-create-step1-wallet-name'} text={walletName} setText={setWalletName}/></Col>
				</Row>
				<Row className={"mb-5 align-items-center"}>
					<Col>Wallet Mainnet</Col>
					<Col><CDropdown id={'wallet-management-create-step1-wallet-mainnet'} data={mainnetDropdownList}
					                selected={mainnet} onSelected={(item: IDropdown_Dto_GetMainnetResult) => {
						if(item && item.data) {
							setMainnet(item);
							setToken(undefined);
							fetchTokens(item.data.id).then((res) => {
								const newDropdownList: IDropdown_Dto_GetTokenByNetworkResult[] = [];
								if(res.length > 0) {
									for(let i=0; i<res.length; i++) {
										const token = res[i];
										newDropdownList.push({
											name: token.tokenName,
											value: token.tokenId.toString(),
											data: token
										})
									}
									setTokenDropdownList(newDropdownList);
								}
							});
						}
					}}/></Col>
				</Row>
				<Row>
					<div className={"d-flex flex-row justify-content-end"}>
						<div><Button size={"lg"} variant={"dark"} onClick={() => onResetClicked()} className={"me-3"}>Reset</Button></div>
						<div><Button size={"lg"} variant={"dark"} disabled={!walletName || !mainnet}
						             onClick={() => setShowTfa(true)}>Add</Button></div>
					</div>
				</Row>
			</Row>
		</Row>
	</Container>
}