import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSearchTextField from "../../components/CSearchTextField";

export interface ILogDataPageFilter {
	listDisplay: IDropdown
	setListDisplay: Function
	blockchainStatus: IDropdown
	setBlockchainStatus: Function
	token: IDropdown
	setToken: Function
	systemStatus: IDropdown
	setSystemStatus: Function
	onSearch: Function
}

const listDisplayData = [
	{
		name: "25",
		value: "25",
	},
	{
		name: "50",
		value: "50",
	},
	{
		name: "100",
		value: "100",
	}
]

const blockchainStatusData = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'READY',
		value: 'READY',
	},
	{
		name: 'SENDING',
		value: 'SENDING',
	},
	{
		name: 'PENDING',
		value: 'PENDING',
	},
	{
		name: 'COMPLETE',
		value: 'COMPLETE',
	},
]

const systemStatusData = [
	{
		name: 'All',
		value: undefined,
	},
	{
		name: 'APPROVED',
		value: 'APPROVED',
	},
	{
		name: 'UNRECOGNIZED',
		value: 'UNRECOGNIZED',
	},
	{
		name: 'REFUND',
		value: 'REFUND',
	},
	{
		name: 'FAILED',
		value: 'FAILED',
	},
]

const tokenData = [
	{
		name: 'All',
		value: undefined,
	},
]

export default function WalletDistributeHistoryPageFilter(props: ILogDataPageFilter) {
	return <Row className="mb-3 align-items-end">
		<Col xs={6}>
			<div className={"d-flex justify-content-between"}>
				<CDropdown id={"wallet-distribute-history-dropdown-system-status"} hint={"System Status"} width={150} data={systemStatusData} selected={props.systemStatus} onSelected={props.setSystemStatus}></CDropdown>
				<CDropdown id={"wallet-distribute-history-dropdown-blockchain-category"} hint={"Blockchain Status"} width={150} data={blockchainStatusData} selected={props.blockchainStatus} onSelected={props.setBlockchainStatus}></CDropdown>
				<CDropdown id={"wallet-distribute-history-dropdown-token"} hint={"Token"} width={150} data={tokenData} selected={props.token} onSelected={props.setToken}></CDropdown>
				<CDropdown id={"wallet-distribute-history-dropdown-list-display"} hint={"List Display"} width={150} data={listDisplayData} selected={props.listDisplay} onSelected={props.setListDisplay}></CDropdown>
			</div>
		</Col>
		<Col xs={6} className={"d-flex justify-content-end"}>
			<CSearchTextField id={"activity-logs-search"} placeholder={"Wallet Name / Wallet Address"} onSearch={props.onSearch}/>
		</Col>
	</Row>;
}