import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../api/wallet_connect/wallet_connect_dto";
import {getCurrencyUnit, weiToGwei} from "../../utils/util";
import {Link, useNavigate} from "react-router-dom";
import link from "assets/icons/link.png";

interface ICLinkExplorer{
	target: string;
	type: "address"|"tx"
	address: string
	text: string
	copyText: string
	width?: number
}


export default function CLinkExplorer(props : ICLinkExplorer) {
	const text = props.text?props.text:"-";

	const linkExplorer = ()=>{

		let destination;
		if(props.target?.toLowerCase() === "usdt"|| props.target?.toLowerCase() === "eth" || props.target?.toLowerCase() === "ethereum"){
			destination = "etherScan";
		}else if(props.target?.toLowerCase() === "gpd" || props.target?.toLowerCase() === "gpex"){
			destination = "gpexScan";
		}

		if(destination === "etherScan"){
			if(props.type === "address"){
				return window.open(`${process.env.REACT_APP_ETHERSCAN_URL}/address/${props?.address}`, '_blank', 'noopener, noreferrer');
			}else if(props.type === "tx"){
				return window.open(`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${props?.address}`, '_blank', 'noopener, noreferrer');
			}
		}else if(destination === "gpexScan"){
			if(props.type === "address"){
				return window.open(`${process.env.REACT_APP_GPEXSCAN_URL}/address/${props?.address}`, '_blank', 'noopener, noreferrer');
			}else if(props.type === "tx"){
				return window.open(`${process.env.REACT_APP_GPEXSCAN_URL}/tx/${props?.address}`, '_blank', 'noopener, noreferrer');
			}
		}
	}
	return (
		<>
			<div style={{color:`#F4B12E`,cursor:"pointer" ,textAlign:"center"}}>
				<span className="d-flex" style={{width: props.width ? `${props.width}px` : undefined,
						wordWrap: "break-word", wordBreak: "break-all",textAlign:"start"}}>
					<img
						src={link} width="18" height="18" alt="link"
						color={"#F4B12E"} style={{ marginTop: "3px", marginRight: "4px", }}
						onClick={()=>{
							linkExplorer();
						}}/>
					<div onClick={ async () => {
						try {
							if(!props.copyText) {
								if(props.text) await navigator.clipboard.writeText(props.text);
								alert("Copied to clipboard.");
								return;
							}
							await navigator.clipboard.writeText(props.copyText);
							alert("Copied to clipboard.");
						} catch (e) {
							alert("Couldn't copy to clipboard.");
						}
					}}>
						{text}
					</div>
				</span>
			</div>
		</>
	)
}