import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'styles/header.css';
import logo from 'assets/gpex_icon.png';
import { useNavigate } from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {SIDEBAR_MENU_ITEMS} from "../../constants/menu_items";
import {TokenManager} from "../../states/token";
import {checkLogin} from "../../utils/session";
import {authDisplayTimeSelector, authUserState, IAuthUser} from "../../states/auth_user_atom";
import {useRecoilState, useRecoilValue} from "recoil";
import {AuthTimer} from "../../states/AuthTimer";
import CLoadingDimmer from "./CLoadingDimmer";
import CModal from "./modal/CModal";
import {AuthApi} from "../../api/auth/AuthApi";

export default function CHeader() {
  const menuData = SIDEBAR_MENU_ITEMS;
  const [loading, setLoading] = useState(false);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState<IAuthUser|undefined>(authUserState);
  const time = useRecoilValue(authDisplayTimeSelector);
  const timeout = parseInt(process.env.REACT_APP_AUTH_TIMEOUT ?? "0") ?? 0;

  const onNavigate = async (href: string) => {
    if(await checkLogin()) {
      navigate(href);
    } else {
      navigate("/login");
    }
  };

  const logout = async () => {
    TokenManager.setAccessToken('');
    TokenManager.setRefreshToken('');
    AuthTimer.getInstance().stop();
    navigate('/login');
  }

  async function refresh() {
    const refreshToken = TokenManager.getRefreshToken();
    if(loading || !refreshToken) return;
    setLoading(true);
    AuthApi.refreshSession(refreshToken).then((res) => {
      TokenManager.setAccessToken(res.data.accessToken);
      TokenManager.setRefreshToken(res.data.refreshToken);
      AuthTimer.getInstance().restart();
    }).catch((error) => {

    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light" fixed={"top"}>
      <Container>
        <CLoadingDimmer loading={loading} setLoading={setLoading}/>
        <CModal title={"Extend Timer"} body={"Do you want to extend remain time?"} show={showExtendModal} setShow={setShowExtendModal} onOk={() => {
          refresh();
        }}/>
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="GPEX"
            style={{
              marginRight: "8px"
            }}
          />
        <Navbar.Brand onClick={() => {onNavigate('/dashboard')}}>GPEX Admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => {onNavigate('/dashboard')}}>Dashboard</Nav.Link>
            {
              menuData.map((menuItem) => {
                return <div key={menuItem.title}>
                  <NavDropdown title={menuItem.title} id="collasible-nav-dropdown">
                    {menuItem.items.map((item) => {
                      return <NavDropdown.Item key={item.url} onClick={() => {onNavigate(item.url)}}>{item.title}</NavDropdown.Item>
                    })}
                  </NavDropdown>
                </div>;
              })
            }
          </Nav>
          <Nav>
            <div style={{ display: "flex", alignItems: "center"}}>
              <div style={{ marginRight: "16px"}}>
                { userInfo
                    ? <div style={{ display: "flex"}}>
                        <div style={{ fontWeight: "bold", marginRight: "16px"}}>{`${userInfo?.name}`}</div>
                      { (timeout-Math.floor(time/1000)) > 0 && (timeout-Math.floor(time/1000)) < 1000*60*60*24
                         ? <div style={{ marginRight: "16px"}}>{`until automatic logout ${Math.floor((timeout-Math.floor(time/1000))/60)}:${((timeout-Math.floor(time/1000))%60).toString().padStart(2, '0')}`}</div>
                          : <div style={{ marginRight: "16px"}}>{`-`}</div>
                      }
                        <div style={{ color: "blue", cursor: "pointer" }} onClick={() => {
                          setShowExtendModal(true);
                        }}>{`Time Extend`}</div>
                    </div>
                    : <div>no userInfo</div>
                }
              </div>
              {userInfo
                  ? <Nav.Link style={{ border: "1px solid #cccccc", borderRadius: "8px" }} onClick={logout}>Logout</Nav.Link>
                  : <Nav.Link style={{ border: "1px solid #cccccc", borderRadius: "8px" }} onClick={() => {onNavigate('/login')}}>Login</Nav.Link>}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
  