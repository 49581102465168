import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../../api/wallet_connect/wallet_connect_dto";
interface ITransactionDetailPageChargeFee {
	detail? : Dto_GetTransactionDetail
}

export default function TransactionDetailPageChargeFee(props : ITransactionDetailPageChargeFee) {
	return <Container>
		<Row style={{ width: "620px", height: "200px" }} className={"p-4 mb-4 bg-white rounded d-flex flex-column align-items-start"}>
			<Row className={"h4 mb-3 pb-3 border-2 border-bottom"}>Charge Fee (User Deducted)</Row>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Gas Coverage Fee</div>
				<div>{props.detail?.userNetworkFee} {props.detail?.withdrawalTokenName?props.detail.withdrawalTokenName:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center"}>
				<div>Coverage Fee</div>
				<div>{props.detail?.userConversionFee} {props.detail?.withdrawalTokenName?props.detail.withdrawalTokenName:"-"}</div>
			</div>
		</Row>
	</Container>
}