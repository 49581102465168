import {Button, Col, Container, Row} from "react-bootstrap";
import CListPage from "../../components/CListPage";
import CPageTitle from "../../components/CPageTitle";
import ConnectSettingsPageListItem  from "./ConnectSettingsPageListItem";
import ConnectSettingsPageListHeader from "./ConnectSettingsPageListHeader";
import CBreadCrumb from "../../components/CBreadCrumb";
import CTextFormField from "../../components/CSearchTextField";
import CTotalCount from "../../components/CTotalCount";
import {useEffect, useState} from "react";
import {WalletConnectApi} from "../../../api/wallet_connect/WalletConnectApi";
import {Dto_GetWalletConnectionResult} from "../../../api/wallet_connect/wallet_connect_dto";
import { useNavigate } from "react-router-dom";
import CLoadingDimmer from "pages/components/CLoadingDimmer";

export default function ConnectSettingsPage() {
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<Dto_GetWalletConnectionResult[]>([]);
	const [totalCount, setTotalCount] = useState(0);

	const generateItem = (value: Dto_GetWalletConnectionResult) => {
		return <ConnectSettingsPageListItem key={JSON.stringify(value)} {...value} />
	}

	const navigate = useNavigate();
	const createBtnClicked = () => {
		navigate("/wallet-connect/settings/create", {
			state: {
				// walletConnect: props
			}
		})
	}


	useEffect(() => {
		fetchWalletConnect(1);
		
	}, [])

	async function fetchWalletConnect(page: number,inPairId?:number,) {
		if(loading) return;
		setLoading(true);
		WalletConnectApi.getWalletConnection({
			// inTokenId: inPairId?inPairId:0,
			// inWalletId: 

		}).then((res) => {
			setList(res.data.list);
			setTotalCount(res.data.list.length);
		}).catch((error) => {

		}).finally(() => {
			setLoading(false)
		});
	}

	return <Container>
		<CBreadCrumb first={"Connect Settings"} firstUrl={"/wallet-connect/settings"}/>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CPageTitle title={"Connect Settings"} subtitle={"You can manage transaction pair."}/>
		<Row className={"mb-4 d-flex justify-content-between"}>
			<Col><CTotalCount totalCount={totalCount}/></Col>
			<Col><div style={{ display: "flex", flexDirection:"column", justifyContent: "end", alignItems: "flex-end" }}>
				<CTextFormField id={"connect-settings-search"} placeholder={"Pair ID / Wallet Name"}
				                onSearch={(value: string) => {
					                fetchWalletConnect(1, parseInt(value));
				                }}
				/>
				<Button className="btn-dark mt-3 btn-lg buttonHover" onClick={createBtnClicked}>Create</Button>	
			</div>
			
			</Col>
		</Row>
		<ConnectSettingsPageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}