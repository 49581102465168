import {Button, Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import {useEffect, useRef, useState} from "react";
import CBreadCrumb from "../../../components/CBreadCrumb";
import CTextField from "../../../components/CTextField";
import CDropdown from "../../../components/CDropdown";
import {useNavigate} from "react-router-dom";
import {Dto_CreateNewWallet} from "../../../../api/wallet/wallet_dto";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {Dto_GetMainnetResult, Dto_GetTokensByNetworkResult} from "../../../../api/network/network_dto";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {IDropdown_Dto_GetMainnetResult, IDropdown_Dto_GetTokenByNetworkResult} from "../../../../model/custom_dropdown";
import CLoadingDimmer from "../../../components/CLoadingDimmer";

export default function WalletManagementAddExternalWalletPage() {
	const navigate = useNavigate();
	const target = useRef();

	const [loading, setLoading] = useState(false);
	const [walletName, setWalletName] = useState('');
	const [mainnet, setMainnet] = useState<IDropdown_Dto_GetMainnetResult>();
	const [mainnetDropdownList, setMainnetDropdownList] = useState<IDropdown_Dto_GetMainnetResult[]>([]);
	const [walletAddress, setWalletAddress] = useState('');
	const [validAddress, setValidAddress] = useState(false);

	const [showTfa, setShowTfa] = useState(false);

	useEffect(() => {
		if(loading) return;
		setLoading(true);
		fetchMainnetList().then((res) => {
			const newDropdownList: IDropdown_Dto_GetMainnetResult[] = [];
			if(res.length > 0) {
				for(let i=0; i<res.length; i++) {
					const item = res[i];
					newDropdownList.push({
						name: item.name,
						value: item.id.toString(),
						data: item
					})
				}
				setMainnetDropdownList(newDropdownList);
			}
		}).catch((error) => {

		}).finally(() => {

		})
	}, [])

	async function fetchMainnetList(): Promise<Dto_GetMainnetResult[]> {
		setLoading(true);
		const result = await NetworkApi.getMainnet({pageNumber: 1, pageSize: 20,});
		setLoading(false);
		if(result && result.data.list) {
			return result.data.list;
		} else {
			return [];
		}
	}

	async function fetchTokens(networkId: number): Promise<Dto_GetTokensByNetworkResult[]> {
		if(loading) return [];
		if(!networkId) {
			setLoading(false)
			return [];
		}
		setLoading(true)
		const res = await NetworkApi.getTokensByNetwork(networkId);
		setLoading(false)
		if(res && res.success) return res.data.list
		else return []
	}

	async function onVerifyAddress() {
		if(loading) return;
		setLoading(true)
		WalletApi.verifyAddress({
			address: walletAddress,
		}).then((res) => {
			if(res.success) {
				setValidAddress(true)
				alert("This is a valid address.");
			} else{
				setValidAddress(false)
				alert("This address is not valid.");
			}
		}).catch((error) => {

		}).finally(() => {
			setLoading(false)
		})
	}

	async function onCreateClicked(otpCode: string) {
		if(!mainnet || !mainnet.data) return;
		const networkId = mainnet.data?.id;
		const params: Dto_CreateNewWallet = {
			externalYn: true,
			walletName: walletName,
			networkId: mainnet.data.id,
			address: walletAddress,
			otpCode: otpCode,
		};
		const result = await WalletApi.createNewWallet(params);
		if(result.success) {
			alert(MESSAGE_CHANGES_SAVED);
			navigate('/wallet/management');
		} else {
			alert(MESSAGE_CHANGES_FAILED);
		}
	}

	function onReset() {
		setWalletName('');
		setMainnet(undefined);
		setWalletAddress('');
		setValidAddress(false);
	}

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CVerifyTFAModal setShow={setShowTfa} show={showTfa} onOk={onCreateClicked}/>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Add New Wallet"} secondUrl={"/wallet/management/add"}
			third={"Add Cold Wallet"} thirdUrl={"/wallet/management/add/cold"}
		/>
		<CPageTitle title={"Add Cold Wallet"} subtitle={""}/>
		<Row className={"d-flex justify-content-center"}>
			<Row style={{ width: "768px"}} className={"bg-white rounded p-5"}>
				<Row className={"h5 mb-5"}>Wallet Information</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Wallet Name</Col>
					<Col xs={8}><CTextField id={'wallet-management-create-step1-wallet-name'} text={walletName} setText={setWalletName}/></Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Wallet Mainnet</Col>
					<Col xs={8}>
						<CDropdown id={'wallet-management-create-step1-wallet-mainnet'} data={mainnetDropdownList}
						           selected={mainnet}
						           onSelected={(item: IDropdown_Dto_GetMainnetResult) => {
							if(item && item.data) {
								setMainnet(item);
							}
					}}/></Col>
				</Row>
				<Row className={"mb-5 align-items-center"}>
					<Col>Wallet Address</Col>
					<Col xs={8} className={"d-flex flex-row justify-content-end"}>
						<CTextField id={'wallet-management-create-step1-wallet-address'} text={walletAddress} setText={setWalletAddress} width={360} onChange={(str: string) => {
							setValidAddress(false);
						}}/>
						{validAddress ? <Button variant={"success"} className={"ms-2"} onChange={() => {}}>Verified</Button> :
							<Button variant={"dark"} className={"ms-2"} onClick={onVerifyAddress}>Verify</Button>}
					</Col>
				</Row>
				<Row>
					<div className={"d-flex flex-row justify-content-end"}>
						<div><Button size={"lg"} variant={"dark"} onClick={() => onReset()} className={"me-3"}>Reset</Button></div>
						<div><Button size={"lg"} variant={"dark"} disabled={!walletName || !walletAddress || !mainnet || !validAddress} onClick={() => {
							setShowTfa(true);
						}}>Add</Button></div>
					</div>
				</Row>
			</Row>
		</Row>
	</Container>
}