import {Button, Container, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import {IDropdown_Dto_GetTokensByNetworkResult} from "../../../../model/custom_dropdown";
import {NetworkApi} from "../../../../api/network/NetworkApi";
import {Dto_GetMainnetResult} from "../../../../api/network/network_dto";
import CTextField from "../../../components/CTextField";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import {Dto_GetWalletsManagementResult} from "../../../../api/wallet/wallet_dto";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";

export interface IAddTokenModalComponent {
    show: boolean
    setShow: any
    wallet: Dto_GetWalletsManagementResult
    onConfirm: Function
}

export default function WalletManagementEditPageAddTokenModal(props: IAddTokenModalComponent) {
    const [networkTokenList, setNetworkTokenList] = useState<IDropdown_Dto_GetTokensByNetworkResult[]>([]);
    const [selectedToken, setSelectedToken] = useState<IDropdown_Dto_GetTokensByNetworkResult>();
    const [mainnet, setMainnet] = useState<Dto_GetMainnetResult>();
    const [otpCode, setOtpCode] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getNetworkName();
        makeTokenDropdownList();
    }, [])

    async function getNetworkName() {
        if(!props.wallet.networkId) return;
        NetworkApi.getMainnet().then((res) => {
            const network = res.data.list.find(item => item.id === props.wallet.networkId);
            if(network) {
                setMainnet(network);
            }
        })
    }

    const makeTokenDropdownList = () => {
        if(!props.wallet.networkId) return;
        NetworkApi.getTokensByNetwork(props.wallet.networkId).then((res) => {
            const newDropdownList: IDropdown_Dto_GetTokensByNetworkResult[] = [];
            for(const item of res.data.list) {
                newDropdownList.push({
                    name: item.tokenName,
                    value: item.tokenId.toString(),
                    data: item,
                })
            }
            setNetworkTokenList(newDropdownList);
        }).catch((error) => {

        })
    }

    async function addToken() {
        if(loading) return;
        if(!otpCode) return;
        if(!props.wallet.networkId || !selectedToken) return;
        setLoading(true);
        WalletApi.addTokenToWallet(props.wallet.walletId, {
            tokenId: selectedToken.data.tokenId,
            otpCode: otpCode
        }).then((res) => {
            if(res.success) {
                alert(MESSAGE_CHANGES_SAVED);
                props.onConfirm(selectedToken.data);
                props.setShow(false);
            } else {
                alert(res.msg);
            }
        }).catch((error) => {
            alert(MESSAGE_CHANGES_FAILED);
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
                <Modal.Header closeButton style={{ border: "none" }}>
                    <Modal.Title>Add New Token</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ border: "none" }}>
                    <div className={"mb-4 text-secondary"}>
                        <div>Would you like to add new tokens to your chosen wallet?</div>
                    </div>
                    <div className={"mb-3"}>
                        <h6 className={"mb-2"}>Mainnet</h6>
                        <div style={{ width: "100%", padding: "8px 16px",
                            textAlign: "center",
                            borderRadius: "6px", border: "1px solid #dddddd", backgroundColor: "#cccccc"
                        }} >{mainnet ? mainnet.name : '-'}</div>
                    </div>
                    <div className={"mb-5"}>
                        <h6 className={"mb-2"}>Select Token</h6>
                        <CDropdown id={"add-new-token-modal-dropdown"} selected={selectedToken} data={networkTokenList}
                                   onSelected={(item: IDropdown_Dto_GetTokensByNetworkResult) => setSelectedToken(item)}/>
                    </div>
                    <div className={"mb-4"}>
                        <CTextField id={'wallet-management-edit-two-factor-authentication-code'} text={otpCode} setText={setOtpCode}
                            placeholder={"Enter Google Authentication Code"}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                    <Button
                        style={{ width: "360px"}}
                        variant="dark"
                        size={"lg"}
                        onClick={() => addToken()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};