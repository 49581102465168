import {Button, Col, Row} from "react-bootstrap";

export default function ConnectSettingsPageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col  xs={1}>Pair Id</Col>
		<Col xs={1}>In Token</Col>
		<Col xs={1}>Out Token</Col>
		<Col xs={2}>Conversion Fee</Col>
		<Col xs={2}>In Wallet</Col>
		<Col xs={2}>Out Wallet</Col>
		<Col  xs={3}>Action</Col>
	</Row>;
}