import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_GetRoleListResult} from "./role_dto";

export class RoleApi {
	private static instance: RoleApi
	private constructor() {}

	public static async getRoleList():
		Promise<{code: string, msg: string, data: { list: Dto_GetRoleListResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_ROLES);
	}
}