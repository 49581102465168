import settings from "../../assets/icons/settings.png";
import React from "react";

export interface ISettingIconComponent {
	width?: number
	onClick: any
	disabled: boolean
}

export default function CSettingButton(props: ISettingIconComponent) {
	return <div>
		<button
			style={{ width: `${props.width !== 0 && props.width !== undefined ? props.width + "px" : "120px"}`, cursor: "pointer" }}
			className={"d-flex justify-content-center bg-light rounded pt-2 pb-2 "}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			<img
				src={settings}
				width="24"
				height="24"
				alt="settings"
			/>
		</button>
	</div>
}