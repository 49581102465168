import {Button, Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../../components/CPageTitle";
import {useEffect, useState} from "react";
import CBreadCrumb from "../../../components/CBreadCrumb";
import CTextField from "../../../components/CTextField";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import {useLocation, useNavigate} from "react-router-dom";
import {WalletApi} from "../../../../api/wallet/WalletApi";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {Dto_CreateNewWallet} from "../../../../api/wallet/wallet_dto";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED} from "../../../../constants/value";
import {AdminApi} from "../../../../api/admin/AdminApi";
import {Dto_GetAdminListResult} from "../../../../api/admin/admin_dto";
import {IDropdown_Dto_GetAdminListResult} from "../../../../model/custom_dropdown";

export default function WalletManagementCreateStep2Page() {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState('');
	const [alertYn, setAlertYn] = useState<IDropdown>({
		name: 'No',
		value: 'false',
	});
	const alertYnDropdownList: IDropdown[] = [
		{
			name: 'No',
			value: 'false',
		},
		{
			name: 'Yes',
			value: 'true',
		}
	]
	const [alertAdmin, setAlertAdmin] = useState<IDropdown_Dto_GetAdminListResult>();
	const [alertAdminList, setAlertAdminList] = useState<IDropdown_Dto_GetAdminListResult[]>([]);
	const [addedAlertAdminList, setAddedAlertAdminList] = useState<Dto_GetAdminListResult[]>([]);

	const [gasPrice, setGasPrice] = useState('');
	const [gasLimit, setGasLimit] = useState('');
	const [maximumTransactionFee, setMaximumTransactionFee] = useState('');
	const [modal, setModal] = useState(false);

	const name = location.state.name ?? 'default wallet';
	const mainnet = location.state.mainnet ?? 1;
	const token = location.state.token ?? 1;

	useEffect(() => {
		fetchAdminUserList();
	}, [])

	function alertAdminUserContainer() {
		return <div className={"d-flex flex-row mt-4"}>
			{addedAlertAdminList.map((item: Dto_GetAdminListResult) => {
				return alertAdminUserItem(item);
			})}
		</div>
	};

	function alertAdminUserItem(item: Dto_GetAdminListResult) {
		return <div key={item.userId} style={{ display: "flex", flexDirection: "row", padding: "8px",
			backgroundColor: "#0A99FF", borderRadius: "8px", marginRight: "8px", cursor: "pointer" }}
			onClick={() => {
				setAddedAlertAdminList([...addedAlertAdminList.filter((i) => item.userId !== i.userId).sort((a, b) => a.userId - b.userId)])
			}}
		>
			<div style={{ color: "white", marginRight: "8px" }}>{item.userName}</div>
			<div style={{ color: "white" }}>X</div>
		</div>
	}

	async function onCreateClicked() {
		setModal(true);
		const adminList = [];
		for(const item of addedAlertAdminList) {
			adminList.push(item.userId);
		}
		const params: Dto_CreateNewWallet = {
			externalYn: false,
			walletName: name,
			networkId: mainnet,
			otpCode: '111111',
		};
		const result = await WalletApi.createNewWallet(params);
		if(result.success) {
			alert(MESSAGE_CHANGES_SAVED);
		} else {
			alert(MESSAGE_CHANGES_FAILED);
		}
	}

	async function fetchAdminUserList() {
		setLoading(true);
		AdminApi.getAdminList({
			pageNumber: 1,
			pageSize: 100,
		}).then((res) => {
			if(res.success) {
				const newDropdownList: IDropdown_Dto_GetAdminListResult[] = [];
				for(let i=0; i<res.data.list.length; i++) {
					const item: Dto_GetAdminListResult = res.data.list[i];
					newDropdownList.push({
						name: `${item.userName} (${item.roleId})`,
						value: item.userId.toString(),
						data: item
					});
				}
				setAlertAdminList(newDropdownList);
			}
		}).catch((error) => {

		}).finally(() => {
			setLoading(false);
		});
	}

	function onGasPriceChanged(textGasPrice: string) {
		try {
			if(textGasPrice && gasLimit) {
				const numGasPrice = parseFloat(textGasPrice);
				const numGasLimit = parseFloat(gasLimit);
				setMaximumTransactionFee((numGasPrice * numGasLimit).toString());
			}
		} catch(e) {

		}
	}

	function onGasLimitChanged(textGasLimit: string) {
		try {
			if(gasPrice && textGasLimit) {
				const numGasPrice = parseFloat(gasPrice);
				const numGasLimit = parseFloat(textGasLimit);
				setMaximumTransactionFee((numGasPrice * numGasLimit).toString());
			}
		} catch(e) {

		}
	}

	return <Container>
		<CVerifyTFAModal setShow={setModal} onOk={() => {}} show={modal}/>
		<CBreadCrumb
			first={"Wallet Management"} firstUrl={"/wallet/management"}
			second={"Add New Wallet"} secondUrl={"/wallet/management/add"}
			third={"Create New Wallet:Step 2"} thirdUrl={"/wallet/management/add/step2"}
		/>
		<CPageTitle title={"Create New Wallet"} subtitle={"Step 2/2"}/>
		<Row className={"d-flex justify-content-center mb-4"}>
			<Row style={{ width: "768px"}} className={"bg-white rounded p-5"}>
				<Row className={"h5 mb-5"}>Other Settings</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Minimum Limit Alert</Col>
					<Col>
						<CTextField id={'wallet-management-create-step2-minimum-limit-alert'} text={limit} setText={setLimit}/>
					</Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Alert Generated</Col>
					<Col><CDropdown id={'wallet-management-create-step1-wallet-mainnet'} data={alertYnDropdownList} selected={alertYn} onSelected={setAlertYn}/></Col>
				</Row>
				<Row className={"mb-2 align-items-center"}>
					<Col>Alert Sent to</Col>
					<Col><CDropdown id={'wallet-management-create-step1-wallet-select-token'} data={alertAdminList} selected={alertAdmin}
					                onSelected={(item: IDropdown_Dto_GetAdminListResult) => {
						setAlertAdmin(item);
						if(addedAlertAdminList.filter((i) => item.data.userId === i.userId).length > 0) return;
						setAddedAlertAdminList([...addedAlertAdminList, item.data].sort((a, b) => a.userId - b.userId));
					}}/></Col>
				</Row>
				<div>{alertAdminUserContainer()}</div>
			</Row>
		</Row>
		<Row className={"d-flex justify-content-center mb-5"}>
			<Row style={{ width: "768px"}} className={"bg-white rounded p-5"}>
				<Row className={"h5"}>Set Gas Fee</Row>
				<Row className={"fs-6 mb-5"}>You can set a gas fee to use when withdrawing.</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Gas Price</Col>
					<Col><CTextField id={'wallet-management-create-step2-gas-price'} text={gasPrice} setText={(text: string) => {
						setGasPrice(text);
						onGasPriceChanged(text);
					}} unit={"Gwei"}/></Col>
				</Row>
				<Row className={"mb-4 align-items-center"}>
					<Col>Gas Limit</Col>
					<Col><CTextField id={'wallet-management-create-step2-gas-limit'} text={gasLimit} setText={(text: string) => {
						setGasLimit(text)
						onGasLimitChanged(text);
					}}/></Col>
				</Row>
				<Row className={"mb-5 align-items-center"}>
					<Col>Maximum Transaction Fee</Col>
					<Col><CTextField id={'wallet-management-create-step2-maximum-transaction-fee'}
					                 text={maximumTransactionFee} setText={setMaximumTransactionFee}
					                 disabled={true}
					/></Col>
				</Row>
				<Row>
					<div className={"d-flex flex-row justify-content-end"}>
						<div><Button size={"lg"} variant={"secondary"} disabled={true} className={"me-3"}>Reset</Button></div>
						<div><Button size={"lg"} variant={"dark"} onClick={onCreateClicked}>Create</Button></div>
					</div>
				</Row>
			</Row>
		</Row>
	</Container>
}