import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../../api/wallet_connect/wallet_connect_dto";
import {getCurrencyUnit, getFloatingPointNumber, weiToGwei} from "../../../utils/util";
import {Link} from "react-router-dom";
import CLinkExplorer from "../../components/CLinkExplorer";

interface ITransactionDetailPageTransactionInformationDeposit{
	detail? : Dto_GetTransactionDetail
}




export default function TransactionDetailPageTransactionInformationDeposit(props:ITransactionDetailPageTransactionInformationDeposit) {

	return <Container>
		<Row style={{ width: "620px", height: "400px" }} className={"p-4 mb-4 bg-white rounded d-flex flex-column align-items-start"}>
			<Row className={"h4 mb-3 pb-3 border-2 border-bottom"}>Transaction Information (Deposit)</Row>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Amount</div>
				<div>{props.detail?.depositAmount?props.detail.depositAmount:"-"} {props.detail?.depositTokenName}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>From</div>
				<div>
					<CLinkExplorer
						target={props.detail?.depositTokenName ?? ""} type="address" address={props.detail?.depositFromAddress ?? ""}
						text={props.detail?.depositFromAddress ?? ""}
						copyText={props.detail?.depositFromAddress ?? ""}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>To</div>
				<div>
					<CLinkExplorer
						target={props.detail?.depositTokenName ?? ""} type="address" address={props.detail?.depositToAddress ?? ""}
						text={props.detail?.depositWalletName ?? ""}
						copyText={props.detail?.depositToAddress ?? ""}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>TxID</div>
				<div>
					<CLinkExplorer
						target={props.detail?.depositTokenName ?? ""} type="tx" address={props.detail?.depositTxId ?? ""}
						text={props.detail?.depositTxId ?? ""}
						copyText={props.detail?.depositTxId ?? ""}
						width={380}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Mainnet</div>
				<div>{props.detail?.depositNetworkName?props.detail.depositNetworkName:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Gas Fee</div>
				<div>{props.detail?.depositFee?getFloatingPointNumber(props.detail.depositFee, 18):"-"} {getCurrencyUnit(props.detail?.depositNetworkName?props.detail.depositNetworkName:"-")}</div>


			</div>
		</Row>
	</Container>
}