import deleteIcon from "../../assets/icons/delete.png";
import React from "react";

export interface IDeleteIconComponent {
	width?: number
	onClick: any
	disabled: boolean
}

export default function CDeleteButton(props: IDeleteIconComponent) {
	return <div>
		<button
			style={{ width: `${props.width !== 0 && props.width !== undefined ? props.width + "px" : "120px"}`, cursor: "pointer" }}
			className={"d-flex justify-content-center bg-light rounded pt-2 pb-2"}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			<img
				src={deleteIcon}
				width="24"
				height="24"
				alt="delete"
			/>
		</button>
	</div>
}