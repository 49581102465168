import {Container} from "react-bootstrap";
import TransactionApprovePageFilter from "./TransactionApprovePageFilter";
import {IDropdown} from "../../components/CDropdown";
import {useEffect, useState} from "react";
import {WalletConnectApi} from "../../../api/wallet_connect/WalletConnectApi";
import {Dto_GetTransactionApproveResult} from "../../../api/wallet_connect/wallet_connect_dto";
import {checkValidResult} from "../../../utils/session";
import TransactionApprovePageListHeader from "./TransactionApprovePageListHeader";
import CListPage from "../../components/CListPage";
import TransactionApprovePageListItem from "./TransactionApprovePageListItem";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import { NavigateFunction } from "react-router-dom";
import CSimplePagination from "../../components/CSimplePagination";



export default function TransactionApprovePageFailed({loading, setLoading, navigate}: {loading: boolean, setLoading: Function, navigate: NavigateFunction}){
    const [index, setIndex] = useState(1);
    const [list, setList] = useState<Dto_GetTransactionApproveResult[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [ pageSize, setPageSize ] = useState(25)
    const generateItem = (value : Dto_GetTransactionApproveResult) =>{
        return <TransactionApprovePageListItem key ={value.txPairId?.toString()} {...value}/>
    }

    const [listAmountData,setListAmountData] = useState<IDropdown>({
        name : "25",
        value : "25"
    });
    const [sortData, setSortData] = useState<IDropdown>({
        name : "Deposit (Recent)",
        value : "DATE_DESC"
    })

    useEffect(() => {
        fetchTransactionList(1)
    }, [])


    async function fetchTransactionList(page:number,search?: string){
        if(loading) return;
        setLoading(true);
        WalletConnectApi.getTransactionApprove({
            status: "FAILED",
            sort: sortData.value,
            pageNumber : page,
            pageSize : listAmountData.value ? parseInt(listAmountData.value) : 25,
            txPairId : search ? parseInt(search) : undefined
        }).then((result) => {
            if (result && result.data.list) {
                if(listAmountData.value) setPageSize(parseInt(listAmountData.value))
                setList(result.data.list);
                setTotalCount(result.data.pagingInfo.totalCount);
            }else{
                setList([]);
                setTotalCount(0);
            }
        }).catch((e) =>{
            setList([]);
            setTotalCount(0);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Container>
            <CLoadingDimmer loading={loading} setLoading={setLoading}/>
            <CSimplePagination
                index={index}
                setIndex={setIndex}
                loading={loading}
                onSubmit={(page: number) => fetchTransactionList(page)}
                totalCount={totalCount}
                pageSize={listAmountData.value ? parseInt(listAmountData.value) : 25}
            />
            <TransactionApprovePageFilter listAmountData={listAmountData} setListAmountData={setListAmountData} sortData={sortData} setSortData={setSortData}
                    onSearch={(search:string)=>{
                        fetchTransactionList(1,search)
                    }}
            />
            <TransactionApprovePageListHeader/>
            {list.length === 0 ? "No Data" : ""}
            <CListPage list={list} itemGenerator={generateItem} />

        </Container>
    )

}
