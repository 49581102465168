import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";
import {
	Dto_GetMainnet,
	Dto_GetMainnetResult,
	Dto_getNetworkGasFeeResult,
	Dto_getNetworkGasPriceResult, Dto_GetTokenByNetworkAndWalletResult,
	Dto_GetTokensByNetworkResult,
	Dto_GetWalletByNetworkResult,
	Dto_setNetworkGasFee
} from "./network_dto";

export class NetworkApi {
	private static instance: NetworkApi
	private constructor() {}

	public static async getMainnet(params?: Dto_GetMainnet ):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetMainnetResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS, params);
	}

	public static async getTokensByNetwork(networkId: number):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetTokensByNetworkResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS_ID_TOKENS(networkId.toString()));
	}

	public static async getNetworkGasPrice(networkId: number):
		Promise<{code: string, msg: string, data: Dto_getNetworkGasPriceResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS_ID_GAS_PRICE(networkId.toString()));
	}

	public static async getNetworkGasFee(networkId: number):
		Promise<{code: string, msg: string, data: Dto_getNetworkGasFeeResult, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS_ID_GAS_FEE(networkId.toString()));
	}

	public static async setNetworkGasFee(networkId: number, data: Dto_setNetworkGasFee):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_NETWORKS_ID_GAS_FEE(networkId.toString()), data);
	}

	public static async getWalletByMainnet(networkId: string, params: any):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetWalletByNetworkResult[] }, success: boolean}> {
		return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS_ID_WALLETS(networkId), params);
	}

	public static async getTokenByMainnetAndWallet(networkId: string, walletId: string, params:any):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetTokenByNetworkAndWalletResult[] }, success: boolean}> {
	    return BaseHttp.get(ApiUrl.API_PRIVATE_NETWORKS_ID_WALLETS_ID_TOKENS(networkId, walletId), params);
    }
}