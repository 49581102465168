import {Button, Container, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {AdminApi} from "../../../../api/admin/AdminApi";
import {Dto_GetAdminListResult} from "../../../../api/admin/admin_dto";
import {RoleApi} from "../../../../api/role/RoleApi";
import CDropdown, {IDropdown} from "../../../components/CDropdown";

export interface IAdminSettingsRoleModalComponent {
    show: boolean
    setShow: any
    onConfirm: any
    user?: Dto_GetAdminListResult
}

export default function AdminSettingRoleModal(props: IAdminSettingsRoleModalComponent) {
    const [tfa, setTfa] = useState(false);

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<IDropdown|undefined>();
    const [list, setList] = useState<IDropdown[]>([]);

    async function fetchRoleList() {
        if(loading) return;
        setLoading(true);
        RoleApi.getRoleList().then((result) => {
            if(result.success) {
                const newList: IDropdown[] = [];
                for(let i=0; i<result.data.list.length; i++) {
                    newList.push({
                        name: result.data.list[i].roleName,
                        value: result.data.list[i].roleId.toString(),
                    })
                }
                setList(newList);
            }
            else {
                setList([]);
            }
        }).catch((error) => {
            setList([]);
        }).finally(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        fetchRoleList();
    }, [])

    return (
        <>
            <CVerifyTFAModal show={tfa} setShow={setTfa} onOk={(code: string) => {
                if(!props.user) return;
                if(!selected) return;
                AdminApi.setAdminRole(props.user.userId, {
                    roleId: parseInt(selected.value!),
                    otpCode: code,
                }).then((result) => {
                    if(result.success) {
                        if(props.onConfirm) props.onConfirm(true);
                    }
                    else {
                        alert(result.msg);
                        return;
                    }
                }).catch((e) => {
                    alert(e);
                    return;
                })
            }}/>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
            <Modal.Title>Admin Role Management</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none" }}>
                <Container>
                    <div className={"text-secondary mb-3"}>Are you sure you want to Change the Role of the selected Admin?</div>
                    <CDropdown id={'admin-settings-role-management-modal-dropdown'} data={list}
                               selected={selected}
                               onSelected={(item: IDropdown) => {
                        setSelected(item);
                    }}/>
                </Container>
            </Modal.Body>
            <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                <Button
                    style={{ width: "360px"}}
                    variant="dark"
                    size={"lg"}
                    onClick={() => {
                        if(!selected) return;
                        props.setShow(false);
                        setTfa(true);
                    }}>{props.onConfirm ?? "Confirm"}
                    Change
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};