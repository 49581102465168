import {Button, Container, Tab, Tabs} from "react-bootstrap";
import CPageTitle from "../../components/CPageTitle";
import CBreadCrumb from "../../components/CBreadCrumb";
import CommissionRecordPageFeeSummary from "./CommissionRecordPageFeeSummary";
import {useState} from "react";
import FeeRecordPageUnrealizedFee from "./unrealized_fee/FeeRecordPageUnrealizedFee";
import FeeRecordPageRealizedCommissionHistory from "./realizedCommissionHistory/FeeRecordPageRealizedCommissionHistory";

export default function CommissionRecordPage() {
	return <Container>
		<CBreadCrumb first={"Commission Record"} firstUrl={"/wallet-connect/realize"}/>
		<CPageTitle title={"Commission Record"} subtitle={""}/>
		<Tabs
			defaultActiveKey="fee_summary"
			id="fee_record_tabs"
			className="mb-3"
		>
			<Tab tabClassName="bg-white text-black" eventKey="fee_summary" title="Commission Summary">
				<CommissionRecordPageFeeSummary/>
			</Tab>
			<Tab tabClassName="bg-white text-black" eventKey="unrealized_fee" title="Unrealized Commission">
				<FeeRecordPageUnrealizedFee/>
			</Tab>
			<Tab tabClassName="bg-white text-black" eventKey="realized_commission_history" title="Realized Commission History">
				<FeeRecordPageRealizedCommissionHistory/>
			</Tab>
		</Tabs>
	</Container>
}