import {Col, Row} from "react-bootstrap";

export default function TransactionApprovePageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Swap Tx ID<br/>(Wallet Connect)</Col>
		<Col>Date (Deposit)</Col>
		<Col>Amount / Token (Mainnet)</Col>
		<Col></Col>
		<Col>Date<br/>(Withdrawal)</Col>
		<Col>Amount / Token<br/>(Mainnet)</Col>
		<Col>Commission<br/>(Mainnet)</Col>
		<Col>Status<br/>(System/Blockchain)</Col>
		</Row>
}