import {Button, Container, Row} from "react-bootstrap";
import {Dto_GetTransactionDetail} from "../../../api/wallet_connect/wallet_connect_dto";
import {getCurrencyUnit, getFloatingPointNumber, weiToGwei} from "../../../utils/util";
import {Link, useNavigate} from "react-router-dom";
import CLinkExplorer from "../../components/CLinkExplorer";

interface ITransactionDetailPageTransactionInformationWithdraw{
	detail ?: Dto_GetTransactionDetail
}


export default function TransactionDetailPageTransactionInformationWithdraw(props : ITransactionDetailPageTransactionInformationWithdraw) {
	
	return <Container>
		<Row style={{ width: "620px", height: "400px" }} className={"p-4 mb-4 bg-white rounded d-flex flex-column align-items-start"}>
			<Row className={"h4 mb-3 pb-3 border-2 border-bottom"}>Transaction Information (Withdraw)</Row>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Amount</div>
				<div>{props.detail?.withdrawalAmount?props.detail.withdrawalAmount:"-"} {props.detail?.withdrawalTokenName}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>From</div>
				<div>
					<CLinkExplorer
						target={props.detail?.withdrawalTokenName ?? ""} type="address"
						address={props.detail?.withdrawalFromAddress ?? ""}
						text={props.detail?.withdrawalWalletName ?? ""}
						copyText={props.detail?.withdrawalFromAddress ?? ""}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>To</div>
				<div>
					<CLinkExplorer
						target={props.detail?.withdrawalTokenName ?? ""} type="address"
						address={props.detail?.withdrawalToAddress ?? ""}
						text={props.detail?.withdrawalToAddress ?? ""}
						copyText={props.detail?.withdrawalToAddress ?? ""}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>TxID</div>
				<div>
					<CLinkExplorer
						target={props.detail?.withdrawalTokenName ?? ""} type="tx"
						address={props.detail?.withdrawalTxId ?? ""}
						text={props.detail?.withdrawalTxId ?? ""}
						copyText={props.detail?.withdrawalTxId ?? ""} width={380}/>
				</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Mainnet</div>
				<div>{props.detail?.withdrawalNetworkName?props.detail.withdrawalNetworkName:"-"}</div>
			</div>
			<div className={"d-flex justify-content-between align-items-center mb-3"}>
				<div>Gas Fee</div>
				<div>{props.detail?.withdrawalFee?getFloatingPointNumber(props.detail.withdrawalFee, 18):"-"} {getCurrencyUnit(props.detail?.withdrawalNetworkName?props.detail.withdrawalNetworkName:"-")}</div>
			</div>
		</Row>
	</Container>
}