import CHeader from "pages/components/CHeader";
import { Outlet } from "react-router-dom";
import CSidebar from "./components/CSidebar";
import 'styles/global.css'
import {useRef} from "react";

export default function MainLayout() {
    const target = useRef(null);

    return <>
        <div>
            <div>
                <CHeader key="main-header"/>
            </div>
            <div className="main-layout" ref={target}>
                <CSidebar/>
                <div className="page-layout page-side-padding">
                    <Outlet/>
                </div>
            </div>
        </div>
    </>
}