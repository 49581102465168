import {Col, Row} from "react-bootstrap";

export default function FeeRecordPageRealizedCommissionHistoryHeader() {
    return <Row className="p-3 mb-2 text-light bg-dark h6">
        <Col>Date</Col>
        <Col xs={2}>Tx Pair Id</Col>
        <Col>Gas Coverage Commission</Col>
        <Col>Conversion Commission</Col>
        <Col>Status</Col>
        <Col>Download</Col>
    </Row>
}