import CPageTitle from "../../components/CPageTitle";
import {Container} from "react-bootstrap";
import CBreadCrumb from "../../components/CBreadCrumb";
import GPDMintPageMintCard from "./GPDMintPageMintCard";
import GPDMintPageStatusCard from "./GPDMintPageStatusCard";
import {useEffect, useState} from "react";

export default function GPDMintPage() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {

	}, [])

	return <Container>
		<CBreadCrumb first={"GPD Mint/Burn"} firstUrl={"/contract/mint"}/>
		<CPageTitle title={"GPD Mint/Burn"} subtitle={""}/>
		<GPDMintPageMintCard loading={loading} setLoading={setLoading}/>
		<GPDMintPageStatusCard loading={loading} setLoading={setLoading}/>
	</Container>
}