import {Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

import 'styles/login.css';

import { useState } from "react";
import {AuthApi} from "../../api/auth/AuthApi";
import CVerifyLoginTFAModal from "../components/modal/CVerifyLoginTFAModal";
import CRegisterTFAModal from "../components/modal/CRegisterTFAModal";
import {UserSecurityApi} from "../../api/user_security/UserSecurityApi";
import logoTitle from "../../assets/logo_title.png"

function LoginPage() {
  const navigate = useNavigate();

  const [otpImageUrl, setOtpImageUrl] = useState('');
  const [otpKey, setOtpKey] = useState('');
  const [registerTFAModal, setRegisterTFAModal] = useState(false);
  const [verifyTFAModal, setVerifyTFAModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const version = process.env.REACT_APP_VERSION;
  const buildEnv = process.env.REACT_APP_BUILD_ENV;

  async function onLoginButtonClicked(e: any) {
    e.preventDefault();
    if(loading) return;
    setLoading(true);
    // 1차 로그인
    const result = await AuthApi.newSessionStep1({
      authType: "login",
      email: email,
      password: password,
      grantType: "password",
    })

    if(result.success) {

    }
    // 1차 로그인 실패
    else {
      // OTP 미등록 >> OTP 등록 프로세스
      if(result.code === "1010" && result.data.otpStatus === "DISABLED") {
        setLoading(false);
        UserSecurityApi.getOtpSettingInfo({ email: result.data.email }).then((res) => {
          setOtpImageUrl(res.data.otpQrImgUrl);
          setOtpKey(res.data.otpKey);
          setRegisterTFAModal(true);
        });
        return;
      }
      // 이미 OTP 등록됨 >> 2차 로그인
      else if(result.code === "1010" && result.data.otpStatus === "ENABLED") {
        setLoading(false);
        setVerifyTFAModal(true);
      }
      else {
        setLoading(false);
        alert(result.msg);
        return;
      }
    }
  };

  return (
    <Container className="fluid bg">
      <CVerifyLoginTFAModal show={verifyTFAModal} setShow={setVerifyTFAModal}
                            email={email} password={password} onOk={(success: boolean) => {
            if(success) {
              navigate('/');
            }
      }}/>
      <CRegisterTFAModal show={registerTFAModal} setShow={setRegisterTFAModal}
                         email={email} password={password} otpKey={otpKey} otpImageUrl={otpImageUrl} onOk={(success: boolean) => {
                           if(success) {
                             navigate('/');
                           }
      }}/>
      <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
        <img style={{ maxWidth: "400px", marginBottom: "16px"}}  src={logoTitle} height={48}></img>
        <h3 style={{ textAlign: "center"}}>ADMIN CONSOLE</h3>
        <div style={{ color: "#aaaaaa" }}>{`${version}-${buildEnv}`}</div>
      </div>
      <Form className="w-50">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter email" 
            value={email} 
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-5" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>

        <div className="d-grid gap-2"> {loading ? 
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> : 
          <Button className="buttonHover" size="lg" variant="dark" type="submit" onClick={onLoginButtonClicked}>
              Log in
          </Button>}
        </div>
      </Form>
    </Container>
  );
}

export default LoginPage;
