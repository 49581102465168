import {Col, Row} from "react-bootstrap";

export default function TransactionApprovePageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Tx Pair Id</Col>
		<Col>Date (Deposit)</Col>
		<Col>Amount / Token (Mainnet)</Col>
		<Col></Col>
		<Col>Date (Withdrawal)</Col>
		<Col>Amount / Token (Mainnet)</Col>
		<Col>User Deducted Fee (Mainnet)</Col>
		<Col>Status</Col>
		</Row>;
}