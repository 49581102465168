import {Button, Form} from "react-bootstrap"
import {useState} from "react";

export interface ISelectTextFieldComponent {
	id: string
	placeholder?: string
	onSelect?: Function
	enable?: boolean
}

export default function CSelectTextField(props: ISelectTextFieldComponent) {
	const [text, setText] = useState('');

	function onSelectEvent(e: any) {
		e.preventDefault();
		if(props.onSelect !== undefined) {
			props.onSelect(text);
		}
	}

	return <Form>
		<div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
			<div style={{ width: "320px", marginRight: "8px"}}><Form.Control
				className={"pt-2 pb-2"}
				id={props.id + "select"}
				type={"text"}
				value={text}
				onChange={e => setText(e.target.value)}
				placeholder={props.placeholder ?? "Choose a value"}
				disabled={true}
				
			/>
			</div>
			<div>
				<Button
					type={"submit"}
					variant="dark"
					onClick={onSelectEvent}>
					✓
				</Button>
			</div>
		</div>
	</Form>
}