import {Col, Row} from "react-bootstrap";

export default function GPDHistoryPageBurnListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Date</Col>
		<Col>Burn Id</Col>
		<Col>Approved By</Col>
		<Col>Blockchain Status</Col>
		<Col>To</Col>
		<Col>(C) Amount</Col>
		<Col>(C) Gas Fee</Col>
	</Row>;
}