import {Dto_GetAccountInfoResult, Dto_GetUserAccess, Dto_GetUserAccessResult, Dto_SetAccountInfo} from "./user_dto";
import {BaseHttp} from "../BaseHttp";
import {ApiUrl} from "../../constants/ApiUrl";
import {DataResponse} from "../../model/response_model";

export class UserApi {
	private static instance: UserApi
	private constructor() {}

	public static async setAccountInfo(data: Dto_SetAccountInfo):
		Promise<{code: string, msg: string, data: DataResponse, success: boolean}> {
		return BaseHttp.put(ApiUrl.API_PRIVATE_USER, data);
	}

	public static async getAccountInfo():
        Promise<{code: string, msg: string, data: { detail: Dto_GetAccountInfoResult }, success: boolean}> {
        return BaseHttp.get(ApiUrl.API_PRIVATE_USER);
    }

	public static async getUserAccess(authCode: string): Promise<boolean> {
		const res = await BaseHttp.get(ApiUrl.API_PRIVATE_USER_ACCESS, {
			authCode: authCode,
		});
		return res.data.access;
	}
}