import {BaseHttp} from "../BaseHttp";
import {DataResponse, PagingInfoResponse} from "../../model/response_model";
import {ApiUrl} from "../../constants/ApiUrl";
import {Dto_GetWalletDistributeHistories, Dto_GetWalletDistributeHistoriesResult, Dto_SendWalletDistribute} from "./wallet_distribute_dto";

export class WalletDistributeApi {
	private static instance: WalletDistributeApi
	private constructor() {}

	// internal error
	public static async sendWalletDistribute(params: Dto_SendWalletDistribute): Promise<DataResponse> {
		return BaseHttp.post(`${ApiUrl.API_PRIVATE_WALLET_DISTRIBUTE}`, params);
	}

	public static async getWalletDistributeHistories(params: Dto_GetWalletDistributeHistories):
		Promise<{code: string, msg: string, data: { pagingInfo: PagingInfoResponse, list: Dto_GetWalletDistributeHistoriesResult[] }, success: boolean}> {
		return BaseHttp.get(`${ApiUrl.API_PRIVATE_WALLET_DISTRIBUTE_HISTORIES}`, params);
	}
}