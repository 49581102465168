import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";

const { persistAtom } = recoilPersist()

export interface ILocaleSettings {
    locale: string
    offset: number
}

export const localeSettingState = atom<ILocaleSettings>({
    key: "localeSettingState",
    default: {
        locale: 'ko',
        offset: 9,
    },
    effects_UNSTABLE: [persistAtom]
})