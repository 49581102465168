import {Col, Row} from "react-bootstrap";

export default function GPDHistoryPageMintListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
		<Col>Date</Col>
		<Col>Mint Id</Col>
		<Col>Approved By</Col>
		<Col>Blockchain Status</Col>
		<Col>To</Col>
		<Col>Amount</Col>
		<Col>Gas Fee</Col>
	</Row>;
}