import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../../components/CDropdown";
import CSearchTextField from "../../../components/CSearchTextField";

export interface IGPDHistoryPageMintFilter {
	selectedListDisplay: IDropdown,
	setSelectedListDisplay: Function,
	onSearch: Function
}

export default function GPDHistoryPageMintFilter(props: IGPDHistoryPageMintFilter) {
	const dropdownData: IDropdown[] = [{
		name: "25",
		value: "25",
	},
	{
		name: "50",
		value: "50",
	},
	{
		name: "100",
		value: "100",
	}];

	return <Row className="mb-3 align-items-end">
			<Col xs={2}>
				<CDropdown id={"gpd-history-mint-dropdown-role"} hint={"List Display"} data={dropdownData}
				           selected={props.selectedListDisplay}
				           onSelected={props.setSelectedListDisplay}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"gpd-history-mint-search"} placeholder={"Mint ID"} onSearch={props.onSearch}/>
			</Col>
		</Row>;
}