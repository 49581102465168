import {Col, Row} from "react-bootstrap";

export default function WalletManagementPageListHeader() {
	return <Row className="p-3 mb-2 text-light bg-dark h6">
			<Col>{"Wallet No."}</Col>
			<Col>{"Wallet Name"}</Col>
			<Col>{"Mainnet"}</Col>
			<Col xs={4}>{"Wallet Address"}</Col>
			<Col>{"Signature Type"}</Col>
			<Col>{"Status"}</Col>
			<Col>{"Action"}</Col>
		</Row>;
}