import {Button, Modal, Form, Row, Col, InputGroup, FormControl} from "react-bootstrap";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {ChangeEvent, useEffect, useState} from "react";

export interface ICalendarModalComponent {
    show: boolean,
    setShow: any,
    onCancel?: any,
    onOk: any,
}

export default function CCalendarModal(props: ICalendarModalComponent) {
    const [selected, setSelected] = useState(new Date());

    return (
        <>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
                <Form>
                    <Modal.Header closeButton style={{ border: "none" }}>
                        <Modal.Title>Calendar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"d-flex flex-column justify-content-center align-items-center"}>
                        <Calendar value={selected} locale="en-EN" onChange={(value: any, event: any) => {
                            setSelected(value)
                        }}/>

                        <InputGroup  className={"d-flex flex-row justify-content-center align-items-center"} style={{width:"300px", margin: "15px"}} >
                            <FormControl style={{margin: "0 15px 0"}} min="0" max="24" type="number" maxLength={2} onChange={(e)=>{
                                let targetValue = parseInt(e.target.value);
                                    if (targetValue<0||targetValue>23) e.target.value="0"
                                    if (e.target.value.length>2) e.target.value="0"

                                    if (targetValue<0||targetValue>23) return;
                                    if (e.target.value.length>2) return;

                                    let copy = selected;
                                    copy.setHours(parseInt(e.target.value))
                                    setSelected(copy)
                                }}/>
                            <span>H</span>
                            <FormControl style={{margin: "0 15px 0"}} min="0" max="24" type="number" maxLength={2} onChange={(e)=>{
                                let targetValue = parseInt(e.target.value);
                                if (targetValue<0||targetValue>59) e.target.value="0"
                                if (e.target.value.length>2) e.target.value="0"
                                let copy = selected;
                                copy.setMinutes(parseInt(e.target.value))
                                setSelected(copy)
                            }}/>
                            <span>M</span>
                            <FormControl  style={{margin: "0 8px 0"}} min="0" max="24" type="number" maxLength={2} onChange={(e)=>{
                                let targetValue = parseInt(e.target.value);
                                if (targetValue<0||targetValue>59) e.target.value="0"
                                if (e.target.value.length>2) e.target.value="0"
                                let copy = selected;
                                copy.setSeconds(parseInt(e.target.value))
                                setSelected(copy)
                            }}/>
                            <span>S</span>
                        </InputGroup>

                    </Modal.Body>
                    <Modal.Footer style={{ border: "none" }} className={"d-flex justify-content-center"}>
                        <Row>
                            <Col>
                                <Button
                                    style={{ width: "160px"}}
                                    variant={"secondary"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(props.onCancel) props.onCancel();
                                        props.setShow(false);
                                    }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={{ width: "160px"}}
                                    variant={"dark"}
                                    type={"submit"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(props.onOk) props.onOk(selected);


                                        props.setShow(false);
                                    }}>
                                    Apply
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
        </Modal>
        </>
    );
};