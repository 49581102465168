import {Col, Container, Row} from "react-bootstrap";
import CPageTitle from "../../components/CPageTitle";
import TransactionApprovePageListHeader from "./TransactionRecordPageListHeader";
import CListPage from "../../components/CListPage";
// import TransactionRecordPageListItem, {ITransactionRecordPageListItem } from "./TransactionRecordPageListItem";
import CPagination from "../../components/CPagination";
import CBreadCrumb from "../../components/CBreadCrumb";
import TransactionRecordPageFilter from "./TransactionRecordPageFilter";
import CTotalCount from "../../components/CTotalCount";
import {useEffect, useState} from "react";
import {WalletConnectApi} from "../../../api/wallet_connect/WalletConnectApi";
import {IDropdown} from "../../components/CDropdown";
import {checkValidResult} from "../../../utils/session";
import {useNavigate} from "react-router-dom";
import {Dto_GetTransactionApproveResult} from "../../../api/wallet_connect/wallet_connect_dto";
import CSimplePagination from "../../components/CSimplePagination";
import TransactionRecordPageListItem from "./TransactionRecordPageListItem";
import CLoadingDimmer from "pages/components/CLoadingDimmer";

export default function TransactionRecordPage() {
	const [list, setList] = useState<Dto_GetTransactionApproveResult[]>([]);
	const [loading, setLoading] = useState(false)
	const [totalCount,setTotalCount] = useState(0);
	const [index, setIndex] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [currentTotalPage, setCurrentTotalPage] = useState(0);
	const [listAmountData,setListAmountData] = useState<IDropdown>({
		name: "25",
		value : "25"
	})

	const [ sortData, setSortData ] = useState<IDropdown>({
		name : "Deposit (Recent)",
		value : "DATE_DESC"
	})

	const [ statusData, setStatusData ] = useState<IDropdown>({
		name : "ALL",
		value : ""
	})
	const navigate = useNavigate();
	const generateItem = (value: Dto_GetTransactionApproveResult) => {
		return <TransactionRecordPageListItem key={value.txPairId?.toString()} {...value} />
	}
	useEffect(() => {
		fetchTransactionRecordList(1);
	}, []);
	async function fetchTransactionRecordList(page:number, search?: string){
		if(loading) return;
		setLoading(true);

		const result = await WalletConnectApi.getTransactionApprove({
			pageNumber: page,
			status : statusData.value,
			sort : sortData.value,
			pageSize : listAmountData.value ? parseInt(listAmountData.value) : 25,
			txPairId : search ? parseInt(search) : undefined
		});

		if(result && result.data.list){
			try {
				if (listAmountData.value) setPageSize(parseInt(listAmountData.value))
				setList(result.data.list);
				setTotalCount(result.data.pagingInfo.totalCount)
				setLoading(false);

			}catch(e){
				setTotalCount(0)
				if (listAmountData.value) setPageSize(parseInt(listAmountData.value))
				setList([]);
				setLoading(false);
			}
		}else{
			setTotalCount(0);
			if (listAmountData.value) setPageSize(parseInt(listAmountData.value))
			setList([]);
			setLoading( false);
		}

	}


	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>
		<CBreadCrumb first={"Transaction Record"} firstUrl={"/wallet-connect/record"}/>
		<CPageTitle title={"Transaction Record"} subtitle={""}/>
		<CTotalCount totalCount={totalCount}/>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchTransactionRecordList(page)}
			totalCount={totalCount}
			pageSize={pageSize}
		/>
		<TransactionRecordPageFilter
			listAmountData={listAmountData}
			setListAmountData={setListAmountData}
			sortData={sortData}
			setSortData={setSortData}
			statusData={statusData}
			setStatusData={setStatusData}

			onSearch={(search:string)=>{
					fetchTransactionRecordList(1,search)
				}
			}

		/>
		<TransactionApprovePageListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>

	</Container>
}