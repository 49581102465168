import 'styles/sidebar.css';
import 'styles/layout.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CPageTitle from "../../components/CPageTitle";
import {TokenManager} from "../../../states/token";
import {logout} from "../../../utils/session";
import Axios from "axios";
import {ApiUrl} from "../../../constants/ApiUrl";
import DashboardPageDataCenterModal from "./DashboardPageDataCenterModal";
import CLoadingDimmer from "../../components/CLoadingDimmer";

function DashboardPageDataCenter() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [count, setCount] = useState(0);
    const [walletBalance, setWalletBalance] = useState(false);
    const [walletDistribute, setWalletDistribute] = useState(false);
    const [walletConnectHistory, setWalletConnectHistory] = useState(false);
    const [walletConnectFee, setWalletConnectFee] = useState(false);
    const [GPDMint, setGPDMint] = useState(false);
    const [GPDBurn, setGPDBurn] = useState(false);
    const [selectedItemList, setSelectedItemList] = useState<string[]>([]);

    const selectorList = [
        "WALLET_BALANCE", "WALLET_DISTRIBUTE",
        "WALLET_CONNECT_HISTORY", "WALLET_CONNECT_FEE",
        "GPD_MINT", "GPD_BURN"
    ];
    useEffect(() => {
        countSelectedItem();
        countSelectedItemList();
    }, [walletBalance,
        walletDistribute,
        walletConnectHistory,
        walletConnectFee,
        GPDMint,
        GPDBurn])

    function countSelectedItem() {
        let count = 0;
        if(walletBalance) count += 1;
        if(walletDistribute) count += 1;
        if(walletConnectHistory) count += 1;
        if(walletConnectFee) count += 1;
        if(GPDMint) count += 1;
        if(GPDBurn) count += 1;
        setCount(count);
        return count;
    }

    function countSelectedItemList() {
        const list: string[] = [];
        if(walletBalance) list.push("WALLET_BALANCE");
        if(walletDistribute) list.push("WALLET_DISTRIBUTE");
        if(walletConnectHistory) list.push("WALLET_CONNECT_HISTORY");
        if(walletConnectFee) list.push("WALLET_CONNECT_FEE");
        if(GPDMint) list.push("GPD_MINT");
        if(GPDBurn) list.push("GPD_BURN");
        setSelectedItemList(list);
        return list;
    }

    interface ICheckboxContainer {
        value: boolean
        setValue: Function
        title: string
    }

    function checkBoxComponent(params: ICheckboxContainer) {
        return <div className={"d-flex mb-3"}>
            <input
                style={{ marginRight: "16px" }}
                type="checkbox" id="scales" name="scales"
                checked={params.value}
                onChange={(e) => {
                    params.setValue(e.target.checked)
                }}>
            </input>
            <div onClick={() => {
                params.setValue(!params.value)
            }}>{params.title}</div>
        </div>
    }

    async function onDownload() {
        if(loading) return;
        setLoading(true);
        const accessToken = TokenManager.getAccessToken();
        const refreshToken = TokenManager.getRefreshToken();
        if(!accessToken || !refreshToken) logout();
        try {
            Axios(ApiUrl.API_PRIVATE_DATACENTER_DOWNLOAD, {
                method: "get",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${accessToken}`
                },
                responseType: "blob",
                params: {
                    pageNumber: 1,
                    pageSize: 10000,
                    // category:
                    // type:
                    // status:
                    // searchFilter:
                }
            }).then((response) => {
                const date = new Date();
                const name = `data_center_${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}.csv`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", name);
                link.style.cssText = "display:none";
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch((error) => {

            }).finally(() => {
                setLoading(false);
            });
        } catch (e) {

        }
    }

  return <Container>
      <CLoadingDimmer loading={loading} setLoading={setLoading}/>
      <DashboardPageDataCenterModal
          show={showModal} setShow={setShowModal}
          loading={loading} setLoading={setLoading}
        onOk={() => {

        }}
        selectedValueList={selectedItemList}
      />
      <Row>
          <Col>
              <CPageTitle title={"Data Center"} subtitle={""}/>
          </Col>
      </Row>

      <Container style={{ width: "768px" }}>
          <Row className={"bg-white rounded mb-4 p-2 d-flex flex-row align-items-center"}>
              <Col>{`Selected: ${count}`}</Col>
              <Col className={"d-flex justify-content-end"}>
                  <Button type={"submit"}
                          variant="dark"
                          onClick={() => {
                              if(selectedItemList.length === 0) return;
                              setShowModal(true);
                          }}>
                      Download
                  </Button>
              </Col>
          </Row>

          <Row className={"bg-white rounded p-4"}>
              <Row>
                  {checkBoxComponent({
                      title: "Registered Wallet Balance",
                      value: walletBalance,
                      setValue: setWalletBalance,
                  })}
              </Row>
              <Row>
                  {checkBoxComponent({
                      title: "Wallet Distribute",
                      value: walletDistribute,
                      setValue: setWalletDistribute,
                  })}
              </Row>
              <Row>
                  {checkBoxComponent({
                      title: "Wallet Connect Transaction History",
                      value: walletConnectHistory,
                      setValue: setWalletConnectHistory,
                  })}
              </Row>
              <Row>
                  {checkBoxComponent({
                      title: "Wallet Connect User Charged Fee",
                      value: walletConnectFee,
                      setValue: setWalletConnectFee,
                  })}
              </Row>
              <Row>
                  {checkBoxComponent({
                      title: "Smart Contract GPD Mint",
                      value: GPDMint,
                      setValue: setGPDMint,
                  })}
              </Row>
              <Row>
                  {checkBoxComponent({
                      title: "Smart Contract GPD Burn",
                      value: GPDBurn,
                      setValue: setGPDBurn,
                  })}
              </Row>
          </Row>
      </Container>
    </Container>;
}

export default DashboardPageDataCenter;
