import {Col, Form, FormText, Pagination, Row} from "react-bootstrap";
import '../../styles/pagination.css'
import {useEffect, useState} from "react";
import {FETCH_SIZE} from "../../constants/value";

export interface ISimplePaginationComponent {
    index: number
    setIndex: any
    onSubmit: any
    loading: boolean
    pageSize: number
    totalCount: number
}

export default function CSimplePagination(params: ISimplePaginationComponent) {

    useEffect(()=>{

    })

    return <Row>
        <div className={"d-flex flex-row"} >
            <Pagination>
                <Pagination.Prev
                    disabled={params.index <= 1}
                    onClick={() => {
                        if(params.loading) return;
                        if(params.index > 1) {
                            params.onSubmit(params.index - 1)
                            params.setIndex(params.index - 1)
                        }
                    }}/>
                <div style={{ width: "60px", marginLeft: "8px", marginRight: "8px" }}>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        if(params.loading) return;
                        params.onSubmit(params.index)
                    }}>
                        <Form.Control
                            id={"textform" + "select" + params.index}
                            type={"text"}
                            value={params.index}
                            onChange={(e) => {
                                try {
                                    const re = /^[0-9\b]+$/;
                                    if(e.target.value === '') params.setIndex(0);
                                    if(!re.test(e.target.value)) return;
                                    const page = parseInt(e.target.value);
                                    if(params.index < 0) params.setIndex(0);
                                    else params.setIndex(page)
                                } catch(e) {}
                            }}
                            placeholder={"0"}
                        />
                    </Form>
                </div>
                <Pagination.Next
                    disabled={params.index >= Math.ceil(params.totalCount/params.pageSize)}
                    onClick={() => {
                    if(params.loading) return;
                    if(params.totalCount && params.index * params.pageSize > params.totalCount) return;
                    params.onSubmit(params.index + 1)
                    params.setIndex(params.index + 1)
                }}/>
            </Pagination>
            {
                params.totalCount !== undefined && params.pageSize !== undefined ? <div className={"mt-2 ms-4"}>
                    {`${params.index} of ${1 + Math.floor(params.totalCount/params.pageSize)}` }
                </div> : <div/>
            }
        </div>
    </Row>;
}