import {SIDEBAR_MENU_ITEMS} from '../../constants/menu_items'
import React from "react";
import {Nav} from "react-bootstrap";
import 'styles/sidebar.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {checkLogin} from "../../utils/session";

export interface ISidebarComponent {
    selected?: string,
}

export default function CSidebar(selected: ISidebarComponent) {
    const menuData = SIDEBAR_MENU_ITEMS;
    const navigate = useNavigate();
    const location = useLocation();

    const version = process.env.REACT_APP_VERSION;
    const buildEnv = process.env.REACT_APP_BUILD_ENV;

    const onNavigate = async (url: string) => {
        if(await checkLogin()) {
            navigate(url);
        } else {
            navigate('/login');
        }
    }

    return (
        <div style={{ position: "fixed"}} className="hide-browser">
            <Nav
                activeKey="/home"
                variant={"tabs"}
            >
                <div className="sidebar">
                    <div style={{ color: "#FFFFFF", paddingTop: "16px", paddingBottom: "16px", paddingLeft: "16px", marginBottom: "16px", backgroundColor: "#161720" }}>
                        <h4>ADMIN CONSOLE</h4>
                        <div style={{ color: "#aaaaaa" }}>{`${version}-${buildEnv}`}</div>
                    </div>
                    { location.pathname === '/dashboard' ?
                        <div key={'/dashboard'} className={"menu-item-selected mb-3"} onClick={()=>onNavigate('/dashboard')}>Dashboard</div> :
                        <div key={'/dashboard'} className={"menu-item mb-3"} onClick={()=>onNavigate('/dashboard')}>Dashboard</div> }
                    {
                        menuData.map((menuItem) => {
                            return <div key={menuItem.title} className={"d-flex flex-column"}>
                                <div className="fs-4 ps-3 pb-2 text-white">{menuItem.title}</div>
                                <div className="ps-3 pb-2 d-flex flex-column">
                                    {menuItem.items.map((item) => {
                                        return location.pathname.includes(item.url) ?
                                            <div key={item.title} className={"menu-item-selected"} onClick={()=>onNavigate(item.url)}>{item.title}</div> :
                                            <div key={item.title} className={"menu-item"} onClick={()=>onNavigate(item.url)}>{item.title}</div>
                                    })}
                                </div>
                            </div>;
                        })
                    }
                </div>
            </Nav>
        </div>
    );
};