import {Container, Tab, Tabs} from "react-bootstrap";
import CPageTitle from "../../components/CPageTitle";
import CBreadCrumb from "../../components/CBreadCrumb";
import TransactionApprovePageUnrecognized from "./TransactionApprovePageUnrecognized";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import TransactionApprovePageFailed from "./TransactionApprovePageFailed";
import CLoadingDimmer from "pages/components/CLoadingDimmer";

export default function TransactionApprovePage() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	return <Container>
		<CLoadingDimmer loading={loading} setLoading={setLoading}/>

		<CBreadCrumb first={"Transaction Approve"} firstUrl={"/wallet-connect/approve"}/>
		<CPageTitle title={"Transaction Approve"} subtitle={""}/>
		<Tabs
			defaultActiveKey="unrecognized"
			id="transaction_approve_tabs"
			className="mb-3"
		>
			<Tab tabClassName="bg-white text-black" eventKey="unrecognized" title="Unrecognized">
				<TransactionApprovePageUnrecognized loading={loading} setLoading={setLoading} navigate={navigate}/>
			</Tab>
			<Tab tabClassName="bg-white text-black" eventKey="failed" title="Failed">
				<TransactionApprovePageFailed loading={loading} setLoading={setLoading} navigate={navigate}/>
			</Tab>
		</Tabs>
	</Container>
}