import {Col, Row} from "react-bootstrap";
import {Dto_GetUnrealizedFeeResult} from "../../../../api/token/token_dto";
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";

export default function FeeRecordPageUnrealizedFeeListItem(props: Dto_GetUnrealizedFeeResult) {

	return <Row className="p-3 bg-white mb-2 moveItem">
		<Col>{props.createDate?<CLocaleTimestamp timestamp={props.createDate}/>:"-"}</Col>
		<Col xs={2}>{props.txPairId}</Col>
		<Col>
			<Row>{`${props.gasCoverageFee} ${props.tokenName}`}</Row>
			{/* <Row>{`(${props.mainnetName})`}</Row> */}
		</Col>
		<Col>
			<Row>{`${props.coverageFee} ${props.tokenName}`}</Row>
			{/* <Row>{`(${props.conversionFeeMainnet})`}</Row> */}
		</Col>
		<Col>{
			!props.realised ? "Unrealized" : "Realized"
		}</Col>
	</Row>
}