import {COIN_ETH, COIN_GPX, TOKEN_GPD, TOKEN_USDT} from "../constants/value";

export function isEmptyString(text: string): boolean {
    return text !== null &&
        text !== undefined &&
        text !== '' &&
        text.length !== 0
}

export function weiToGwei(wei: number): number {
    return wei * 1e9;
}

export function stringWeiToGwei(swei: string): number {
    const exponentialString = swei;
    const numberValue = parseFloat(exponentialString);
    return weiToGwei(numberValue);
}

export function gweiToEth(gwei: number): number {
    return gwei / 1e9;
}

export function getFormattedNumber(value: number, fraction: number = 6): string {
    return value.toLocaleString(undefined, { minimumFractionDigits: 0 });
}

export function transformFormattedNumberToNumber(value: string): number {
    return Number(value.replace(/,/g, ''));
}

export function checkPositiveNumber(num: number): boolean {
    return num>=0;
}

export function getMainnetSymbol(mainnet: string): string {
    if(mainnet === 'GPEX') {
        return 'GPX'
    }
    else if(mainnet === 'Ethereum') {
        return 'ETH'
    }
    return '';
}

/***
    shrinkAddress(address)
    주소가 길어서 표시할 수 없을 경우 사이 길이를 ... 로 줄여서 보여줍니다.
 ***/
export function shrinkAddress(address: string, length?: number): string {
    if(!address) return "";
    const len = length??6
    if(address.length > 14) {
        return `${address.slice(0, len+2)}...${address.slice(address.length-len, address.length)}`
    }
    return address
}

export function timestampToDate(timestamp: string): Date {
    const date = new Date(parseInt(timestamp)*1000);
    return date;
}


export function getCurrencyUnit(Mainnet: string):string{
    if(Mainnet==="Ethereum") {
        return "ETH"
    }else if(Mainnet ==="GPEX"){
        return "GPX"
    }else{
        return "-"
    }
}

export function getCurrencyUnitByTokenName(tokenName: string):string{
    if(tokenName === "USDT") {
        return "ETH"
    }else if(tokenName === "GPD"){
        return "GPX"
    }else{
        return "-"
    }
}


export function getGasUnit(tokenOrNetworkName: string): string {
    if(tokenOrNetworkName === COIN_ETH || tokenOrNetworkName === TOKEN_USDT || tokenOrNetworkName.toLowerCase() === "ethereum") {
        return COIN_ETH;
    }
    else if(tokenOrNetworkName === COIN_GPX || tokenOrNetworkName === TOKEN_GPD || tokenOrNetworkName.toLowerCase() === "gpex") {
        return COIN_GPX;
    }
    else {
        return '';
    }
}

// 소수점 아래의 숫자를 특정 자릿수까지 표시. 만약에 아래에 0이 있다면 제거하여 표시
export function getFloatingPointNumber(num: number, fractionDigits: number): string {
    let formatted = num.toFixed(fractionDigits);

    while (formatted.includes('.') && (formatted.endsWith('0') || formatted.endsWith('.'))) {
        formatted = formatted.slice(0, -1);
    }

    return formatted;
}

export function getServerUrl(REACT_APP_BUILD_ENV:string){
    if (REACT_APP_BUILD_ENV==="dev") return
}



