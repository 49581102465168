import {Col, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CSearchTextField from "../../components/CSearchTextField";

export interface ITransactionApprovePageFilter{
	listAmountData: IDropdown
	setListAmountData: Function
	sortData: IDropdown
	setSortData: Function
	onSearch : Function
}


const listAmountData = [
	{
		name : "25",
		value : "25",
	},
	{
		name : "50",
		value : "50",
	},
	{
		name : "100",
		value : "100",
	}
]

const sortData = [
	{	name : "Deposit (Recent)",
		value : "DATE_DESC"
	},
	{
		name : "Deposit (Old)",
		value : "DATE_ASC"
	},
	{
		name : "Deposit Amount (High)",
		value : "DEPOSIT_DESC"
	},
	{
		name : "Deposit Amount (Low)",
		value : "DEPOSIT_ASC"
	}

]


export default function TransactionApprovePageFilter(props:ITransactionApprovePageFilter) {
	return <Row className="mb-3 align-items-end">
			<Col xs={2}>
				<CDropdown id={"transaction-approve-dropdown-list-display"} hint={"List Display"} data={listAmountData} selected={props.listAmountData}  onSelected={props.setListAmountData}></CDropdown>
			</Col>
			<Col xs={3}>
				<CDropdown id={"transaction-approve-dropdown-sort-by"} hint={"Sort by"} data={sortData} selected={props.sortData} onSelected={props.setSortData}></CDropdown>
			</Col>
			<Col></Col>
			<Col xs={4} className={"d-flex justify-content-end"}>
				<CSearchTextField id={"transaction-approve-search"} placeholder={"Pair ID"} onSearch={props.onSearch}/>

			</Col>
		</Row>;
}