import {Dropdown} from "react-bootstrap";

export interface IDropdown {
	name: string
	value: string | undefined  // uses for simple values for example id.
}

export interface IDropdownComponent {
	id: string
	data: IDropdown[]
	hint?: string
	width?: number
	selected?: IDropdown
	onSelected: Function
}



export default  function CDropdown(props: IDropdownComponent) {
	return <div style={{ width: props.width ? `${props.width}px`: ""}}>
		<Dropdown>
			<Dropdown.Header>{props.hint}</Dropdown.Header>
			<Dropdown.Toggle id={props.id} className="w-100 pt-2 pb-2 bg-white text-secondary border border-1 border-radius">
				{props.selected ? props.selected.name : props.hint ?? "Choose an item"}
			</Dropdown.Toggle>

			<Dropdown.Menu className="w-150" variant="dark">
				{props.data.map((item) => {
					return <Dropdown.Item key={JSON.stringify(props.data) + item.value} href="#/action-1" onClick={(e: any) => {
						e.preventDefault();
						props.onSelected(item)
					}}>{item.name}</Dropdown.Item>
				})}
			</Dropdown.Menu>
		</Dropdown>
	</div>
}