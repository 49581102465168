import {Col, Row} from "react-bootstrap";
import {IDropdown} from "../../components/CDropdown";
import CTitleDropdown from "../../components/CTitleDropdown";
import {Dto_GetTokensBalanceByWalletResult, Dto_GetWalletsDistributeResult} from "../../../api/wallet/wallet_dto";
import CTextField, {ITextFieldType} from "../../components/CTextField";

export interface IWalletDistributeReceivingPage {
	walletOffline: Dto_GetWalletsDistributeResult[]
	walletOnline: Dto_GetWalletsDistributeResult[]
	walletDropdown: IDropdown[]
	walletDropdownSelected?: IDropdown
	setWalletDropdownSelected: Function
	onWalletSelected: Function

	mainnet: string
	setMainnet: Function
	walletAddress: string
	setWalletAddress: Function
	walletBalance: string
	setWalletBalance: Function
	balanceAfterTransfer: string
	setBalanceAfterTransfer: Function
}

export default function WalletDistributePageReceivingCard(props: IWalletDistributeReceivingPage) {
	return <Col className="p-4 bg-white">
		<Row className={"h5 ms-1 mb-4"}>Choose a receiving wallet</Row>
		<Row className="mb-4">
			<CTitleDropdown id={"wallet-distribute-receiving-Select Wallet Name"} title={"Select Wallet Name"} data={props.walletDropdown}
                selected={props.walletDropdownSelected}
                onSelected={(selected: IDropdown) => {
	                props.onWalletSelected(selected)
                }}/>
		</Row>
		<Row className="mb-4">
			<CTextField id={"wallet-distribute-receiving-Mainnet"} title={"Mainnet"} placeholder={"Mainnet"}
			            text={props.mainnet} setText={props.setMainnet} type={ITextFieldType.text}
			            disabled={true}/>
		</Row>
		<Row className="mb-4">
			<CTextField id={"wallet-distribute-receiving-Wallet Address"} title={"Wallet Address"} placeholder={"-"}
			            text={props.walletAddress} setText={props.setWalletAddress} type={ITextFieldType.text}
			            disabled={true}/>
		</Row>
		<Row className="mb-4">
			<CTextField id={"wallet-distribute-receiving-Wallet Balance"} title={"Wallet Balance"} placeholder={"-"}
			            text={props.walletBalance} setText={props.setWalletBalance} type={ITextFieldType.text}
			            disabled={true}/>
		</Row>
		<Row>
			<CTextField id={"wallet-distribute-receiving-Balance after transfer"} title={"Balance after transfer"} placeholder={"-"}
			            text={props.balanceAfterTransfer} setText={props.setBalanceAfterTransfer} type={ITextFieldType.text}
			            disabled={true}/>
		</Row>
	</Col>
}