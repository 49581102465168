import {Button, Container} from "react-bootstrap";
import {IDropdown} from "../../../components/CDropdown";
import FeeRecordPageUnrealizedFeeListHeader from "./FeeRecordPageUnrealizedFeeListHeader";
import CListPage from "../../../components/CListPage";
import FeeRecordPageUnrealizedFeeListItem  from "./FeeRecordPageUnrealizedFeeListItem";
import FeeRecordPageUnrealizedFeeFilter from "./FeeRecordPageUnrealizedFeeFilter";
import {useEffect, useState} from "react";
import {TokenApi} from "../../../../api/token/TokenApi";
import {IDropdown_Dto_GetTokenResult, IDropdown_Dto_GetWalletsByTokenResult} from "../../../../model/custom_dropdown";
import {Dto_GetUnrealizedFeeResult} from "../../../../api/token/token_dto";
import CSimplePagination from "../../../components/CSimplePagination";
import {Dto_GetTransactionApproveResult} from "../../../../api/wallet_connect/wallet_connect_dto";
import {UserApi} from "../../../../api/user/UserApi";

export default function FeeRecordPageUnrealizedFee() {
	const [list, setList] = useState<Dto_GetUnrealizedFeeResult[]>([]);
	const [index, setIndex] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [ pageSize, setPageSize ] = useState("25")

	const [loading, setLoading] = useState(false);
	const [selectedToken, setSelectedToken] = useState<IDropdown>();
	const [tokenDropDownList,setTokenDropDownList] = useState<IDropdown_Dto_GetTokenResult[]>([])

	const [selectedWallet, setSelectedWallet] = useState<IDropdown>();
	const [walletDropDownList,setWalletDropDownList] = useState<IDropdown_Dto_GetWalletsByTokenResult[]>([])

	const [accessRealize, setAccessRealize] = useState(false);
	const generateItem = (value: Dto_GetUnrealizedFeeResult) => {
		return <FeeRecordPageUnrealizedFeeListItem key={value.txPairId}{...value} />
	}

	useEffect(() => {
		fetchGetTokenList();
		getUserAccess();
	}, [])

	// useEffect(()=>{
	// setSelectedToken(tokenDropDownList[0]);
	// if(selectedToken?.value){
	// fetchWalletsByToken(selectedToken.value);
	// }
	// setSelectedWallet(walletDropDownList[0]);
	// },[tokenDropDownList])


	useEffect(()=>{
		if(selectedToken?.value){
			fetchWalletsByToken(selectedToken?.value);
		}
		fetchGetUnrealizedFee(1);
	},[selectedToken])


	// useEffect(() => {
	// 	if(selectedToken?.value){
	// 	fetchWalletsByToken(selectedToken.value);
	// 	}
	// 	let firstWallet = walletDropDownList[0];
	// 	setSelectedWallet(firstWallet);
	// }, [selectedToken])

	async function fetchGetUnrealizedFee(page: number, search?: string) {
		if(loading) return;
		setLoading(true);

		if (!selectedWallet || !selectedToken || !selectedWallet.value || !selectedToken.value) return;
		try{
			const result = await TokenApi.getUnrealizedFee({
				pageSize: parseInt(pageSize),
				pageNumber: page,
				realised : false,
				walletId : parseInt(selectedWallet.value),
				tokenId : parseInt(selectedToken.value),
				txPairId : search ? parseInt(search) : undefined
			});
			if(result.success) {
				setList(result.data.list);
				setTotalCount(result.data.paging.totalCount);
			}
		}catch(e){

		}finally{
			setLoading(false);
		}
		setLoading(false);
	}

	async function fetchGetTokenList() {
		if(loading) return;
		setLoading(true);
		TokenApi.getTokens().then((res) => {
			const newDropdownList: IDropdown_Dto_GetTokenResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.tokenName,
					value : item.tokenId.toString(),
					data : item
				});
			}
			setTokenDropDownList(newDropdownList);
			setSelectedToken(newDropdownList[0]);
		}).catch((error) => {
			setLoading(false);
		}).finally(() => {
			setLoading(false);
		});
	}

	async function fetchWalletsByToken(tokenId: string) {
		if(loading) return;
		setLoading(true)
		try{
			const res = await TokenApi.getWalletsByToken(parseInt(tokenId));
			const newWalletDropdownList :IDropdown_Dto_GetWalletsByTokenResult[] = res.data.list.map((item)=>({
				name : item.walletName,
				value : item.walletId.toString(),
				data : item,
			}));
			setWalletDropDownList(newWalletDropdownList);
			setSelectedWallet(newWalletDropdownList[0]);
		}catch(e){

		}finally{
			setLoading(false);
		}
	}

	async function getUserAccess() {
		const access = await UserApi.getUserAccess("REALIZE_FEE");
		setAccessRealize(access);
	}


	return <Container>
		<CSimplePagination
			index={index}
			setIndex={setIndex}
			loading={loading}
			onSubmit={(page: number) => fetchGetUnrealizedFee(page)}
			totalCount={totalCount}
			pageSize={pageSize ? parseInt(pageSize) : 25}
		/>
		<FeeRecordPageUnrealizedFeeFilter
			selectedTokenData={selectedToken}
			setSelectedTokenData={setSelectedToken}
			tokenDropDownList={tokenDropDownList}
			selectedWalletData={selectedWallet}
			setSelectedWalletData={setSelectedWallet}
			walletDropDownList={walletDropDownList}
			fetchWalletsByToken={fetchWalletsByToken}
			fetchGetTokenList={fetchGetTokenList}
			onSearch={(search: string) => fetchGetUnrealizedFee(1, search)}
			accessRealize={accessRealize}
		/>
		<FeeRecordPageUnrealizedFeeListHeader/>
		{list.length === 0 ? "No Data" : ""}
		<CListPage list={list} itemGenerator={generateItem}/>
	</Container>
}