import { TransactionApi } from "api/transactions/TransactionApi";
import { WalletConnectApi } from "api/wallet_connect/WalletConnectApi";
import axios, { Axios } from "axios";
import {Button, Col, Row} from "react-bootstrap";
import { resetRecoil } from "recoil-nexus";
import { TokenManager } from "states/token";
import { logout } from "utils/session";
import {Dto_GetFeeTransactionListResult} from "../../../../api/token/token_dto";
import {ApiUrl} from "../../../../constants/ApiUrl";
import CLoadingDimmer from "pages/components/CLoadingDimmer";
import { useState } from "react";
import CLocaleTimestamp from "../../../components/CLocaleTimestamp";




export default function FeeRecordPageRealizedCommissionHistoryListItem(props:Dto_GetFeeTransactionListResult) {

    const [loading, setLoading] = useState(false);

    async function onDownloadFeeListByFeeGroupId(){
        if(loading) return;
        setLoading(true);
        const accessToken =TokenManager.getAccessToken();
        const refreshToken = TokenManager.getRefreshToken();
        if(!accessToken || !refreshToken) logout();

        try{
            axios({
                method : "get",
                url : ApiUrl.API_PRIVATE_TRANSACTIONS_FEES_HISTORIES_TRANSACTIONID_DOWNLOAD(props.feeTransactionId.toString()),
                headers : {
                    'Content-Type': 'application/json',
                    'Cookie': 'gpex_lang=ko',
                    "Authorization": `Bearer ${accessToken}`
                },
                responseType : `blob`,
            })
                .then((res)=>{
                    const date = new Date();
                    const name = `realize_detail_${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}.csv`;
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name);
                    link.style.cssText = "display:none";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
        }catch(e){
        }finally{
            setLoading(false);
        }
    }

    function getBlockchainStatusColor(blockchainStatus: string): string {
        if(blockchainStatus === 'COMPLETE') {
            return "green";
        }
        else if(blockchainStatus === 'PENDING') {
            return "orange";
        }
        else if(blockchainStatus === 'SENDING') {
            return "blue";
        }
        else if(blockchainStatus === 'READY') {
            return "black";
        }
        else
            return "black";
    }

    return <>
        <CLoadingDimmer loading={loading} setLoading={setLoading} />
        <Row className="p-3 bg-white mb-2 moveItem">

            <Col>{props.createDate?<CLocaleTimestamp timestamp={props.createDate}/>:"-"}</Col>
            <Col xs={2}>{props.feeTransactionId}</Col>
            <Col>
                <Row>
                    <Row>  {`${props.gasCoverageFee!==null?props.gasCoverageFee:"-"} ${props.tokenName}`}</Row>
                    <Row>    {props.networkName} </Row>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Row>{`${props.conversionFee!==null?props.conversionFee:"-"} ${props.tokenName}`}</Row>
                    <Row>{props.networkName}</Row>
                </Row>
            </Col>
            <Col style={{ color: getBlockchainStatusColor(props.status)}}>{props.status}</Col>
            <Col className="text-primary" style={{cursor:"pointer"}} onClick={onDownloadFeeListByFeeGroupId}> <span style={{color:"#F4B12E"}}>Details Download</span>
            </Col>
        </Row>
    </>
}