import {Button, Container, Modal} from "react-bootstrap";
import warning from 'assets/icons/warning.png'
import React, {useEffect, useState} from "react";
import CTextField from "../../../components/CTextField";
import {MESSAGE_FILL_THE_BLANK} from "../../../../constants/value";
import {UserApi} from "../../../../api/user/UserApi";
import CVerifyTFAModal from "../../../components/modal/CVerifyTFAModal";
import {UserSecurityApi} from "../../../../api/user_security/UserSecurityApi";
import {IAccountSettingsEditAccountInfoModalComponent} from "./AccountSettingsEditAccountInfoModal";

export interface IAccountSettingsEditPasswordModalComponent {
    show: boolean
    setShow: any
    onConfirm: any
}

export default function AccountSettingsEditPasswordModal(props: IAccountSettingsEditPasswordModalComponent) {

    const [tfa, setTfa] = useState(false);

    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    useEffect(() => {
    }, [])
    return (
        <>
            <CVerifyTFAModal show={tfa} setShow={setTfa} onOk={(code: string) => {
                UserSecurityApi.setPassword({
                    password: password,
                    passwordConfirm: confirm,
                    otpCode: code,
                }).then((result) => {
                    if(result.success) {
                        if(props.onConfirm) props.onConfirm(true);
                    }
                    else {
                        alert(result.msg);
                        return;
                    }
                }).catch((e) => {
                    alert(e);
                    return;
                })
            }}/>
            <Modal
            show={props.show}
            onHide={()=>props.setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
                <Modal.Title>Password Change</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none" }}>
                <Container>
                    <form>
                        <CTextField id={'account-setting-edit-password-modal-name-tf'} text={password} setText={setPassword} title={"New Password"}/>
                        <div style={{ marginBottom: "16px" }}></div>
                        <CTextField id={'account-setting-edit-password-modal-name-tf'} text={confirm} setText={setConfirm} title={"Confirm Password"}/>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer className={"border-0 d-flex flex-row justify-content-center mb-4"}>
                <Button
                    style={{ width: "360px"}}
                    variant="dark"
                    size={"lg"}
                    onClick={() => {
                        if(!password || !confirm) {
                            alert(MESSAGE_FILL_THE_BLANK);
                            return;
                        }
                        props.setShow(false);
                        setTfa(true);
                    }}>{props.onConfirm ?? "Confirm"}
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};