import {atom, selector} from "recoil";
import {recoilPersist} from "recoil-persist";
import {logout, refreshAccessToken} from "../utils/session";

const { persistAtom } = recoilPersist()

export interface IAuthUser {
    email: string
    name: string
    roleId: number
    roleName: string
    accessToken: string
    refreshToken: string
}

export const authUserState = atom<IAuthUser|undefined>({
    key: "authUserState",
    default: undefined,
    effects_UNSTABLE: [persistAtom]
})

export const authRegisterAtState = atom<number>({
    key: "authRegisterAtState",
    default: 0,
    effects_UNSTABLE: [persistAtom]
})

export const authCurrentTimeState = atom<number>({
    key: "authCurrentTimeState",
    default: 0,
    effects_UNSTABLE: [persistAtom]
})

export const authDisplayTimeSelector = selector({
    key: "authDisplayTimeSelector",
    get: ({get}) => {
        if(!process.env.REACT_APP_AUTH_TIMEOUT) return 0;
        const timeLimit = parseInt(process.env.REACT_APP_AUTH_TIMEOUT) * 1000;
        if(get(authRegisterAtState) === 0) {
            return 0;
        }
        else {
            if(timeLimit - (get(authCurrentTimeState) - get(authRegisterAtState)) < 0) {
                logout();
                return 0;
            }
            return get(authCurrentTimeState) - get(authRegisterAtState);
        }
    }
})