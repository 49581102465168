import {Button, Col, Container, Row} from "react-bootstrap";
import CDropdown, {IDropdown} from "../../components/CDropdown";
import CTextField from "../../components/CTextField";
import {useEffect, useState} from "react";
import GPDMintPageMintModal from "./modals/GPDMintPageMintModal";
import {SmartContractApi} from "../../../api/smart_contract/SmartContractApi";
import {IDropdown_Dto_GetGPDWalletsResult} from "../../../model/custom_dropdown";
import GPDMintPageBurnModal from "./modals/GPDMintPageBurnModal";
import {MESSAGE_CHANGES_FAILED, MESSAGE_CHANGES_SAVED, MESSAGE_FILL_THE_BLANK} from "../../../constants/value";
import {UserApi} from "../../../api/user/UserApi";

export interface IGPDMintPageMintCard {
	loading: boolean
	setLoading: Function
}

export default function GPDMintPageMintCard(props: IGPDMintPageMintCard) {
	const [amount, setAmount] = useState('');
	const [showMintModal, setShowMintModal] = useState(false);
	const [showBurnModal, setShowBurnModal] = useState(false);
	const [selectedType, setSelectedType] = useState<IDropdown>({
		name: "Minting",
		value: "minting",
	});
	const [selectedGpdWalletDropdown, setSelectedGpdWalletDropdown] = useState<IDropdown_Dto_GetGPDWalletsResult>();
	const [gpdWalletDropdownList, setGpdWalletDropdownList] = useState<IDropdown_Dto_GetGPDWalletsResult[]>([]);

	const [accessMint, setAccessMint] = useState(false);
	const [accessBurn, setAccessBurn] = useState(false);

	useEffect(() => {
		fetchGPDWallets();
		getAccess();
	}, [])

	async function fetchGPDWallets() {
		if(props.loading) return;
		props.setLoading(true);
		SmartContractApi.getGPDWallets().then((res) => {
			const newDropdownList: IDropdown_Dto_GetGPDWalletsResult[] = [];
			for(const item of res.data.list) {
				newDropdownList.push({
					name: item.walletName,
					value: item.walletId.toString(),
					data: item
				});
			}
			setGpdWalletDropdownList(newDropdownList);
		}).catch((error) => {

		}).finally(() => {
			props.setLoading(false);
		});
	}

	async function getAccess() {
		const promises = [
			UserApi.getUserAccess("MINT_GPD"),
			UserApi.getUserAccess("BURN_GPD"),
		];
		const access = await Promise.all(promises);
		setAccessMint(access[0]);
		setAccessBurn(access[1]);
	}

	async function minting(otpCode: string) {
		if(props.loading) return;
		try {
			const numAmount = parseFloat(amount);
			const numWalletId = parseInt(selectedGpdWalletDropdown?.value!)
			props.setLoading(true);
			SmartContractApi.createMinting({
				amount: numAmount,
				walletId: numWalletId,
				otpCode: otpCode
			}).then((res) => {
				if(res.success) {
					window.location.reload();
					alert(MESSAGE_CHANGES_SAVED)
				} else {
					alert(res.msg)
				}
			}).catch((error) => {
				alert(error)
			}).finally(() => {
				props.setLoading(false);
			})
		} catch(e) {
			return;
		}
	}

	async function burning(otpCode: string) {
		if(props.loading) return;
		try {
			const numAmount = parseFloat(amount);
			const numWalletId = parseInt(selectedGpdWalletDropdown?.value!)
			props.setLoading(true);
			SmartContractApi.createBurning({
				amount: numAmount,
				walletId: numWalletId,
				otpCode: otpCode
			}).then((res) => {
				if(res.success) {
					window.location.reload();
					alert(MESSAGE_CHANGES_SAVED)
				} else {
					alert(res.msg)
				}
			}).catch((error) => {
				alert(error)
			}).finally(() => {
				props.setLoading(false);
			})
		} catch(e) {
			return;
		}
	}

	const selectTypeDropdown: IDropdown[] = [{
		name: "Minting",
		value: "minting",
	},
	{
		name: "Burning",
		value: "burning",
	}];

	return <Container>
		<GPDMintPageMintModal show={showMintModal} setShow={setShowMintModal}
		                      to={selectedGpdWalletDropdown?.name ?? ''}
		                      amount={amount}
		                      gasFeeAmt={selectedGpdWalletDropdown?.data.maxGasFee??0}
		                      onOk={(otpCode: string) => {
								  minting(otpCode);
		}}/>
		<GPDMintPageBurnModal show={showBurnModal} setShow={setShowBurnModal}
		                      to={selectedGpdWalletDropdown?.name ?? ''}
		                      amount={amount}
		                      gasFeeAmt={selectedGpdWalletDropdown?.data.maxGasFee??0}
		                      onOk={(otpCode: string) => {
			                      burning(otpCode);
		}}/>
		<Row style={{ width: "768px" }} className={"p-5 bg-white mb-5 rounded"}>
			<Col>
				<Row className={"mb-5"}><h4>Minting/Burning</h4></Row>
				<Row>
					<div className={"d-flex justify-content-between mb-4"}>
						<div>Select Type</div>
						<CDropdown id={"gpd-mint-page-mint-select-type-dropdown"}
						           width={360} data={selectTypeDropdown}
						           selected={selectedType}
						           onSelected={(item: IDropdown_Dto_GetGPDWalletsResult) => {
									   setAmount('');
									   setSelectedGpdWalletDropdown(undefined);
							           setSelectedType(item)
								   }
						           }></CDropdown>
					</div>
					<div className={"d-flex justify-content-between mb-4"}>
						<div>Amount</div>
						<CTextField id={"gpd-mint-page-mint-amount-textfield"} text={amount} setText={setAmount} width={360} unit={"GPD"}></CTextField>
					</div>
					<div className={"d-flex justify-content-between mb-5"}>
						<div>Select Wallet</div>
						<CDropdown id={"gpd-mint-page-mint-select-wallet-dropdown"}
						           width={360} data={gpdWalletDropdownList}
						           selected={selectedGpdWalletDropdown}
						           onSelected={setSelectedGpdWalletDropdown}></CDropdown>
					</div>
					<Button className={"mb-1"} size={"lg"} variant={"dark"}
					        disabled={(!accessMint && selectedType.value === 'minting') ||
						        (!accessBurn && selectedType.value === 'burning') || !amount || !selectedGpdWalletDropdown
					}
					        onClick={() => {
						if(!selectedType || !amount || !selectedGpdWalletDropdown) {
							alert(MESSAGE_FILL_THE_BLANK);
							return;
						}
						if(selectedType.value === 'minting') {
							setShowMintModal(true);
						}
						else if(selectedType.value === 'burning') {
							setShowBurnModal(true);
						}
					}}>Confirm</Button>
				</Row>
			</Col>
		</Row>
	</Container>
}